import WithdrawKeys from '../action/keys/withdrawKeys';
import { ActionType } from '../action/type/withdrawType';

export interface StoreState {
    withdrawInfo: any;
    backCardInfo: any;
    serviceCharges: any;
    bankList: any;
    ruleModalVisible: boolean;
    withdrawModalVisible: boolean;
    toInputPage: boolean;
    toVerifyPage: boolean;
    toVerifyingPage: boolean;
    serverInputError: boolean;
    withdrawError: boolean;
    applyModalVisible: boolean;
    dataLoading: boolean;
    serverInputErrorText: string;
    withdrawErrorText: string;
    saveButton: boolean;
    promptModalVisible: boolean;
    promptText: string;
    taxList: any;
    extractModalLoading: boolean;
}

const initialState: StoreState = {
    withdrawInfo: '',
    backCardInfo: {},
    serviceCharges: '',
    bankList: [],
    ruleModalVisible: false,
    withdrawModalVisible: false,
    toInputPage: false,
    toVerifyPage: false,
    toVerifyingPage: false,
    serverInputError: false,
    withdrawError: false,
    applyModalVisible: false,
    dataLoading: false,
    serverInputErrorText: '',
    withdrawErrorText: '',
    saveButton: false,
    promptModalVisible: false,
    promptText: '',
    taxList: {},
    extractModalLoading: false
};

export const withdrawReducer = (state = initialState, action: ActionType ) =>  {
    switch (action.type) {

        case WithdrawKeys.INIT_PAGE:
            return initialState;

        case WithdrawKeys.GET_WITHDRAW_INFO:
            return Object.assign({}, state, {
                withdrawInfo: action.payload
            });

        case WithdrawKeys.DATA_LOADING:
            return Object.assign({}, state, {
                dataLoading: action.payload
            });

        case WithdrawKeys.APPLY_SUCCESS_MODAL:
            return Object.assign({}, state, {
                applyModalVisible: action.payload
            });

        case WithdrawKeys.SERVER_INPUT_ERROR:
            return Object.assign({}, state, {
                serverInputError: action.payload.bol,
                serverInputErrorText: action.payload.value
            });

        case WithdrawKeys.WITHDRAW_ERROR:
            return Object.assign({}, state, {
                withdrawError: action.payload.bol,
                withdrawErrorText: action.payload.value
            });

        case WithdrawKeys.GET_SERVICE_CHARGES:
            return Object.assign({}, state, {
                serviceCharges: action.payload
            });

        case WithdrawKeys.GET_BACK_CARD_INFO:
            return Object.assign({}, state, {
                backCardInfo: action.payload
            });

        case WithdrawKeys.RULE_MODAL_VISIBLE:
            return Object.assign({}, state, {
                ruleModalVisible: action.payload
            });

        case WithdrawKeys.WITHDRAW_MODAL_VISIBLE:
            return Object.assign({}, state, {
                withdrawModalVisible: action.payload
            });

        case WithdrawKeys.TO_INPUT_PAGE:
            return Object.assign({}, state, {
                toInputPage: action.payload
            });

        case WithdrawKeys.TO_VERIFY_PAGE:
            return Object.assign({}, state, {
                toVerifyPage: action.payload
            });

        case WithdrawKeys.VERIFYING_PAGE:
            return Object.assign({}, state, {
                toVerifyingPage: action.payload
            });

        case WithdrawKeys.GET_BANK_LIST:
            return Object.assign({}, state, {
                bankList: action.payload
            });

        case WithdrawKeys.SAVE_BUTTON:
            return Object.assign({}, state, {
                saveButton: action.payload
            });

        case WithdrawKeys.PROMPT:
            return Object.assign({}, state, {
                promptModalVisible: action.payload.bol,
                promptText: action.payload.value,
            });

        case WithdrawKeys.GET_TAX_LIST:
            return Object.assign({}, state, {
                taxList: action.payload
            });

        case WithdrawKeys.EXTRACT_MODAL_LOADING:
            return Object.assign({}, state, {
                extractModalLoading: action.payload
            });

        default:
            return state;
    }
};
