import { all, take, fork, call, put } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import chapterSortKeys from '../../component/index/action/keys/chapterSortKeys';
import * as workServer from '../../server/workServer';
import * as chapterSortAction from '../../component/index/action/chapterSortAction';

function* getChapterMsg() {
    while (true) {
        const { payload } = yield take(chapterSortKeys.GET_SORT_CORPUS_ID);
        let param = {
            current: -1,
            pageSize: -1,
            data: {
                novelId: payload.id
            }
        };
        try {
            const result = payload.type === '1' ? yield call(workServer.getShelfChapterList, param) : yield call(workServer.getDraftChapterList, param);
            result.data = result.data.reverse();
            yield put(chapterSortAction.getSortList(result));
        } catch (err) {
            yield errorTip('Gagal terhubung ke server, silakan coba lagi');
        }

    }
}

function* submitNewSortList() {
    while (true) {
        const { payload } = yield take(chapterSortKeys.GET_NEW_SORT_LIST);
        try {
            yield call(workServer.modifyChapterSort, payload);
            yield errorTip('Berhasil menyortir');
            yield put(chapterSortAction.sortToList(true));
            yield delay(2000);
            yield put(chapterSortAction.sortToList(false));
        } catch (err) {
            yield errorTip('Gagal terhubung ke server, silakan coba lagi');
            yield put(chapterSortAction.sortToList(false));
        }
    }
}

function* errorTip(text: string) {
    yield put(chapterSortAction.getPromptText(text));
    yield put(chapterSortAction.openPrompt(true));
    yield delay(2000);
    yield put(chapterSortAction.openPrompt(false));
}

export function* watchChapterSortSaga() {
    yield all([
        fork(getChapterMsg),
        fork(submitNewSortList),
    ]);
}
