import * as React from 'react';
import { Link } from 'react-router-dom';
import '../less/less/404.less';
import { ossUrl } from '../i18n';

export default class SecondDirectory404Page extends React.Component<any, any> {
    render() {
        return (
           <div className="container">
               <img src={`${ossUrl}404.png`} alt=""/>
               <p>Mohon Maaf, Halaman tidak ditemukan</p>
               <Link to="/novel/index" className="toIndex">Kembali</Link>
           </div>
        );
    }
}
