import { fork, all, take, call, put } from 'redux-saga/effects';
import starDetailKeys from '../../component/index/action/keys/starDetailKeys';
import * as starDetailAction from '../../component/index/action/starDetailAction';
import * as indexServer from '../../server/indexServer';

function* getStarDetailMsg() {
    while (true) {
        const { payload } = yield take(starDetailKeys.GET_DEFAULT_MONTH);
        try {
            const result = yield call(indexServer.getStarDetailMsg, payload);
            yield put(starDetailAction.getStarList(result));
        } catch (e) {
            console.log(e);
        }
    }
}
export function* watchStarDetailSaga() {
    yield all([
        fork(getStarDetailMsg),
    ]);
}
