import chapterSortKeys from './keys/chapterSortKeys';

export function initPage() {
    return { type: chapterSortKeys.INIT_PAGE };
}

export function getSortCorpusId(param: any) {
    return { type: chapterSortKeys.GET_SORT_CORPUS_ID, payload: param };
}

export function getSortList(list: any[]) {
    return { type: chapterSortKeys.GET_SORT_LIST, payload: list };
}

export function getNewSortList(param: any) {
    return { type: chapterSortKeys.GET_NEW_SORT_LIST, payload: param };
}

export function getPromptText(value: string) {
    return { type: chapterSortKeys.GET_PROMPT_TEXT, payload: value };
}

export function openPrompt(value: boolean) {
    return { type: chapterSortKeys.OPEN_PROMPT_MODAL, payload: value };
}

export function sortToList(value: boolean) {
    return { type: chapterSortKeys.SORT_TO_LIST, payload: value };
}