import * as React from 'react';
import '../less/less/login.less';
import { Redirect, Link } from 'react-router-dom';
import { Row, Col, Modal } from 'antd';
import { GoogleLogin } from 'react-google-login';
import { loginServerForFacebook, loginServerForGoogle  } from '../server/loginServer';
import { ossUrl } from '../i18n';

// import logo from '../less/images/logo.svg';

declare global {
    export interface Window {
        FB: any;
        gapi: any;
        loginWay: string;
    }
}

interface State {
    isLogin: boolean;
    promptModal: boolean;
    promptText: string;
    errCode: number;
    isLocked: boolean;
}

interface Props {
    data: any;
    getFaceBookLogin: (param: any) => void;
    getGoogleLogin: (param: any) => void;
}

class LoginPage extends React.Component<Props, State> {

    state: State = {
        isLogin: false,
        promptModal: false,
        promptText: '',
        errCode: 0,
        isLocked: false
    };

    // facebook login
    componentClicked = () => {
        // 需要获取的信息scope
        window.FB.login((response: any) => { this.statusChangeCallback(response); }, { scope: 'email'});

    }

    statusChangeCallback = (response: any) => {
        if (response.status === 'connected') {
            // 取得 accessToken
            let accessToken = response.authResponse.accessToken,  userId = response.authResponse.userID;

            let param = {
                'accessToken': accessToken,
                'userId': userId
            };
            try {
                loginServerForFacebook(param).then((result: any) => {
                    window.localStorage.setItem('TOKEN', result);
                    window.localStorage.setItem('userId', param.userId);
                    window.sessionStorage.removeItem('avatar');
                    window.sessionStorage.removeItem('name');
                    window.sessionStorage.removeItem('penName');
                    this.setState({
                        isLogin: true
                    });
                }).catch((err) => {
                    if (err.code === 106) {
                        this.lockedModalShow();
                    } else {
                        this.setState({promptText: err.message});
                        this.promptModalShow();
                        setTimeout(() => { this.promptModalCancel(); }, 2000);
                    }
                });
            } catch (err) {
                if (err.code && err.code !== 200) {
                    this.setState({promptText: err.message});
                    this.promptModalShow();
                    setTimeout(() => { this.promptModalCancel(); }, 2000);
                }
            }
        }
    }

    // Google Login
    onSuccess = (googleUser: any) => {
        // let islogin = window.gapi.auth2.getAuthInstance().isSignedIn.get();
        // let profile = googleUser.getBasicProfile();

        let res = googleUser.getAuthResponse(true);
        let param = {
            'token': res.id_token
        };
        try {
            window.sessionStorage.setItem('loginWay', 'google');
            loginServerForGoogle(param).then((result: any) => {
                window.localStorage.setItem('TOKEN', result);
                window.localStorage.removeItem('avatar');
                window.localStorage.removeItem('name');
                window.localStorage.removeItem('penName');
                this.setState({
                    isLogin: true
                });
            }).catch((err) => {
                if (err.code === 106) {
                    this.lockedModalShow();
                } else {
                    this.setState({promptText: err.message});
                    this.promptModalShow();
                    setTimeout(() => { this.promptModalCancel(); }, 2000);
                }
            });
        } catch (err) {
            if (err.code !== 200) {
                this.setState({promptText: err.message});
                this.promptModalShow();
                setTimeout(() => { this.promptModalCancel(); }, 2000);
            }
        }
    }

    onFailure = (err: any) => {
        if (err.code && err.code !== 200) {
            this.setState({promptText: err.message});
            this.promptModalShow();
            setTimeout(() => { this.promptModalCancel(); }, 2000);
        }
    }

    promptModalShow = () => {
       this.setState({promptModal: true});
    }

    promptModalCancel = () => {
       this.setState({promptModal: false});
    }

    lockedModalShow = () => {
        this.setState({isLocked: true});
    }

    lockedModalCancel = () => {
        this.setState({isLocked: false});
    }

    render () {

        const { promptModal, promptText, isLocked } = this.state;
        if (this.state.isLogin) {
            return <Redirect to="/novel/index" />;
        }

        return (
            <div className="loginPage">
                <Row style={{height: '100%'}}>
                    <Col span={10} className="loginCard">
                        <p className="masuk">Pilih cara login</p>
                        <Row className="loginBtn" >
                            <GoogleLogin
                                clientId="461948713799-f7a21m2oedsmhi3j3o94s08ga335in05.apps.googleusercontent.com"
                                buttonText="google Login"
                                onSuccess={this.onSuccess}
                                onFailure={this.onFailure}
                                render={(renderProps: any) => (
                                    <Col onClick={renderProps.onClick} className="btn">
                                        <img src={`${ossUrl}google_icon.png`} alt=""/>
                                    </Col>
                                )}
                            />
                            <Col className="btn" onClick={this.componentClicked}>
                                <img src={`${ossUrl}facebook_icon.png`} alt=""/>
                            </Col>
                        </Row>
                        <p className="prompt">
                            Dengan mendaftar, kamu setuju dengan <br/>
                            <Link target="_blank" to="/authorPact">Ketentuan Layanan</Link>{' & '}
                            <Link target="_blank" to="/privacyPolicy">Kebijakan Privasi NovelMe</Link>
                        </p>
                    </Col>
                    <Col span={14} className="matching" >
                        {/*<img src={`${ossUrl}login_logo.svg`} className="logo" alt=""/>*/}
                        {/*<img src={logo} className="logo" alt=""/>*/}
                    </Col>
                </Row>
                <Modal
                    title=""
                    visible={promptModal}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={true}
                    onCancel={this.promptModalCancel}
                    className="confirm prompt"
                >
                    <em>{promptText}</em>
                </Modal>
                <Modal
                    title=""
                    visible={isLocked}
                    centered={true}
                    footer={null}
                    maskClosable={true}
                    onCancel={this.lockedModalCancel}
                    className="confirm prompt"
                >
                    <div>
                        <p style={{textAlign: 'center'}}>Akun dibekukan, kirim email </p>
                        <p style={{textAlign: 'center'}}>ke <a href="mailto:novelme.office@gmail.com"  className="auxiliaryColor">novelme.office@gmail.com</a> dengan subjek : "Pemulihan Akun"</p>

                        <span onClick={this.lockedModalCancel} className="sureBtn">Yakin</span>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default LoginPage;
