import { all, fork, call, takeEvery, put  } from 'redux-saga/effects';
import UploadChapterKeys from '../../component/index/action/keys/uploadChapterKeys';
import * as uploadChapterAction from '../../component/index/action/uploadChapterAction';
import * as editServer from '../../server/editServer';

function* initPage() {
    yield getMyAllNovelInfoList();
}

function* getMyAllNovelInfoList() {
    try {
        let param = {
            completedStatus: 0
        };
        const result = yield call(editServer.getMyAllNovelInfoList, param);
        yield put(uploadChapterAction.getNovelList(result));
    } catch (err) {
        console.log(err);
    }
}

function* watchInitPage() {
    yield takeEvery(UploadChapterKeys.INIT_PAGE, initPage);
}

export function* watchUploadChapterSaga() {
    yield all([
        fork(watchInitPage),
    ]);
}
