import IncomeKeys from './keys/incomeKeys';

export function initPage() {
    return { type: IncomeKeys.INIT_PAGE };
}

export function getIncomeInfo(dataList: any) {
    return { type: IncomeKeys.GET_INCOME_INFO, payload: dataList };
}

export function getIncomeRecordList(dataList: any) {
    return { type: IncomeKeys.GET_INCOME_RECORD_LIST, payload: dataList };
}

export function incomeRecordListSearch(dataList: any) {
    return { type: IncomeKeys.INCOME_RECORD_LIST_SEARCH, payload: dataList };
}

export function updatePageSize(dataList: any) {
    return { type: IncomeKeys.UPDATE_INCOME_RECORD_LIST_PAGE_SIZE, payload: dataList };
}

export function getTypeList(dataList: any) {
    return { type: IncomeKeys.GET_TYPE_LIST, payload: dataList };
}

export function getSectionList(dataList: any) {
    return { type: IncomeKeys.GET_SECTION_LIST, payload: dataList };
}

export function ruleModalVisible(bol: boolean) {
    return { type: IncomeKeys.RULE_MODAL_VISIBLE, payload: bol };
}

export function getUpdatePage(page: number, pageSize: number) {
    return { type: IncomeKeys.CHANGE_CHAPTER, payload: { page, pageSize} };
}
