import { createStore, applyMiddleware, compose } from 'redux';
// import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import reducer from '../redux/reducers';
import rootSaga from '../redux/rootSaga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
  composeEnhancer(applyMiddleware(...middleware)),
);

sagaMiddleware.run(rootSaga);

export default store;
