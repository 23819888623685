import { fork, all, take, call, put  } from 'redux-saga/effects';
import rewardDetailKeys from '../../component/index/action/keys/rewardDetailKeys';
import * as rewardDetailAction from '../../component/index/action/rewardDetailAction';
import * as indexServer from '../../server/indexServer';

function* getRewardDetail() {
    while (true) {
        const { payload } = yield take(rewardDetailKeys.RWARD_LIST_PAYLOAD);
        try {
            const result = yield call(indexServer.getRewardList, payload);
            let data = result.data && result.data.map( (item: any, i: number) => {
                item.no = i + 1;
                return item;
            });
            yield put(rewardDetailAction.getRewardList({data, total: result.total}));
        } catch (e) {
            console.log(e);
        }
    }
}

function* getSaleDetail() {
    while (true) {
        const { payload } = yield take(rewardDetailKeys.SALE_LIST_PAYLOAD);
        try {
            const result = yield call(indexServer.getSaleList, payload);
            let data = result.data && result.data.map( (item: any, i: number) => {
                item.no = i + 1;
                return item;
            });
            yield put(rewardDetailAction.getSaleList({data, total: result.total}));
        } catch (e) {
            console.log(e);
        }
    }
}
export function* watchRewardDetailSaga() {
    yield all([
        fork(getRewardDetail),
        fork(getSaleDetail)
    ]);
}
