import createCorpusKeys from '../action/keys/createCorpusKeys';
import { ActionType } from '../action/type/createCorpusType';

interface State {
    imgError: boolean;
    nameError: boolean;
    selectError: boolean;
    introError: boolean;
    imgErrorText: string;
    selectErrorText: string;
    nameErrorText: string;
    introErrorText: string;
    novelCoverUrl: any;
    corpusName: string;
    corpusSelect: string;
    novelBrief: string;
    openSureModal: boolean;
    typeList: any[];
    toCorpusList: boolean;
    editCorpusMsg: any;
    promptModal: boolean;
    promptText: string;
    sourceType: number;
    novelDetailList: any;
    inCompetition: boolean;
    openCreateChapterModal: boolean;
    novelId: number;
    cancelButton: boolean;
}

const State: State = {
    novelDetailList: {
        modifyResult: {}
    },
    imgError: false,
    nameError: false,
    selectError: false,
    introError: false,
    imgErrorText: '',
    selectErrorText: '',
    nameErrorText: '',
    introErrorText: '',
    novelCoverUrl: '',
    corpusName: '',
    corpusSelect: '',
    novelBrief: '',
    openSureModal: false,
    typeList: [],
    toCorpusList: false,
    editCorpusMsg: {},
    promptModal: false,
    promptText: '',
    sourceType: 1,
    inCompetition: false,
    openCreateChapterModal: false, // 用来判断弹出框是否显示创建成功跳到创建章节按钮
    novelId: -1,
    cancelButton: true
};

export const createCorpusReducer = (state = State, action: ActionType) => {
    switch (action.type) {

        case createCorpusKeys.INIT_PAGE:
            return State;

        case createCorpusKeys.GET_NOVEL_TYPE_LIST:
            return Object.assign({}, state, {
                typeList: action.payload
            });

        case createCorpusKeys.IMG_ERROR:
            return Object.assign({}, state, {
                imgError: action.payload.visible,
                imgErrorText: action.payload.text
            });

        case createCorpusKeys.SELECT_ERROR:
            return Object.assign({}, state, {
                selectError: action.payload.visible,
                selectErrorText: action.payload.text
            });

        case createCorpusKeys.INTRO_ERROR:
            return Object.assign({}, state, {
                introErrorText: action.payload.text,
                introError: action.payload.visible
            });

        case createCorpusKeys.NAME_ERROR:
            return Object.assign({}, state, {
                nameErrorText: action.payload.text,
                nameError: action.payload.visible
            });

        case createCorpusKeys.GET_NOVEL_COVER:
            return Object.assign({}, state, {
                novelCoverUrl: action.payload
            });

        case createCorpusKeys.GET_NOVEL_BRIEF:
            return Object.assign({}, state, {
                novelBrief: action.payload
            });

        case createCorpusKeys.OPEN_CREATE_SURE_MODAL:
            return Object.assign({}, state, {
                openSureModal: action.payload
            });

        case createCorpusKeys.TO_CORPUS_LIST:
            return Object.assign({}, state, {
                toCorpusList: action.payload
            });

        case createCorpusKeys.NOVEL_DETAIL_LIST:
            return Object.assign({}, state, {
                novelDetailList: action.payload,
                novelCoverUrl: action.payload.coverUrl,
                corpusName: action.payload.name,
                corpusSelect: action.payload.typeName,
                novelBrief: action.payload.brief,
                sourceType: action.payload.sourceType,
                inCompetition: action.payload.inCompetition,
            });

        case createCorpusKeys.VISIBLE_PROMPT:
            return Object.assign({}, state, {
                promptModal: action.payload.bol,
                promptText: action.payload.value
            });

        case createCorpusKeys.OPEN_CREATE_CHAPTER_MODAL:
            return Object.assign({}, state, {
                openCreateChapterModal: action.payload
            });

        case createCorpusKeys.GET_NOVEL_ID:
            return Object.assign({}, state, {
                novelId: action.payload
            });

        case createCorpusKeys.CANCEL_BUTTON:
            return Object.assign({}, state, {
                cancelButton: action.payload
            });

        default:
            return state;
    }
};
