import { all, call, fork, takeEvery, put, select, take } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import workListKeys from '../../component/index/action/keys/workListKeys';
import * as workServer from '../../server/workServer';
import * as workListAction from '../../component/index/action/workListAction';
import * as Mean from '../../i18n/index';
import { errorTip } from './common';

function* initWorkPage() {
    yield getMyNovelList();
}

function* getMyNovelList(id?: any) {
    const data = yield select();
    const work = data.workListReducer;
    try {
        yield put(workListAction.loading(true));
        let param = {
            data: work.filterStatus,
            current: work.page.current,
            pageSize: work.page.pageSize,
        };
        const result = yield call(workServer.getMyNovelList, param);
        if (result.total === 0) {
            yield put(workListAction.workListNoData(true));
        } else {
            yield put(workListAction.workListNoData(false));
            const detail = yield call(workServer.getNovelDetailById, typeof id === 'number' ? id : result.data[0].id);
            // 获取任务进度
            const taskDetail = yield call(workServer.getTaskSchedule, detail.id);
            detail.taskMsg = taskDetail;
            sessionStorage.setItem('currentNovel', typeof id === 'number' ? id : result.data[0].id);
            yield put(workListAction.getCorpusDetail(detail));
        }
        yield put(workListAction.getWorkList(result));
        yield put(workListAction.loading(false));
    } catch (err) {
        yield errorTip(Mean.NO_NETWORK, workListAction.visiblePrompt);
    }
}

function* watchDeleteNovelList() {
    while (true) {
        const { payload } = yield take(workListKeys.DEL_CORPUS);
        try {
            yield call(workServer.delCorpus, [payload]);
            yield put(workListAction.visiblePrompt({bol: true, value: Mean.DELETE_SUCCESS}));
            yield delay(1000);
            yield put(workListAction.visiblePrompt({bol: false}));
            yield getMyNovelList();
        } catch (err) {
            if (err.code = 312) {
                yield errorTip('Novel ada isi, tidak bisa dihapus', workListAction.visiblePrompt);
            } else {
                yield errorTip(Mean.NO_NETWORK, workListAction.visiblePrompt);
            }
        }
    }
}

function* watchGetNovelDetail() {
    while (true) {
        const { payload } = yield take(workListKeys.GET_CORPUS_DETAIL_BY_ID);
        try {
            let result = yield call(workServer.getNovelDetailById, payload);
            // 获取任务进度
            const taskDetail = yield call(workServer.getTaskSchedule, result.id);
            result.taskMsg = taskDetail;
            yield put(workListAction.getCorpusDetail(result));
        } catch (err) {
            yield errorTip(Mean.NO_NETWORK, workListAction.visiblePrompt);
        }
    }
}

function* watchSubmitCompetition() {
    while (true) {
        const { payload } = yield take(workListKeys.IN_COMPETITION);
        try {
            yield call(workServer.submitToCompetition, payload);
            yield put(workListAction.visiblePrompt({bol: true, value: 'Sukses mengirim'}));
            yield delay(1000);
            yield put(workListAction.visiblePrompt({bol: false}));
            yield getMyNovelList();
        } catch (err) {
            if (err.code === 322) {
                yield errorTip('Karya ini tidak bisa ikut lomba', workListAction.visiblePrompt);
            } else {
                yield errorTip(Mean.NO_NETWORK, workListAction.visiblePrompt);
            }
        }
    }
}

function* paidNovel() {
    while (true) {
        const { payload } = yield take(workListKeys.GET_PAID_NOVEL);
        try {
            yield call(workServer.modifyPaymentStatus, payload.id);
            yield put(workListAction.paidNovelModal({bol: true, value: Mean.PAID_OK}));
            console.log(payload.id);
            yield getMyNovelList(payload.id);

        } catch (e) {
            yield errorTip('Kesalahan sistem, silakan coba lagi nanti', workListAction.visiblePrompt);
        }
    }
}

export function* watchInitPage() {
    yield takeEvery(workListKeys.INIT_PAGE, initWorkPage);
}

function* watchChangeStatus() {
    yield takeEvery(workListKeys.GET_CORPUS_STATUS, getMyNovelList);
}

function* watchChangePageSize() {
    yield takeEvery(workListKeys.CHANGE_PAGE_SIZE, getMyNovelList);
}

export function* watchWorkListSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchDeleteNovelList),
        fork(watchChangeStatus),
        fork(watchSubmitCompetition),
        fork(watchGetNovelDetail),
        fork(watchChangePageSize),
        fork(paidNovel)
    ]);
}
