import LoginPage from '../LoginPage';
import { connect } from 'react-redux';
import * as loginAction from '../action/loginAction';

const mapStateToProps = (state: any) => {
    return {
        data: state.loginReducer
    };
};

const mapDispatchToProps = {
    getFaceBookLogin: loginAction.getFaceBookLogin,
    getGoogleLogin: loginAction.getGoogleLogin
};

function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(LoginPage);
