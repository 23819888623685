import editKeys from './keys/editKeys';

export function initPage() {
    return { type: editKeys.INIT_PAGE };
}

export function getAllNovelList(list: any[]) {
    return { type: editKeys.GET_ALL_NOVEL_INFO_LIST, payload: list };
}

export function getSensitiveWordList(list: any[]) {
    return { type: editKeys.GET_SENSITIVE_WORD_LIST, payload: list };
}

export function saveChapter(param: any) {
    return { type: editKeys.SAVE_CHAPTER, payload: param };
}

export function getHasReleaseNovelId(id: number) {
    return { type: editKeys.GET_HAS_RELEASE_NOVEL_ID, payload: id  };
}

export function visiblePrompt(param: {bol: boolean, value?: string }) {
    return { type: editKeys.VISIBLE_PROMPT, payload: param };
}

export function autoSaveChapterTooltipTextVisible(value: boolean) {
    return { type: editKeys.AUTO_SAVE_CHAPTER_TOOLTIP_TEXT, payload: value };
}

export function getSaveChapterId(id: number) {
    return { type: editKeys.GET_SAVE_CHAPTER_ID, payload: id };
}

export function editToChapterList(value: boolean) {
    return { type: editKeys.EDIT_TO_LIST, payload: value };
}

export function getNovelId(id: string) {
    return { type: editKeys.GET_NOVEL_ID_FOR_URL, payload: id };
}
