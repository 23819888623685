import Header from '../Header';
import { connect } from 'react-redux';
import * as indexAction from '../../component/index/action/indexAction';

const mapStateToProps = (state: any) => {
    return {
        data: state.indexReducer,
        authorRegisterModalVisible: state.indexReducer.authorRegisterModalVisible
    };
};

const mapDispatchToProps = {
    initIndexPage: indexAction.initPage,
    openAuthorRegisterModal: indexAction.openAuthorRegisterModal,
};

function mergeProps(stateProps: Object, dispatchProps: Object, ownProps: Object) {
    return Object.assign({}, ownProps, stateProps, dispatchProps);
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Header);
