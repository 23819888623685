import IncomeKeys from '../action/keys/incomeKeys';
import { ActionType } from '../action/type/incomeType';

export type SearchType = {
    id: number;
    name: string;
    description?: string;
};

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    incomeInfo: any;
    incomeRecordList: any[];
    total: number;
    typeList: SearchType[];
    sectionList: SearchType[];
    searchData: any;
    page: PageData;
    ruleModalVisible: boolean;
}

const initialState: StoreState = {
    incomeInfo: {},
    incomeRecordList: [],
    total: 0,
    typeList: [],
    sectionList: [],
    searchData: {},
    page: {
        current: 1,
        pageSize: 10,
    },
    ruleModalVisible: false,
};

export const incomeReducer = (state = initialState, action: ActionType ) =>  {
    switch (action.type) {

        case IncomeKeys.INIT_PAGE:
            return initialState;

        case IncomeKeys.GET_INCOME_INFO:
            return Object.assign({}, state, {
                incomeInfo: action.payload
            });

        case IncomeKeys.GET_INCOME_RECORD_LIST:
            return Object.assign({}, state, {
                incomeRecordList: action.payload.dataList,
                total: action.payload.total,
            });

        case IncomeKeys.GET_TYPE_LIST:
            return Object.assign({}, state, {
                typeList: action.payload
            });

        case IncomeKeys.GET_SECTION_LIST:
            return Object.assign({}, state, {
                sectionList: action.payload
            });

        case IncomeKeys.INCOME_RECORD_LIST_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload,
                page: {
                    current: 1
                }
            });

        case IncomeKeys.UPDATE_INCOME_RECORD_LIST_PAGE_SIZE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize,
                }
            });

        case IncomeKeys.RULE_MODAL_VISIBLE:
            return Object.assign({}, state, {
                ruleModalVisible: action.payload
            });

        case IncomeKeys.CHANGE_CHAPTER:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.page,
                    pageSize: action.payload.pageSize
                }
            });

        default:
            return state;
    }
};
