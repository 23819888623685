import chapterSortKeys from '../action/keys/chapterSortKeys';
import { ActionTypes } from '../action/type/chapterSortType';

interface State {
    chapterList: any[];
    promptText: string;
    promptModal: boolean;
    toList: boolean;
}

const initalState: State = {
    chapterList: [],
    promptText: '',
    promptModal: false,
    toList: false
};

export const chapterSortReducer = (state = initalState, action: ActionTypes) => {
    switch (action.type) {

        case chapterSortKeys.INIT_PAGE:
            return initalState;

        case chapterSortKeys.GET_SORT_LIST:
            return Object.assign({}, state, {
                chapterList: action.payload
            });

        case chapterSortKeys.GET_PROMPT_TEXT:
            return Object.assign({}, state, {
                promptText: action.payload
            });

        case chapterSortKeys.OPEN_PROMPT_MODAL:
            return Object.assign({}, state, {
                promptModal: action.payload
            });

        case chapterSortKeys.SORT_TO_LIST:
            return Object.assign({}, state, {
                toList: action.payload
            });

        default:
            return state;
    }
};