import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    t: any;
}

export default class Footer extends React.Component<Props, any> {
    render() {

        const { t } = this.props;

        return (
            <div className="footer">
                <div className="footer_con">
                    <ul>
                        <li>
                            <Link to={{pathname: `/novel/noticeDetail`, search:  `?type=joinUs`}}>{t('JOIN_US')}</Link>
                        </li>
                        <li>
                            <Link to={{pathname: `/novel/noticeDetail`, search:  `?type=aboutUs`}}>{t('ABOUT_US')}</Link>
                        </li>
                        <li>
                            <Link to={{pathname: `/novel/noticeDetail`, search:  `?type=contactUs`}}>{t('CONTACT_US')}</Link>
                        </li>
                        <li>
                            <Link to={{pathname: `/novel/noticeDetail`, search:  `?type=serviceAgreement`}}>{t('PRIVACY_POLICY')}</Link>
                        </li>
                        <li>
                            <Link to={{pathname: `/novel/noticeDetail`, search:  `?type=privacyPolicy`}}>{t('SERVICE_AGREEMENT')}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
