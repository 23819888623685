import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as Loadable from 'react-loadable';
import Main from '../../layout/PageLayout';
// import edit from './view/setting/editSetting';
import SecondDirectory404Page from '../../layout/secondDirectory404Page';

const EditComponent = Loadable({
    loader: () => import('./view/setting/editSetting'),
    loading: () => <div />,
});

export const editRouter = (
    <Main>
        <Switch>
            <Route path="/edit/editor" component={EditComponent} />
            <Route component={SecondDirectory404Page} />
        </Switch>
    </Main>
);
