enum WithdrawDetailKeys {
    INIT_PAGE = 'WITHDRAW_DETAIL_PAGE_INIT_PAGE',
    GET_WITHDRAW_DATA_LIST = 'WITHDRAW_DETAIL_PAGE_GET_INCOME_RECORD_LIST',
    DATA_LIST_SEARCH = 'WITHDRAW_DETAIL_PAGE_DATA_LIST_SEARCH',
    UPDATE_PAGE_SIZE = 'WITHDRAW_DETAIL_PAGE_UPDATE_PAGE_SIZE',
    GET_WIthDRAW_ORDER_BANK_CARD = 'WITHDRAW_DETAIL_GET_WIthDRAW_ORDER_BANK_CARD',
    GET_WITHDRAW_ORDER_ID = 'WITHDRAW_DETAIL_GET_WITHDRAW_ORDER_ID',
    GET_WITHDRAW_ORDER_MSG = 'WITHDRAW_DETAIL_GET_WITHDRAW_ORDER_MSG',
    LOADING = 'WITHDRAW_DETAIL_LOADING',
    PROMPT_MODAL = 'WITHDRAW_DETAIL_PROMPT_MODAL',
    TO_WITHDRAW = 'WITHDRAW_DETAIL_TO_WITHDRAW'
}

export default WithdrawDetailKeys;
