import userInfoKeys from '../action/keys/userInfoKeys';
import { ActionType } from '../action/type/userInfoType';

export interface StoreState {
    contactInfo: any;
    personData: any;
    promptModalVisible: boolean;
    becomeAuthorError: boolean;
    modifyPenNameError: boolean;
    modifyContactInfoError: boolean;
    verificationCodeError: boolean;
    emailError: boolean;
    serverPhoneError: boolean;
    promptText: string;
    serverPhoneErrorText: string;
    becomeAuthorErrorText: string;
    modifyPenNameErrorText: string;
    modifyContactInfoErrorText: string;
    verificationCodeErrorText: string;
    emailErrorText: string;
    becomeAuthorButton: boolean;
    penNameButton: boolean;
    userInfoButton: boolean;
}

const initialState: StoreState = {
    contactInfo: {},
    personData: {},
    promptModalVisible: false,
    becomeAuthorError: false,
    modifyPenNameError: false,
    modifyContactInfoError: false,
    verificationCodeError: false,
    emailError: false,
    serverPhoneError: false,
    promptText: '',
    serverPhoneErrorText: '',
    becomeAuthorErrorText: '',
    modifyPenNameErrorText: '',
    modifyContactInfoErrorText: '',
    verificationCodeErrorText: '',
    emailErrorText: '',
    becomeAuthorButton: false,
    penNameButton: false,
    userInfoButton: false,
};

export const userInfoReducer = (state = initialState, action: ActionType ) =>  {
    switch (action.type) {

        case userInfoKeys.INIT_PAGE:
            return initialState;

        case userInfoKeys.GET_CONTACT_INFO:
            return Object.assign({}, state, {
                contactInfo: action.payload,
            });

        case userInfoKeys.GET_PERSON_DATA:
            return Object.assign({}, state, {
                personData: action.payload,
            });

        case userInfoKeys.BECOME_AUTHOR_BUTTON:
            return Object.assign({}, state, {
                becomeAuthorButton: action.payload,
            });

        case userInfoKeys.SAVE_PEN_NAME_BUTTON:
            return Object.assign({}, state, {
                penNameButton: action.payload,
            });

        case userInfoKeys.USER_INFO_BUTTON:
            return Object.assign({}, state, {
                userInfoButton: action.payload,
            });

        case userInfoKeys.PROMPT:
            return Object.assign({}, state, {
                promptModalVisible: action.payload.bol,
                promptText: action.payload.value,
            });

        case userInfoKeys.BECOME_AUTHOR_ERROR:
            return Object.assign({}, state, {
                becomeAuthorError: action.payload.bol,
                becomeAuthorErrorText: action.payload.value,
            });

        case userInfoKeys.MODIFY_PEN_NAME_ERROR:
            return Object.assign({}, state, {
                modifyPenNameError: action.payload.bol,
                modifyPenNameErrorText: action.payload.value,
            });

        case userInfoKeys.MODIFY_CONTACT_INFO_ERROR:
            return Object.assign({}, state, {
                modifyContactInfoError: action.payload.bol,
                modifyContactInfoErrorText: action.payload.value,
            });

        case userInfoKeys.VERIFICATION_CODE_ERROR:
            return Object.assign({}, state, {
                verificationCodeError: action.payload.bol,
                verificationCodeErrorText: action.payload.value,
            });

        case userInfoKeys.PHONE_ERROR:
            return Object.assign({}, state, {
                serverPhoneError: action.payload.bol,
                serverPhoneErrorText: action.payload.value,
            });

        case userInfoKeys.EMAIL_ERROR:
            return Object.assign({}, state, {
                emailError: action.payload.bol,
                emailErrorText: action.payload.value,
            });

        default:
            return state;
    }
};
