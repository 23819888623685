import { fork, all, takeEvery, put, take, call, select } from 'redux-saga/effects';
import WithdrawKeys from '../../component/settlement/action/keys/withdrawKeys';
import * as withdrawAction from '../../component/settlement/action/withdrawAction';
import * as indexServer from '../../server/indexServer';
import * as settlementServer from '../../server/settlementServer';
import * as workServer from '../../server/workServer';

function* initPage() {
    yield getPersonalData();
}

function* getPersonalData() {
    try {
        yield put(withdrawAction.dataLoading(true));
        const result = yield call(indexServer.getPersonalData);
        let authenticationStatus = result.authenticationStatus;
        if (authenticationStatus === 0 || authenticationStatus === 3) {
            yield put(withdrawAction.toVerify(true));
        } else if (authenticationStatus === 1) {
            yield put(withdrawAction.verifying(true));
        }
        yield getBankCardInfo();
        yield getWithdrawInfo();
        yield getBankList();
        yield GetServiceCharges();
    } catch (err) {
        console.log(err);
        yield put(withdrawAction.dataLoading(false));
    }
}

function* getWithdrawInfo() {
    try {
        const result = yield call(settlementServer.getWithdrawInfo);
        yield put(withdrawAction.getWithdrawInfo(result));
        yield put(withdrawAction.toInput(false));
    } catch (err) {
        if (err.code === 319) {
            yield put(withdrawAction.dataLoading(true));
            yield put(withdrawAction.toInput(true));
            yield put(withdrawAction.dataLoading(false));
        } else {
            console.log(err);
        }
    }
}

function* getBankCardInfo() {
    try {
        yield put(withdrawAction.dataLoading(true));
        const result = yield call(settlementServer.getBankCardInfo);
        yield put(withdrawAction.toInput(false));
        yield put(withdrawAction.getBankCardInfo(result));
        yield put(withdrawAction.dataLoading(false));

    } catch (err) {
        if (err.code === 319) {
            yield put(withdrawAction.dataLoading(true));
            yield put(withdrawAction.toInput(true));
            yield put(withdrawAction.dataLoading(false));
        } else {
            yield put(withdrawAction.dataLoading(false));
            console.log(err);
        }
    }
}

function* GetServiceCharges() {
    try {
        const result = yield call(settlementServer.getServiceCharges);
        yield put(withdrawAction.getServiceCharges(result));
    } catch (err) {
        console.log(err);
    }
}

function* watchWithdrawApply() {
    while (true) {
        try {
            const { payload } = yield take(WithdrawKeys.GET_WITHDRAW_PRICE);
            yield call(settlementServer.withdrawApply, payload);
            yield put(withdrawAction.withdrawModalVisible(false));
            yield getWithdrawInfo();
            yield put(withdrawAction.applySuccessModal(true));
        } catch (err) {
            if (err.code === 319) {
                yield put(withdrawAction.dataLoading(true));
                yield put(withdrawAction.toInput(true));
                yield put(withdrawAction.dataLoading(false));
            } else if (err.code === 320) {
                yield put(withdrawAction.withdrawError(true, 'Permintaan penarikan gagal'));
            } else if (err.code === 335) {
                yield put(withdrawAction.withdrawError(true, 'Jumlah bonus berubah, silakan baharui halaman'));
            }  else if (err.code === 345) {
                yield put(withdrawAction.withdrawError(true, 'Tanggal 1-14 tidak bisa menarik'));
            }
        }
    }
}

function* watchModifyBankCard() {
    while (true) {
        try {
            const { payload } = yield take(WithdrawKeys.MODIFY_BACK_CARD_INFO);
            yield put(withdrawAction.saveButton(true));
            const data = yield select();
            const reducer = data.withdrawReducer;
            if (payload.taxCardPic && payload.taxCardPic[0].originFileObj) {
                let result = yield call(workServer.uploadImgToServer, payload.taxCardPic[0].originFileObj, '5');
                payload.taxCardPic = result[0];
            } else {
                if (payload.taxCardNo) {
                    payload.taxCardPic = reducer.backCardInfo.taxCardPic;
                } else {
                    delete payload.taxCardPic;
                }
            }
            yield call(settlementServer.modifyBankCard, payload);
            yield getWithdrawInfo();
            yield getBankCardInfo();
            yield GetServiceCharges();
            yield put(withdrawAction.saveButton(false));
        } catch (err) {
            yield put(withdrawAction.saveButton(false));
            if (err.code === 319) {
                yield put(withdrawAction.dataLoading(true));
                yield put(withdrawAction.toInput(true));
                yield put(withdrawAction.dataLoading(false));
            } else {
                console.log(err);
            }
        }
    }
}

function* watchAuthentication() {
    while (true) {
        try {
            const { payload } = yield take(WithdrawKeys.AUTHENTICATION);
            yield call(settlementServer.authentication, payload);
            yield initPage();
            yield put(withdrawAction.dataLoading(true));
            yield put(withdrawAction.toInput(false));
            yield put(withdrawAction.dataLoading(false));
        } catch (err) {
            if (err.code === 317) {
                yield put(withdrawAction.serverInputError(true, 'Verifikasi gagal'));
            } else if (err.code === 318) {
                yield put(withdrawAction.serverInputError(true, 'Belum lolos verifikasi nama'));
            } else if (err.code === 319) {
                yield put(withdrawAction.serverInputError(true, 'Belum lolos verifikasi ID'));
            } else if (err.code === 321) {
                yield put(withdrawAction.serverInputError(true, 'Kena limit harian, coba lagi besok'));
            } else {
                console.log(err);
            }
        }
    }
}

function* getBankList() {
    try {
        const result = yield call(settlementServer.getBankList);
        yield put(withdrawAction.getBackList(result));
    } catch (err) {
        console.log(err);
    }
}

/**
 * get tax list
 */

function* getTaxList() {
    while (true) {
        try {
            yield take(WithdrawKeys.GET_TAX_PARAM);
            yield put(withdrawAction.extractModalLoading(true));
            const result = yield call(settlementServer.withdrawDetailForApply);
            yield put(withdrawAction.getTaxList(result));
            yield put(withdrawAction.extractModalLoading(false));
        } catch (e) {
            console.log(e);
        }
    }
}

/**
 * get new canWithdrawAmount
 */
function* getNewAmount() {
    while (true) {
        try {
            const { payload } = yield take(WithdrawKeys.WITHDRAW_MODAL_VISIBLE);
            if (payload) {
                yield getWithdrawInfo();
            }
        } catch (e) {
            console.log(e);
        }
    }
}

function* watchInitPage() {
    yield takeEvery(WithdrawKeys.INIT_PAGE, initPage);
}

export function* watchWithdrawInfoSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchWithdrawApply),
        fork(watchAuthentication),
        fork(watchModifyBankCard),
        fork(getTaxList),
        fork(getNewAmount)
    ]);
}
