import RewardDetailKeys from '../action/keys/rewardDetailKeys';
import { ActionType } from '../action/type/rewardDetailType';

interface State {
    rewardDetail: any;
    saleDetail: any;
}

const State: State = {
    rewardDetail: {},
    saleDetail: {}
};

export const rewardDetailReducer = (state = State, action: ActionType) => {
    switch (action.type) {
        case RewardDetailKeys.GET_RWARD_LIST:
            return Object.assign({}, state, {
                rewardDetail: action.payload
            });

        case RewardDetailKeys.GET_SALE_LIST:
            return Object.assign({}, state, {
                saleDetail: action.payload
            });
        default:
            return state;
    }
};
