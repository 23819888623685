import * as React from 'react';
import { Modal } from 'antd';
import { clearAuthToken } from '../utils/xhr';

let con = <div><p style={{textAlign: 'center'}}>Akun dibekukan, kirim email </p><p style={{textAlign: 'center'}}>ke <a href="mailto:novelme.office@gmail.com"  className="auxiliaryColor">novelme.office@gmail.com</a> dengan subjek : "Pemulihan Akun"</p></div>;

export default {
    modal() {
        Modal.warning({
            content: con,
            width: 550,
            className: 'confirm prompt',
            centered: true,
            okText: 'Yakin',
            onOk() {
                clearAuthToken();
                window.location.href = '/#/login';
            },
            onCancel() {
                clearAuthToken();
                window.location.href = '/#/login';
            }
        });
    }
};
