// oss url
export const ossUrl = 'https://jkt-web-cdn.novelme.id/novelme/author/pic/';

// 导航栏
export const HOME_PAGE = 'Beranda';
export const WORK_MANAGER = 'Pengaturan Karya';
export const CORPUS_MANAGER = 'Pengaturan Buku';
export const UPLOAD_SECTION = 'Unggah Bab';
export const CREATION = 'Tulis Bab Baru';
export const WRITER_SETTLEMENT = 'Pendapatan';
export const WITHDRAW = 'Penarikan';
export const HELP = 'Bantuan';
export const PERSONAL_DATA = 'Informasi Pribadi';
export const CERTIFICATION = 'Verifikasi akun';

// 页尾
export const JOIN_US = 'Bergabunglah Bersama Kami';
export const ABOUT_US = 'Tentang Kami';
export const CONTACT_US = 'Hubungi Kami';
export const PRIVACY_POLICY = 'Perjanjian Layanan';
export const SERVICE_AGREEMENT = 'Kebijakan Privasi';

// 提示
export const EXIT_SUCCESS = 'Sukses keluar';
export const THINK_AGAIN = 'Ubah kembali';
export const LEAVE = 'Tinggalkan halaman';

// 首页
export const CREATE_CORPUS = 'Buat Buku';
export const NOVEL_NUMBER = 'Jumlah novel';
export const DRAFT_NUMBER = 'Jumlah bab';
export const REVIEW_DRAFT_NUMBER = 'Terkirim';
export const NEW_SUBSCRIPTIONS_YESTERDAY = 'Subscriber baru kemarin';
export const NEW_READING_YESTERDAY = 'Pembaca baru kemarin';
export const TOTAL_SUBSCRIPTIONS = 'Total Subscriber';
export const TOTAL_READING = 'Total Dibaca';
export const ANNOUNCEMENT = 'Pengumuman';
export const SURE = 'Yakin';
export const UNVERIFIED = 'Belum Verifikasi';
export const VERIFY = 'Terverifikasi';
export const VERIFY_FAIL = 'Gagal verifikasi';
export const VERIFIED = 'Sedang verifikasi';
export const VERIFIED_NAME = 'Verifikasi nama belum disetujui, silakan kirim ulang setelah diperbaiki';
export const ACTIVITY_WORD = 'Kompetisi menulis';
export const ALLOW = 'Setuju';
export const DRAFT = 'Draft';
export const REVIEW_PASS = 'Lolos review';

// 个人资料
export const AUTHOR_INFO = 'Informasi';
export const AUTHOR_NAME = 'Nama Pena';
export const INPUT_AUTHOR_NAME = 'Silakan masuk nama Panggilan';
export const AUTHOR_NOTICE = 'Pastikan nama sudah benar karena kamu perlu mendaftarkan ulang untuk mengganti';
export const CONTACT_WAY = 'Informasi kontak';
export const PHONE = 'Nomer HP';
export const VERIFICATION_CODE = 'Kode OTP';
export const E_MAIL = 'E-mail';
export const FACEBOOK_ACCOUNT = 'Link Facebook';
export const INS_ACCOUNT = 'Link Instagram';
export const SAVE_CHANGE = 'Kirim';
export const EDIT = 'Ubah';
export const REGAIN = 'Ambil lagi';
export const ADVICE_FILL_IN = 'Mohon diisi';
export const INPUT_AUTHOR_PHONE = 'Silakan masukkan no HP yang aktif';

// 作品管理
export const ALL = 'Semua';
export const NEW = 'Baru';
export const HAS_ON = 'Terpublikasi';
export const REMOVE_ON = 'Diturunkan';
export const UNDER_REVIEW = 'Sedang direview';
export const UNDER_FAILED = 'Review gagal';
export const NOT_BOOK = 'Belum ada buku, ayo tulis yang baru!';
export const THE_CURRENT_COLLECTION = 'Total ada';
export const THIS = 'buku';
export const SECTION_NUMBER = 'Jumlah Bab:';
export const READ = 'Dibaca:';
export const SUBSCRIBER = 'Subscriber:';
export const UPDATE_TIME = 'Waktu diperbarui:';
export const NEW_SECTION = 'Bab terbaru:';
export const WRITE_NEW_CHAPTER = 'Tulis bab baru';
export const VIEW_SECTION_DETAIL = 'Lihat Detail';
export const SECTION_ERROR = 'Karya yang kamu kirimkan mengalami kendala, silakan kirimkan kembali atau hubungi nexttopwriter@novelmeid.com';
export const DELETE_SURE = 'Yakin hapus seluruh buku? Setelah dihapus tidak dapat dikembalikan!';
export const CALL_OF = 'Batal';
export const SECTION_MANAGER = 'Pengaturan Buku';
export const CLICK_UPLOAD = 'Pilih File';
export const RETURN_UPLOAD = 'Upload ulang';
export const SECTION_INFO = 'Informasi buku';
export const SECTION_COVER = 'Cover Buku';
export const PLEASE_UPLOAD_IMG = 'Silakan unggah foto';
export const SUCCESS_IMG = 'Gambar jadi';
export const IMG_ERROR = 'Format gambar tidak sesuai, silakan unggah ulang';
export const MORE_THAN_WORD = 'Sinopsis buku antara 20-1000 huruf';
export const CREATE_SECTION_TEXT = 'Setelah buku ditentukan, judul dan genre tidak dapat diganti. Silakan pastikan informasi sudah benar';
export const THINKING = 'Ubah kembali';
export const CREATE = 'Buat';
export const DELETE_SUCCESS = 'Berhasil dihapus';
export const NO_NETWORK = 'Gagal terhubung ke server, silakan coba lagi';
export const NONE = 'Tidak';
export const ATTENTION_PLEASE = 'Perhatian: Jangan menunggah materi yang melanggar hak cipta dan peraturan perundangan yang berlaku. Tim NovelMe berhak menolak semua konten yang tidak sesuai.';
export const CHANPTER_NAME = 'Nama Bab maks. 60 huruf';
export const AUTO_SAVE = 'Bab otomatis tersimpan...';
export const RECOVER_CHAPTER = 'Apakah ingin mengembalikan bab?';
export const RESTORE = 'Kembalikan';
export const SURE_SUBMIT = 'Bab yang dikirimkan tidak dapat diubah, yakin dikirim?';
export const SURE_DELETE = 'Yakin hapus bab ?';
export const OVER_THAN = 'Otomatis terhapus jika lebih dari';
export const AUTO_DELETE = 'hari';
export const MODIFY_SECTION = 'Ubah Pengaturan Buku';
export const CATEGORY = 'kategori';
export const VIEW_CHAPTER = 'Lihat';
export const CHAPTER = 'Bab';
export const DELETE = 'Hapus';
export const TIME = 'Jam';
export const PUBLISHED = 'Sudah dipublikasikan';
export const UNPUBLISHED = 'Belum dipublikasikan';
export const RECYCLE_BIN = 'Recycle Bin';
export const CHAPTER_MANAGER = 'Pengaturan Bab';
export const PLEASE_INPUT_SECTION_TYPE = 'Silakan pilih genre buku!';
export const PLEASE_INPUT_SECTION_BRIEF = 'Silakan masukkan sinopsis!';
export const NAME_NOT_MORE_THAN_20 = 'Sinopsis buku antara 20-1000 huruf';
export const BRIEF_NOT_INCLUDE_CHAR = 'Sinopsis tidak boleh mengandung karakter khusus';
export const PLEASE_INPUT_SECTION_NAME = 'Silakan isi nama buku!';
export const NAME_NOT_MORE_THAN_HUNDRED = 'Nama buku maks. 100 huruf';
export const NAME_NOT_INCLUDE_CHAR = 'Nama buku tidak boleh mengandung karakter khusus';
export const PLEASE_WAIT_MODIFY_SUCCESS = 'Perubahan novel berhasil';
export const PLEASE_WAIT_MODIFY = 'Perubahan novel berhasil, silakan tunggu';
export const CREATE_FAILED = 'Pembuatan buku gagal';
export const CREATE_SUCCESS = 'Pembuatan novel berhasil';
export const PLEASE_WAIT = 'Sedang menjadikan buku, silakan tunggu';
export const TYPE_PLEASE = 'Silakan pilih Genre';
export const NAME_PLEASE = 'Silakan tulis Judul';
export const BRIEF_PLEASE = 'Silakan isi Ringkasan';
export const ONLY_UPLOAD = 'Unggah';
export const MODIFY_CHAPTER = 'Perbaiki bab';
export const NOVEL_DETAIL = 'Detail buku';
export const RELEASE_SUCCESS = 'Sukses mengirim';
export const RESTORE_CHAPTER = 'Bab telah dikembalikan ke buku';
export const DELEDE_SUCCESS = 'Berhasil dihapus, dapat dikembalikan lewat tempat sampah';
export const SIZE_ERROR = 'Tiap file maksimal 1MB dengan format';
export const FORMAT_ERROR = 'Format file yang diunggah tidak tepat, silakan unggah ulang';
export const TAKE_MATCH = 'Ikut serta';
export const STAR_TICKET = 'Star:';
export const RANKING = 'Ranking:';
export const GO_VER = 'Verifikasi';
export const ORIGIN = 'Orisinil:';
export const CLICK_GET = 'Ambil';

// 新建文集
export const ATTENTION = 'Informasi : Menurut hukum yang berlaku, pihak perusahaan harus membayarkan pajak perorangan ketika menerima pembayaran. Pastikan informasi kamu benar. Informasi ini tidak akan disebarluaskan.';
export const CREATE_NEW_BOOK = 'Buat Buku Baru';
export const SELECT_CORPUS = 'Silakan pilih buku';
export const INPUT_SECTION_NAME = 'Masukkan nama bab';
export const INPUT_CONTENT = 'Masukkan konten…';
export const SUBMIT = 'Kirim';
export const SAVE = 'Simpan';
export const WORD_NUMBER = 'Jumlah Kata';
export const LEAVE_PAGE = 'Yakin tinggalkan halaman? Semua perubahan tidak disimpan';
export const SET_PEN_NAME = 'Kamu belum ada nama panggilan, ayo tentukan!';

// 实名认证
export const IDENTITY_INFO = 'Verifikasi Nama';
export const NAME = 'Nama Depan';
export const SURNAME = 'Nama Belakang';
export const VALID_NUMBER = 'No ID (Kartu Pelajar/KTP/SIM) yang masih berlaku';
export const SUBMIT_INFO = 'Pengiriman data';
export const AUTHENTICATION = 'Sedang verifikasi';
export const UPLOAD_MYSELF_PHOTO = 'Silakan unggah foto ID';
export const UPLOAD_MYSELF_ON_HAND_PHOTO = 'Silakan unggah foto kamu memegang ID';
export const EXPLAIN_1 = `1.Hadiah yang didapatkan bisa diambil`;
export const EXPLAIN_2 = `2.Ikut serta dalam Top Novel dan dapat memenangkan hadiah dan bonus`;
export const EXPLAIN_3 = `3.Akan bantu diiklankan`;
export const EXPLAIN_4 = `4.Mendapatkan prioritas layanan pengguna`;
export const SAMPLE = 'Contoh Foto';
export const ALREADY_PASS = 'Sudah lolos';
export const CORRELATION_EQUITY = 'Hak lainnya';
export const IS_ADULT = 'Apakah >17 tahun';
export const UPLOAD_MYSELF_PHOTO_STUDENT = 'Silakan unggah kartu pelajar kamu';
export const UPLOAD_MYSELF_ON_HAND_PHOTO_STUDENT = 'Silakan unggah kamu memegang kartu pelajar';
export const ADULT = 'Sudah >17 tahun';
export const CHILD = 'Dibawah 17 tahun';

// 收入明细
export const INCOME_DETAIL = 'Penerimaan Penulis';
export const BONUS_SETTLEMENT = 'Musiman';
export const DASHAN_NOTICE = 'Hadiah dari user merupakan tambahan pemasukan untuk penulis selain dari bonus bulanan';
export const BONUS_NOTICE = 'Sejak 1 April 2019 NovelMe mulai menghitung ranking penulis dan memberikan hadiah bagi yang berhak.';
export const GET_BONUS = 'Bonus bulan ini';
export const GET_TOTAL_BONUS = 'Bonus total';
export const GET_BONUS_NOW = 'Saldo yang bisa ditarik';
export const GIVE_REWARD = 'Hadiah';
export const MONTH_DETAIL_INCOME = 'Detail pendapatan bulanan';
export const SELECT_MONTH = 'Waktu';
export const SELECT_CATEGORY = 'Jenis hadiah';
export const SELECT_CORPUS_INCOME = 'Pilih karya';
export const FEE = 'Saldo';
export const INCOME_SEC_EXPLAIN = ' bonus lainnya';
export const INCOME_EXPLAIN = 'Bonus penulis yang akan dikirim termasuk bonus tiap musim, pembagian bonus hadiah dan.';
export const INCOME_DETAIL_EXPLAIN = 'Bagi para penulis bisa mendapatkan penghasilan melalui kontes, keanggotaan pembaca, dll.';
export const RESET = 'Setel ulang';
export const LAST_MONTH_RANK = 'Ranking bulan lalu';
export const EFFORT_DEVELOP = 'Sedang proses pembuatan';
export const VIEW = 'Lihat';

// 提现
export const SETTLE_RULE = 'cara penarikan';
export const CAN_CARRY_BALANCE_CAN = 'Saldo yang bisa ditarik';
export const VIEW_DETAIL = 'Daftar penarikan';
export const SHROFF_ACCOUNT = 'Rekening bank';
export const BANK_ACCOUNT = 'Nomer Rekening';
export const BANK = 'Nama Bank';
export const INPUT_AGAIN = 'Masukkan lagi';
export const WITHDRAW_TIP = 'Jumlah di bawah ini yang dapat ditarik penulis';
export const WITHDRAW_RULE = 'Aturan penarikan';
export const SETTLE_PRICE = 'Jumlah penarikan';
export const FIFTY_DAY = 'Sampai ke rekening dalam 15 hari kerja';
export const BIND = 'Sambungkan';
export const BIND_OFF = 'Ganti nomer';

// 开通付费
export const PAID_OK = 'Kami sudah menerima permohonan, kami akan segera menghubungi kamu.';

// 实名认证
// export const IDENTITY_INFO = '身份信息';
// export const SUBMIT_INFO = '信息提交';
// export const AUTHENTICATION = '认证中';
// export const EXPLAIN_1 = `1.作品获得的打赏可提现`;
// export const EXPLAIN_2 = `2.有资格入选星榜，赢得高额奖金，可提现打赏分成`;
// export const EXPLAIN_3 = `3.原创作品更多流量入口，可以曝光给更多读者`;
// export const EXPLAIN_4 = `4.拥有专属的客服通道`;
// export const NAME = '名';
// export const SURNAME = '姓';
// export const VALID_NUMBER = '有效身份证件号码';
// export const UPLOAD_MYSELF_PHOTO = '请上传本人身份证件照';
// export const UPLOAD_MYSELF_ON_HAND_PHOTO = '请上传本人手持身份证件照';
// export const SAMPLE = '示例';
// export const ALREADY_PASS = '已通过';
// export const CORRELATION_EQUITY = '相关权益';
// export const IS_ADULT = '是否成年';
// export const UPLOAD_MYSELF_PHOTO_STUDENT = '请上传本人学生证件照';
// export const UPLOAD_MYSELF_ON_HAND_PHOTO_STUDENT = '请上传本人手持学生证件照';
// export const ADULT = '已成年';
// export const CHILD = '未成年';

// 收入明细
// export const INCOME_DETAIL = '作家收入结算';
// export const BONUS_SETTLEMENT = '奖金';
// export const DASHAN_NOTICE = '作者通过读者打赏而获得的收入，是NovelMe在作品销售之外，为作者新增的收入方式。';
// export const BONUS_NOTICE = 'NovelMe在2019 年 4 月 1 日开始统计作者大赛创作情况，并向符合奖励标准的作者发放写作奖金。';
// export const LAST_MONTH_RANK = '上月排名';
// export const GET_BONUS = '获得奖金';
// export const GET_TOTAL_BONUS = '累计获得奖金';
// export const GET_BONUS_NOW = '当前可获得奖金';
// export const GIVE_REWARD = '打赏分成';
// export const EFFORT_DEVELOP = '正在努力开发中';
// export const MONTH_DETAIL_INCOME = '月收入明细表';
// export const SELECT_MONTH = '时间';
// export const SELECT_CATEGORY = '奖金类别';
// export const SELECT_CORPUS_INCOME = '选择文集';
// export const VIEW = '查看';
// export const RESET = '重置';
// export const FEE = '金额';
// export const INCOME_SEC_EXPLAIN = '详情，如收入结算疑问，请联系';
// export const INCOME_EXPLAIN = '作者每月应发收入包括赛季奖金、打赏等分成、补贴或奖励';
// export const INCOME_DETAIL_EXPLAIN = '作者在NovelMe写作可以通过大赛投稿、读者送日票、补贴等多种方式获得收入';

// 提现翻译
// export const SETTLE_RULE = '结算规则';
// export const CAN_CARRY_BALANCE_CAN = '当前可提现余额';
// export const VIEW_DETAIL = '查看明细';
// export const SHROFF_ACCOUNT = '收款账号';
// export const BANK_ACCOUNT = '银行卡号';
// export const BANK = '银行';
// export const INPUT_AGAIN = '再次输入';
// export const WITHDRAW_TIP = '作者写作的收入金额显示，支持提现';
// export const WITHDRAW_RULE = 'NovelMe提现规则';
// export const SETTLE_PRICE = '提现金额';
// export const FIFTY_DAY = '预计15个工作日内到账';
// export const BIND_OFF = '修改';
// export const BIND = '绑定';
//
// export const VERIFY_FAIL = '认证失败';
// export const VERIFIED = '认证中';
// export const VERIFY = '已认证';
// export const UNVERIFIED = '未认证';
// export const SET_PEN_NAME = '给自己起一个响亮的笔名吧';
// export const ONLY_UPLOAD = '上传';
// export const FORMAT_ERROR = '上传的文件格式不正确，请重新上传';
// export const SIZE_ERROR = '每个文件不能超过1M';
// export const RESTORE_CHAPTER = '章节已恢复至文集列表中';
// export const DELEDE_SUCCESS = '删除成功，回收站内可找回';
// export const RELEASE_SUCCESS = '发布成功';
// export const NOVEL_DETAIL = '文集详情';
// export const MODIFY_CHAPTER = '修改章节';
// export const HOME_PAGE = '主页';
// export const WORK_MANAGER = '作品管理';
// export const CORPUS_MANAGER = '文集管理';
// export const UPLOAD_SECTION = '上传章节';
// export const CREATION = '创作';
// export const WRITER_SETTLEMENT = '收入结算';
// export const WITHDRAW = '提现';
// export const HELP = '帮助';
// export const PERSONAL_DATA = '个人资料';
// export const CERTIFICATION = '实名认证';
// export const EXIT_SUCCESS = '退出成功';
// export const CREATE_CORPUS = '创建文集';
// export const NOVEL_NUMBER = '小说数量';
// export const DRAFT_NUMBER = '文章数量';
// export const REVIEW_DRAFT_NUMBER = '被审核文章数量';
// export const NEW_SUBSCRIPTIONS_YESTERDAY = '昨日新增订阅';
// export const NEW_READING_YESTERDAY = '昨日新增订阅';
// export const TOTAL_SUBSCRIPTIONS = '总订阅';
// export const TOTAL_READING = '总阅读';
// export const ANNOUNCEMENT = '公告';
// export const JOIN_US = '加入我们';
// export const ABOUT_US = '关于我们';
// export const CONTACT_US = '联系我们';
// export const PRIVACY_POLICY = '服务协议';
// export const SERVICE_AGREEMENT = '隐私政策';
// export const ATTENTION = '身份信息：根据相关法律规定，企业向个人付款，应代交个人所得税。申报时需提供收入所得方的真实身份信息，请您放心，您的个人信息不会被泄露。';
// export const CREATE_NEW_BOOK = '创建一本新书';
// export const SELECT_CORPUS = '请选择文集';
// export const INPUT_SECTION_NAME = '输入章节的名称';
// export const INPUT_CONTENT = '输入内容......';
// export const SUBMIT = '提交';
// export const SAVE = '保存';
// export const WORD_NUMBER = '字数';
// export const SURE = '确定';
// export const AUTHOR_INFO = '作者信息';
// export const AUTHOR_NAME = '作者署名';
// export const INPUT_AUTHOR_NAME = '请输入作者署名';
// export const INPUT_AUTHOR_PHONE = '请输入手机号';
// export const AUTHOR_NOTICE = '作者署名一旦提交，后续修改需要申请，请谨慎填写';
// export const CONTACT_WAY = '联系方式';
// export const PHONE = '手机号';
// export const VERIFICATION_CODE = '验证码';
// export const E_MAIL = 'E-mail';
// export const FACEBOOK_ACCOUNT = 'FaceBook账号';
// export const INS_ACCOUNT = 'Ins账号';
// export const SAVE_CHANGE = '保存修改';
// export const EDIT = '编辑';
// export const CANCEL_CHANGE = '取消修改';
// export const CLICK_GET = '点击获取';
// export const REGAIN = '重新获取';
// export const ADVICE_FILL_IN = '建议填写';
// export const LEAVE_PAGE = '要离开此网站吗？系统可能不会保存您所作的更改';
// export const THINK_AGAIN = '再想想';
// export const LEAVE = '离开';
// export const ALL = '全部';
// export const NEW = '新建';
// export const HAS_ON = '已上架';
// export const REMOVE_ON = '已下架';
// export const UNDER_REVIEW = '审核中';
// export const UNDER_FAILED = '审核失败';
// export const NOT_BOOK = '没有书呀，快去创建一本吧!';
// export const THE_CURRENT_COLLECTION = '当前文集数';
// export const THIS = '本';
// export const SECTION_NUMBER = '章节数量:';
// export const READ = '阅读:';
// export const SUBSCRIBER = '订阅:';
// export const UPDATE_TIME = '更新时间:';
// export const NEW_SECTION = '最新章节:';
// export const WRITE_NEW_CHAPTER = '写新章节';
// export const VIEW_SECTION_DETAIL = '查看详情';
// export const SECTION_ERROR = '您发布的文集或文章出现问题：请重新发布或联系平台';
// export const DELETE_SURE = '确定删除该文集？删除后不可恢复！';
// export const CALL_OF = '取消';
// export const SECTION_MANAGER = '文集管理';
// export const CLICK_UPLOAD = '点击上传';
// export const RETURN_UPLOAD = '重新上传';
// export const SECTION_INFO = '文集信息';
// export const SECTION_COVER = '文集封面';
// export const PLEASE_UPLOAD_IMG = '请上传图片';
// export const SUCCESS_IMG = '效果图';
// export const IMG_ERROR = '图片格式不符合要求，请重新上传';
// export const MORE_THAN_WORD = '文集简介为20-1000字符';
// export const CREATE_SECTION_TEXT = '创建文集后，文集名称和文集类型暂时不支持修改。请仔细确认后，是否创建该文集';
// export const CREATE = '创建';
// export const THINKING = '再想想';
// export const NONE = '暂无';
// export const NO_NETWORK = '服务器请求失败  请稍后重试';
// export const DELETE_SUCCESS = '删除成功';
// export const ATTENTION_PLEASE = '请注意：根据国家相关法律法规要求，切勿上传任何色情、低俗、涉政等违法违规内容。一旦出现，我们将会根据法规进行审核处理。';
// export const BRIEF_PLEASE = '请填写文集简介';
// export const NAME_PLEASE = '请填写文集名称';
// export const TYPE_PLEASE = '请填写文集类型';
// export const PLEASE_WAIT = '正在创建，请稍候';
// export const CREATE_SUCCESS = '文集创建成功';
// export const CREATE_FAILED = '文集创建失败';
// export const PLEASE_WAIT_MODIFY = '正在修改文集，请稍候';
// export const PLEASE_WAIT_MODIFY_SUCCESS = '修改文集成功';
// export const NAME_NOT_INCLUDE_CHAR = '文集名称不能包含特殊字符';
// export const NAME_NOT_MORE_THAN_HUNDRED = '文集名称最多100字符';
// export const PLEASE_INPUT_SECTION_NAME = '请填写书名！';
// export const BRIEF_NOT_INCLUDE_CHAR = '文集简介不能包含特殊字符';
// export const NAME_NOT_MORE_THAN_20 = '文集简介20 - 300字符';
// export const PLEASE_INPUT_SECTION_BRIEF = '请填写简介!';
// export const PLEASE_INPUT_SECTION_TYPE = '请选择类型!';
// export const CHAPTER_MANAGER = '文章管理';
// export const RECYCLE_BIN = '回收站';
// export const UNPUBLISHED = '未发布';
// export const PUBLISHED = '已发布';
// export const TIME = '时间';
// export const DELETE = '删除';
// export const CHAPTER = '章节';
// export const VIEW_CHAPTER = '查看';
// export const CATEGORY = '分类';
// export const MODIFY_SECTION = '修改作品设置';
// export const AUTO_DELETE = '天章节自动清除';
// export const OVER_THAN = '超过';
// export const SURE_DELETE = '你确定要删除这一章吗？';
// export const SURE_SUBMIT = '提交的章节无法更改，请确认提交？';
// export const RESTORE = '还原';
// export const RECOVER_CHAPTER = '你想恢复这一章吗？';
// export const AUTO_SAVE = '自动保存章节...';
// export const CHANPTER_NAME = '章节名称最大100个字母';
// export const ACTIVITY_WORD = '征文活动';
// export const VERIFIED_NAME = '你的实名认证未审核通过，请修改后重新提交';
// export const ALLOW = '同意';
// export const DRAFT = '草稿';
// export const REVIEW_PASS = '审核通过';
// export const ORIGIN = '原创:';
// export const RANKING = '排名:';
// export const STAR_TICKET = '星票:';
// export const TAKE_MATCH = '参赛';
// export const GO_VER = '去认证:';
