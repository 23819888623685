import indexKey from '../action/keys/indexKeys';

export function initPage() {
    return { type: indexKey.INIT_PAGE };
}

export function getPersonalMsg(param: any) {
    return {type: indexKey.GET_PERSONAL_DATE, payload: param };
}

export function getNoticeData(list: any[]) {
    return { type: indexKey.GET_NOTICE_LIST, payload: list };
}

export function getNoticePageData(list: any) {
    return { type: indexKey.GET_NOTICE_PAGE_LIST, payload: list };
}

export function noticePage() {
    return { type: indexKey.NOTICE_LIST_PAGE };
}

export function getNoticeId(id: string) {
    return { type: indexKey.GET_NOTICE_ID, payload: id };
}

export function getNoticeDetail(param: any) {
    return { type: indexKey.GET_NOTICE_DETAIL, payload: param };
}

export function getUpdatePage(page: any, pageSize: any) {
    return { type: indexKey.UPDATE_PAGE, payload: { page, pageSize} };
}

export function openPromptModal(value: boolean) {
    return { type: indexKey.OPEN_PROMPT_MODAL, payload: value };
}

export function getBannerDataList(value: any[]) {
    return { type: indexKey.GET_BANNER_DATA_LIST, payload: value };
}

export function getUserNotificationList(value: any) {
    return { type: indexKey.GET_USER_NOTIFICATION_LIST, payload: value };
}

export function markAsRead(value: string|number) {
    return { type: indexKey.MARK_AS_READ, payload: value };
}

export function userClickBanner(value: string|number) {
    return { type: indexKey.USER_CLICK_BANNER, payload: value };
}

export function openPenNameModal(bol: boolean) {
    return { type: indexKey.OPEN_PEN_NAME_MODAL, payload: bol };
}

export function openFailAuthModal(bol: boolean) {
    return { type: indexKey.OPEN_FAIL_AUTH_MODAL, payload: bol };
}

export function openAggrementModal(bol: boolean) {
    return { type: indexKey.OPEN_AGGREMENT_MODAL, payload: bol };
}

export function openAuthorRegisterModal(bol: boolean) {
    return { type: indexKey.OPEN_AUTHOR_REGISTER_MODAL, payload: bol };
}