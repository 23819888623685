enum IndexKeys {
    INIT_PAGE = 'INIT_PAGE',
    GET_PERSONAL_DATE = 'GET_PERSONAL_DATE',
    GET_NOTICE_LIST = 'GET_NOTICE_LIST',
    NOTICE_LIST_PAGE = 'NOTICE_LIST_PAGE',
    GET_NOTICE_ID = 'GET_NOTICE_ID',
    GET_NOTICE_DETAIL = 'GET_NOTICE_DETAIL',
    UPDATE_PAGE = 'NOTICE_LIST_UPDATE_PAGE',
    OPEN_PROMPT_MODAL = 'INDEX_OPEN_PROMPT_MODAL',
    GET_BANNER_DATA_LIST = 'INDEX_PAGE_GET_BANNER_DATA_LIST',
    GET_USER_NOTIFICATION_LIST = 'INDEX_PAGE_GET_USER_NOTIFICATION_LIST',
    MARK_AS_READ = 'INDEX_PAGE_MARK_AS_READ',
    USER_CLICK_BANNER = 'INDEX_PAGE_USER_CLICK_BANNER',
    OPEN_PEN_NAME_MODAL = 'INDEX_PAGE_OPEN_PEN_NAME_MODAL',
    OPEN_FAIL_AUTH_MODAL = 'INDEX_PAGE_OPEN_FAIL_AUTH_MODAL',
    OPEN_AGGREMENT_MODAL = 'INDEX_PAGE_OPEN_AGGREMENT_MODAL',
    OPEN_AUTHOR_REGISTER_MODAL = 'INDEX_PAGE_OPEN_AUTHOR_REGISTER_MODAL',
    GET_NOTICE_PAGE_LIST = 'INDEX_PAGE_GET_NOTICE_PAGE_LIST',
}

export default IndexKeys;
