import userInfoKeys from './keys/userInfoKeys';

export function initPage() {
    return { type: userInfoKeys.INIT_PAGE };
}

export function getContactInfo(param: any) {
    return { type: userInfoKeys.GET_CONTACT_INFO, payload: param };
}

export function getPersonData(param: any) {
    return { type: userInfoKeys.GET_PERSON_DATA, payload: param };
}

export function becomeAuthor(param: string) {
    return { type: userInfoKeys.BECOME_AUHTOR, payload: param };
}

export function modifyPenName(param: string) {
    return { type: userInfoKeys.MODIFY_PEN_NAME, payload: param };
}

export function sendPhoneNumber(param: string) {
    return { type: userInfoKeys.SEND_PHONE_NUMBER, payload: param };
}

export function saveUserInfo(param: any) {
    return { type: userInfoKeys.SAVE_USER_INFO, payload: param };
}

export function becomeAuthorError(bol: boolean, value?: string) {
    return { type: userInfoKeys.BECOME_AUTHOR_ERROR, payload: {bol, value} };
}

export function modifyPenNameError(bol: boolean, value?: string) {
    return { type: userInfoKeys.MODIFY_PEN_NAME_ERROR, payload: {bol, value} };
}

export function modifyContactInfoError(bol: boolean, value?: string) {
    return { type: userInfoKeys.MODIFY_CONTACT_INFO_ERROR, payload: {bol, value} };
}

export function verificationCodeError(bol: boolean, value?: string) {
    return { type: userInfoKeys.VERIFICATION_CODE_ERROR, payload: {bol, value} };
}

export function emailError(bol: boolean, value?: string) {
    return { type: userInfoKeys.EMAIL_ERROR, payload: {bol, value} };
}

export function phoneError(bol: boolean, value?: string) {
    return { type: userInfoKeys.PHONE_ERROR, payload: {bol, value} };
}

export function prompt(param: {bol: boolean, value?: string}) {
    return { type: userInfoKeys.PROMPT, payload: param };
}

export function becomeAuthorButton(param: boolean) {
    return { type: userInfoKeys.BECOME_AUTHOR_BUTTON, payload: param };
}

export function penNameButton(param: boolean) {
    return { type: userInfoKeys.SAVE_PEN_NAME_BUTTON, payload: param };
}

export function userInfoButton(param: boolean) {
    return { type: userInfoKeys.USER_INFO_BUTTON, payload: param };
}

export function checkPhone(param: string) {
    return { type: userInfoKeys.CHECK_PHONE, payload: param };
}
