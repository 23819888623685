enum CertificationKeys {
    INIT_PAGE = 'CERTIFICATION_INIT_PAGE',
    GET_CERTIFICATION_INFO = 'CERTIFICATION_GET_CERTIFICATION_INFO',
    MODIFY_CERTIFICATION_INFO  = 'CERTIFICATION_MODIFY_CERTIFICATION_INFO',
    MODIFY_CERTIFICATION_INFO_ERROR = 'CERTIFICATION_MODIFY_CERTIFICATION_INFO_ERROR',
    NOTIFY_MODAL_VISIBLE = 'CERTIFICATION_MODIFY_PEN_NAME_ERROR',
    PROMPT = 'CERTIFICATION_PROMPT',
    SAVE_BUTTON = 'CERTIFICATION_SAVE_BUTTON',
    PHONE_ERROR = 'CERTIFICATION_PHONE_ERROR',
    VERIFICATION_CODE_ERROR = 'CERTIFICATION_VERIFICATION_CODE_ERROR',
    EMAIL_ERROR = 'CERTIFICATION_EMAIL_ERROR',
    SEND_PHONE_NUMBER = 'CERTIFICATION_SEND_PHONE_NUMBER',
    CHECK_PHONE = 'CERTIFICATION_CHECK_PHONE'
}

export default CertificationKeys;
