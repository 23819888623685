import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as myUuid from 'uuid';
import FreezeModal from '../layout/FreezeModal';

interface CommonError {
    code: string | number ;
    message: string | number;
}

const API = process.env.NODE_ENV === 'development' ? '/' : '/';

class CommonError {
    constructor(code: number, message: string) {
        this.code = code;
        this.message = message;
    }
}

function getBaseURL(): string {
    return window.location.protocol + '//' + window.location.host + API;
}

function interceptor(json: any): any {
    if (json.code !== 0) {
        let err = new CommonError(json.code, json.message);
        throw err;
    }
    return json.result;
}

export function postJson(api: string, param: any = {}, pathValues: any = null, https: boolean = false) {
    const instance = getDefaultInstance();

    api = applyPathValues(api, pathValues);
    return instance.post(api, {reqId: myUuid(), timestamp: new Date().getTime(), param});
}

export function postLoginJson(api: string, param: any = {}, type: string, pathValues: any = null, https: boolean = false) {
    const instance = getDefaultInstance(true, type);
    api = applyPathValues(api, pathValues);
    return instance.post(api, {reqId: myUuid(), timestamp: new Date().getTime(), param});
}

export function postForm(api: string, formData: any) {
    const instance = getDefaultInstance();
    return instance.post(api, formData);
}

function getDefaultInstance(bol?: boolean, type?: string) {

    if (type) {
        localStorage.setItem('type', type);
    }

    if (bol !== undefined) {
        const instance = axios.create({
            baseURL: getBaseURL(),
            withCredentials: true,
            timeout: 1000 * 60 * 10, // 指定请求超时的毫秒数
            headers: {'X-Social-Type': type}
        });

        instance.interceptors.request.use((config: AxiosRequestConfig) => {
            if (navigator.onLine === false) {
                window.open('/#/noNetwork');
            }
            return config;
        });

        instance.interceptors.response.use(
        (res: any) => {
            // 如果Token从response中返回，可以从这里取Token
            if (bol) {
                window.localStorage.setItem('TOKEN', res.headers['X-Auth-Token']);
            }
            return interceptor(res.data);

        },
        (err: any) => {
            if (err.response.status === 401) {
                window.location.href = '/#/login';
                clearAuthToken();
            }
            if (err.response.status === 423) {
                FreezeModal.modal();
            }
            // else if (err.response.status === 500) {
            //     console.error('error');
            //     window.location.href = '/#/noNetwork';
            // }
            // else if (err.message === 'Network Error') {
            //
            // }
            // window.location.href = '/#/noNetwork';
            return Promise.reject(new Error(err.response.data));
        });

        return instance;

    } else {
        let instance = axios.create({
            baseURL: getBaseURL(),
            withCredentials: true,
            timeout: 1000 * 60 * 10, // 指定请求超时的毫秒数
            headers: {'X-Social-Type': localStorage.getItem('type')}
        });

        instance.interceptors.request.use((config: AxiosRequestConfig) => {
            const token = getAuthToken();
            if (token != null) {
                config.headers['X-Auth-Token'] = token;
            }
            return config;
        });

        instance.interceptors.response.use(
        (res: any) => {
            return interceptor(res.data);
        },
        (err: any) => {

            if (err.message === 'Network Error') {
                window.open('/#/noNetwork');
            }
            if (err.response.status === 401) {
                clearAuthToken();
                window.location.href = '/#/login';
            }
            if (err.response.status === 423) {
                FreezeModal.modal();
            }
            // else if (err.response.status === 500) {
            //     console.error('error');
                // window.location.href = '/#/noNetwork';
                // window.open('/#/noNetwork');
            // }
            return Promise.reject(new Error(err.response.data));
        });

        return instance;
    }
}

export function applyPathValues(api: any, pathValues: any) {
    if (pathValues) {
        const rex = /{\w+}/g;

        let matched = api.match(rex);
        for (let match of matched) {
            let pathName = match.substr(1, match.length - 2);
            if (pathValues[pathName]) {
                api = api.replace(match, '' + pathValues[pathName]);
            }
        }
    }

    return api;
}

function getAuthToken(): string | any {
    const token = window.localStorage.getItem('TOKEN');
    return token;
}

export function clearAuthToken(): void {
    window.localStorage.removeItem('TOKEN');
}

// 遗留文件 不用删除
export function postJsonForExport(api: string, data: any, filename: string) {
    const instance = axios.create({
        baseURL: getBaseURL(),
        withCredentials: true,
        responseType: 'blob',
    });

    instance.interceptors.request.use((config: AxiosRequestConfig) => {
        const token = window.localStorage.getItem('TOKEN');
        if (token != null) {
            config.headers['X-Auth-Token'] = token;
        }
        return config;
    });

    instance.interceptors.response.use(
        (res: AxiosResponse) => {
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(res.data);
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
            return res;
        },
        (err: { response: AxiosResponse }) => {
            return Promise.reject(new Error(JSON.stringify(err.response.data)));
        }
    );

    api = applyPathValues(api, null);

    return instance.post(api, {
        reqId: Math.random().toString(36).substr(2),
        timestamp: new Date().getTime(),
        param: {...data}
    });
}
