import { postJson } from '../utils/xhr';

/**
 * 获取联系方式
 */
export function getContactInfo() {
    return postJson('/api/personal/getContactInfo');
}

/**
 * 修改联系方式
 */
export function modifyContactInfo(param: any) {
    return postJson('/api/personal/modifyContactInfo', param);
}

/**
 * 成为作者
 */
export function becomeAuthor(param: any) {
    return postJson('/api/personal/becomeAuthor', param);
}

/**
 * 修改笔名
 */
export function modifyPenName(param: string) {
    return postJson('/api/personal/modifyPenName', param);
}

/**
 * 请求手机验证码
 */
export function requestVerificationCode(phoneNumber: string) {
    return postJson('/api/personal/requestVerificationCode', phoneNumber);
}

/**
 * 获取认证信息
 */
export function getAuthenticationInfo() {
    return postJson('/api/authentication/getAuthenticationInfo');
}

/**
 * 提交实名认证
 */
export function authenticationSubmit(param: any) {
    return postJson('/api/authentication/submit', param);
}

/**
 * 检查手机号码是否可用
 */
export function checkPhoneAvailable(param: any) {
    return postJson('/api/personal/checkPhoneAvailable', param);
}
