import articleKeys from '../action/keys/articleKeys';
import { ActionType } from '../action/type/articleType';

interface State {
    chapterName: string;
    chapterDraftName: string;
    chapterCon: string;
    chapterDraftCon: string;
    chapterMsg: any;
    toList: boolean;
    promptModal: boolean;
    promptText: string;
    autoSaveTextShow: boolean;
    sensitiveWord: any[];
}

const initalState: State = {
    chapterName: '',
    chapterDraftName: '',
    chapterCon: '',
    chapterDraftCon: '',
    chapterMsg: '',
    toList: false,
    promptModal: false,
    promptText: '',
    sensitiveWord: [],
    autoSaveTextShow: false
};

export const articleReducer = (state = initalState, action: ActionType) => {
    switch (action.type) {

        case articleKeys.INIT_PAGE:
            return initalState;

        case articleKeys.CHAPTER_NAME:
            return Object.assign({}, state, {
                chapterName: action.payload
            });

        case articleKeys.CHAPTER_CON:
            return Object.assign({}, state, {
               chapterCon: action.payload
            });

        case articleKeys.GET_SENSITIVEWORD_LIST:
            return Object.assign({}, state, {
                sensitiveWord: action.payload
            });

        case articleKeys.GET_CHAPTER_MSG:
            return Object.assign({}, state, {
                chapterMsg: action.payload,
                chapterName: action.payload.name,
                chapterDraftName: action.payload.draftName || action.payload.name,
                chapterDraftCon: action.payload.draftContent,
                chapterCon: action.payload.content
            });

        case articleKeys.TO_LISt:
            return Object.assign({}, state, {
                toList: action.payload
            });

        case articleKeys.OPEN_PROMPT_MODAL:
            return Object.assign({}, state, {
                promptModal: action.payload
            });

        case articleKeys.GET_PROMPT_TEXT:
            return Object.assign({}, state, {
                promptText: action.payload
            });

        case articleKeys.AUTO_SAVE_TEXT_SHOW:
            return Object.assign({}, state, {
                autoSaveTextShow: action.payload
            });

        case articleKeys.GET_DRAFT_CONTENT:
            return Object.assign({}, state, {
                chapterDraftCon: action.payload
            });

        case articleKeys.GET_DRAFT_NAME:
            return Object.assign({}, state, {
                chapterDraftName: action.payload
            });

        default:
            return state;
    }
};
