import { fork, all, takeEvery, put, take, call } from 'redux-saga/effects';
import CertificationKeys from '../../component/user/action/keys/certificationKeys';
import * as certificationAction from '../../component/user/action/certificationAction';
import * as userInfoServer from '../../server/userInfoServer';
import * as workServer from '../../server/workServer';
import { errorTip } from './common';
import * as Mean from '../../i18n';

function* initPage() {
    yield getAuthenticationInfo();
}

function* getAuthenticationInfo() {
    try {
        const result = yield call(userInfoServer.getAuthenticationInfo);
        yield put(certificationAction.getCertificationInfo(result));
    } catch (err) {
        console.log(err);
    }
}

function* watchRequestVerificationCode() {
    while (true) {
        try {
            const { payload } = yield take(CertificationKeys.SEND_PHONE_NUMBER);
            yield call(userInfoServer.requestVerificationCode, payload);
        } catch (err) {
            if (err.code === 304) {
                yield put(certificationAction.verificationCodeError(true, 'Format no hp salah'));
            } else if (err.code === 305) {
                yield put(certificationAction.verificationCodeError(true, 'Terlalu banyak permintaan OTP'));
            } else if (err.code === 306) {
                yield put(certificationAction.verificationCodeError(true, 'Limit kode hari ini, coba lagi besok'));
            } else if (err.code === 307) {
                yield put(certificationAction.verificationCodeError(true, 'Kode OTP gagal dikirim'));
            } else {
                yield errorTip(Mean.NO_NETWORK, certificationAction.prompt);
            }
        }
    }
}

function* watchCheckPhone() {
    while (true) {
        try {
            const { payload } = yield take(CertificationKeys.CHECK_PHONE);
            yield call(userInfoServer.checkPhoneAvailable, payload);
        } catch (err) {
            if (err.code === 304) {
                yield put(certificationAction.verificationCodeError(true, 'Format no hp salah'));
            } else if (err.code === 309) {
                yield put(certificationAction.phoneError(true, 'No. HP sudah pernah dipakai'));
            } else {
                yield errorTip(Mean.NO_NETWORK, certificationAction.prompt);
            }
        }
    }
}

function* watchModifyContactInfo() {
    while (true) {
        try {
            const { payload } = yield take(CertificationKeys.MODIFY_CERTIFICATION_INFO);
            yield put(certificationAction.saveButton(true));
            if ( payload.cardPic[0].originFileObj) {
                let result = yield call(workServer.uploadImgToServer, payload.cardPic[0].originFileObj, '1');
                payload.cardPic = result[0];
            } else {
                delete payload.cardPic;
            }
            if (payload.cardHandPic[0].originFileObj) {
                let result = yield call(workServer.uploadImgToServer, payload.cardHandPic[0].originFileObj, '1');
                payload.cardHandPic = result[0];
            } else {
                delete payload.cardHandPic;
            }
            yield call(userInfoServer.authenticationSubmit, payload);
            yield getAuthenticationInfo();
            yield put(certificationAction.notifyModalVisible(true));
            yield put(certificationAction.saveButton(false));
        } catch (err) {
            yield put(certificationAction.saveButton(false));
            console.log(err);
            if (err.code === 302) {
                yield errorTip(err.message, certificationAction.prompt);
            } else if (err.code === 304) {
                yield put(certificationAction.phoneError(true, 'Format no hp salah'));
            } else if (err.code === 308) {
                yield put(certificationAction.phoneError(true, 'Kode OTP tidak cocok'));
            } else if (err.code === 309) {
                yield put(certificationAction.phoneError(true, 'Nomer ponsel sudah digunakan'));
            } else if (err.code === 310) {
                yield put(certificationAction.emailError(true, 'Format email salah'));
            } else if  (err.code === 315) {
                yield put(certificationAction.modifyCertificationError(true, 'Tidak bisa mengubah informasi verifikasi nama asli'));
            } else {
                yield errorTip(Mean.NO_NETWORK, certificationAction.prompt);
            }
        }
    }
}

function* watchInitPage() {
    yield takeEvery(CertificationKeys.INIT_PAGE, initPage);
}

export function* watchCertificationSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchModifyContactInfo),
        fork(watchRequestVerificationCode),
        fork(watchCheckPhone),
    ]);
}
