enum workListKey {
    INIT_PAGE = 'WORK_LIST_INIT_PAGE',
    GET_CORPUS_LIST = 'WORK_LIST_GET_CORPUS_LIST',
    GET_CORPUS_DETAIL = 'WORK_LIST_GET_CORPUS_DETAIL',
    GET_CORPUS_DETAIL_BY_ID = 'WORK_LIST_GET_CORPUS_DETAIL_BY_ID',
    GET_CORPUS_STATUS = 'WORK_LIST_GET_CORPUS_STATUS',
    CHANGE_PAGE_SIZE = 'WORK_LIST_CHANGE_PAGE_SIZE',
    DEL_CORPUS = 'WORK_LIST_DELETE_CORPUS',
    VISIBLE_PROMPT = 'WORK_LIST_VISIBLE_PROMPT',
    LOADING = 'WORK_LIST_LOADING',
    NO_DATA = 'WORK_LIST_NO_DATA',
    IN_COMPETITION = 'WORK_LIST_IN_COMPETITION',
    CHANGE_PAGE_SIZE_AND_STATUS = 'WORK_LIST_CHANGE_PAGE_SIZE_AND_STATUS',
    GET_PAID_NOVEL = 'WORK_LIST_GET_PAID_NOVEL',
    PAID_NOVEL_MODAL = 'WORK_LIST_PAID_NOVEL_MODAL'
}

export default workListKey;
