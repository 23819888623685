enum createCorpusKeys {
    // 初始化界面and获取数据
    INIT_PAGE = 'CREATE_CORPUS_INIT_PAGE',
    GET_NOVEL_TYPE_LIST = 'CREATE_GET_NOVEL_TYPE_LIST',
    GET_NOVEL_DETAIL_BY_ID = 'CREATE_GET_NOVEL_DETAIL_BY_ID',
    NOVEL_DETAIL_LIST = 'CREATE_NOVEL_DETAIL_LIST',
    // crud操作
    ADD_NOVEL = 'CREATE_ADD_NOVEL',
    EDIT_NOVEL = 'CREATE_EDIT_NOVEL',
    TO_CORPUS_LIST = 'CREATE_TO_CORPUS_LIST',
    VISIBLE_PROMPT = 'CREATE_VISIBLE_PROMPT',
    OPEN_CREATE_SURE_MODAL =  'CREATE_OPEN_CREATE_SURE_MODAL',
    // 获取封面图片 简介 更新状态
    GET_NOVEL_COVER = 'CREATE_GET_NOVEL_COVER',
    GET_NOVEL_BRIEF = 'CREATE_GET_NOVEL_BRIEF',
    // 错误提示
    IMG_ERROR = 'CREATE_IMG_ERROR',
    NAME_ERROR = 'CREATE_NAME_ERROR',
    INTRO_ERROR = 'CREATE_INTRO_ERROR',
    SELECT_ERROR = 'CREATE_SELECT_ERROR',
    // 成功提示 - 控制显示跳转新建章节按钮
    OPEN_CREATE_CHAPTER_MODAL = 'CREATE_CORPUS_OPEN_CREATE_CHAPTER_MODAL',
    GET_NOVEL_ID = 'CREATE_CORPUS_GET_NOVEL_ID',
    CANCEL_BUTTON = 'CREATE_CORPUS_CANCEL_BUTTON'
}

export default createCorpusKeys;
