import editKeys from '../action/keys/editKeys';
import { ActionType } from '../action/type/editType';

interface EditReducerState {
    allNovelInfoList: any[];
    sensitiveWord: any[];
    saveChapterId: number;
    toChapterList: boolean;
    hasReleaseNovelId: number;
    novelId: string;
    promptModal: boolean;
    promptText: string;
    saveTextShow: boolean;
}

const initialState: EditReducerState = {
    allNovelInfoList: [],
    sensitiveWord: [],
    saveChapterId: 0,
    toChapterList: false,
    hasReleaseNovelId: 0,
    novelId: '',
    promptModal: false,
    promptText: '',
    saveTextShow: false,
};

export const editReducer = (state = initialState, action: ActionType ) =>  {
    switch (action.type) {

        case editKeys.INIT_PAGE:
            return initialState;

        case editKeys.GET_ALL_NOVEL_INFO_LIST:
            return Object.assign({}, state, {
                allNovelInfoList: action.payload
            });

        case editKeys.GET_SAVE_CHAPTER_ID:
            return Object.assign({}, state, {
                saveChapterId: action.payload
            });

        case editKeys.EDIT_TO_LIST:
            return Object.assign({}, state, {
                toChapterList: action.payload
            });

        case editKeys.GET_HAS_RELEASE_NOVEL_ID:
            return Object.assign({}, state, {
                hasReleaseNovelId: action.payload
            });

        case editKeys.GET_NOVEL_ID_FOR_URL:
            return Object.assign({}, state, {
                novelId: action.payload
            });

        case editKeys.VISIBLE_PROMPT:
            return Object.assign({}, state, {
                promptModal: action.payload.bol,
                promptText: action.payload.value
            });

        case editKeys.AUTO_SAVE_CHAPTER_TOOLTIP_TEXT:
            return Object.assign({}, state, {
                saveTextShow: action.payload
            });

        case editKeys.GET_SENSITIVE_WORD_LIST:
            return Object.assign({}, state, {
                sensitiveWord: action.payload
            });

        default:
            return state;
    }
};
