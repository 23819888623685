import { fork, all, takeEvery, put, select, call, take } from 'redux-saga/effects';
import WithdrawDetailKeys from '../../component/settlement/action/keys/withdrawDetailKeys';
import * as withdrawDetailAction from '../../component/settlement/action/withdrawDetailAction';
import * as settlementServer from '../../server/settlementServer';
import { errorTip } from './common';
import { delay } from 'redux-saga/effects';

function* initPage() {
    yield getDataList();
}

function* getDataList() {
    try {
        const state = yield select();
        const data = state.withdrawDetailReducer;
        const param = {
            ...data.page,
            data: {...data.searchData}
        };
        const result = yield call(settlementServer.getWithdrawList, param);
        yield put(withdrawDetailAction.getDataList({dataList: result.data, total: result.total}));
    } catch (err) {
       if (err.code === 319) {
           yield errorTip('Belum konfirmasi ID', withdrawDetailAction.promptModal);
           yield put(withdrawDetailAction.toCertification(true));
           yield delay(2000);
           yield put(withdrawDetailAction.toCertification(false));
       } else {
           yield errorTip('Gagal terhubung ke server, silakan coba lagi', withdrawDetailAction.promptModal);
       }
    }
}

function* getOrderMsg() {
    while (true) {
        const { payload } = yield take(WithdrawDetailKeys.GET_WITHDRAW_ORDER_ID);
        try {
            yield put(withdrawDetailAction.loading(true));
            const result = yield call(settlementServer.getOrderMsg, payload);
            yield getBankCardInfo();
            yield put(withdrawDetailAction.getOrderMsg(result));
            yield put(withdrawDetailAction.loading(false));
        } catch (e) {
            yield errorTip('Gagal terhubung ke server, silakan coba lagi', withdrawDetailAction.promptModal);
        }
    }
}

function* getBankCardInfo() {
    const result = yield call(settlementServer.getBankCardInfo);
    yield put(withdrawDetailAction.getOrderBankCardInfo(result));
}

function* watchInitPage() {
    yield takeEvery(WithdrawDetailKeys.INIT_PAGE, initPage);
}

function* watchSearchData() {
    yield takeEvery(WithdrawDetailKeys.DATA_LIST_SEARCH, getDataList);
}

export function* watchWithdrawDetailSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearchData),
        fork(getOrderMsg)
    ]);
}
