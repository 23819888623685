import * as React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import * as ReactDOM from 'react-dom';
import App from '../component/App';
import LoginPage from '../layout/setting/loginSetting';
import { indexRouter } from '../component/index/router';
import { editRouter } from '../component/edit/router';
import { userInfoRouter } from '../component/user/router';
import { helpRouter } from '../component/help/router';
import { settlementRouter } from '../component/settlement/router';
import { welfareRouter } from '../component/welfare/router';
import OfflinePage from '../layout/OfflinePage';
import AuthorPactPage from '../layout/AuthorPactPage';
import PrivacyPolicyPage from '../layout/PrivacyPolicyPage';
import ErrorPage from '../layout/ErrorPage';
import ConstructionPage from '../layout/ConstructionPage';

import { withNamespaces } from 'react-i18next';
import * as H from 'history';

class Routers extends React.Component<any, any> {

    getConfirmation = (message: string | ((location: H.Location) => string | boolean), callback: any) => {
        const container: any = document.getElementById('root1');
        const ConFirmComponent = () => {
            const { t } = this.props;
            return (
                <div>
                    <div className="root1_mask" />
                    <div className="leaveModal confirm">
                        <em>{message}</em>
                        <ul>
                            <li
                                className="create"
                                onClick={() => {callback(false); ReactDOM.unmountComponentAtNode(container); }}
                            >
                                {t('THINK_AGAIN')}
                            </li>
                            <li
                                className="cancel"
                                onClick={() => {callback(true); ReactDOM.unmountComponentAtNode(container); }}
                            >
                                {t('LEAVE')}
                            </li>
                        </ul>
                    </div>
                </div>
            );
        };
        ReactDOM.render(
            <ConFirmComponent/>,
            document.getElementById('root1')
        );
    }

    render() {

        return (
            <HashRouter getUserConfirmation={this.getConfirmation}>
                <App>
                    <Switch>
                        <Route exact={true} path="/" component={() => <Redirect to={{pathname: '/novel/index'}}/>}/>
                        <Route path="/novel" render={() => indexRouter} />
                        <Route path="/edit" render={() => editRouter} />
                        <Route path="/user" render={() => userInfoRouter} />
                        <Route path="/help" render={() => helpRouter} />
                        <Route path="/settlement" render={() => settlementRouter} />
                        <Route path="/welfare" render={() => welfareRouter} />
                        <Route path="/noNetwork" component={OfflinePage} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/authorPact" component={AuthorPactPage} />
                        <Route path="/privacyPolicy" component={PrivacyPolicyPage} />
                        <Route path="/error" component={ErrorPage} />
                        <Route path="/construction" component={ConstructionPage} />

                        <Route component={ErrorPage} />
                    </Switch>
                </App>
            </HashRouter>
        );
    }
}

export default withNamespaces('translation')(Routers);
