import * as React from 'react';
import * as ReactDOM from 'react-dom';
// import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import * as Loadable from 'react-loadable';
import Routers from './router/routers';
import store from './store/store';
import './i18n/i18n';

const AppBundle = (
    <Provider store={store}>
        <Routers />
    </Provider>
);

window.onload = () => {
    Loadable.preloadReady().then(() => {
        ReactDOM.hydrate(
            AppBundle,
            document.getElementById('root')
        );
    });
};

// ReactDOM.render(
//     <Provider store={store}>
//         <Routers />
//     </Provider>,
//     document.getElementById('root')
// );
// registerServiceWorker();
