import { postJson, postForm } from '../utils/xhr';
import axios from 'axios';

/**
 * 获取我的文集列表2.0
 */
export function getMyNovelList(param: any) {
    return postJson('/api/novel/getMyNovelList', param);
}

/**
 * 文集提交参赛2.0
 */
export function submitToCompetition(param: any) {
    return postJson('/api/novel/submitToCompetition', param);
}

/**
 * 新建文集2.0
 */
export function createNewCropus(param: any) {
    return postJson('/api/novel/addMyNovel', param);
}

/**
 * 编辑文集2.0
 */
export function editCorpus(param: any) {
    return postJson('/api/novel/modifyMyNovel', param);
}

/**
 * 删除文集2.0
 */
export function delCorpus(param: any) {
    return postJson('/api/novel/deleteMyNovel', param);
}

/**
 * 获取文集type2.0
 */
export function getNovelTypeList() {
    return postJson('/api/novel/getNovelTypeList');
}

/**
 * 上架章节2.0
 */
export function showChapter(id: string) {
    return postJson('/api/chapter/showChapter', id);
}

/**
 * 请求文集详情2.0
 */
export function getNovelDetailById(id: string) {
    return postJson('/api/novel/getNovelDetailById', id);
}

/**
 * 从回收站彻底删除章节2.0
 */
export function deleteChapterFromTrash(id: number) {
    return postJson('/api/chapter/deleteChapterFromTrash', id);
}

/**
 * 删除章节2.0
 */
export function deleteChapter(id: number) {
    return postJson('/api/chapter/deleteChapter', id);
}

/**
 * 获取回收站中的章节列表2.0
 */
export function getChapterListFomTrash(param: any) {
    return postJson('/api/chapter/getChapterListFomTrash', param);
}

/**
 * 从回收站还原章节2.0
 */
export function restoreChapterFromTrash(id: number) {
    return postJson('/api/chapter/restoreChapterFromTrash', id);
}

/**
 * 多章节上传2.0
 */
export function uploadChapterBatch(param: any) {
    return postJson('/api/chapter/uploadChapterBatch', param);
}
/**
 * 修改文章排序2.0
 */
export function modifyChapterSort(param: any) {
    return postJson('/api/chapter/modifyChapterSort', param);
}

/**
 * 请求章节列表2.0已删除
 */
// export function getChapterList(param: any) {
//     return postJson('/api/chapter/getChapterList', param);
// }

/**
 * 获取小说上架章节列表
 */
export function getShelfChapterList(param: any) {
    return postJson('/api/chapter/getAllOnShelfChapterList', param);
}

/**
 * 获取草稿箱小说章节列表
 */
export function getDraftChapterList(param: any) {
    return postJson('/api/chapter/getAllOnDraftChapterList', param);
}

/**
 * 新建并发布章节2.0
 */
export function addAndReleaseChapter(param: any) {
    return postJson('/api/chapter/addAndReleaseChapter', param);
}

/**
 * 请求章节详情2.0
 */
export function getChapterDetailById(id: string) {
    return postJson('/api/chapter/getChapterDetailById', id);
}

/**
 * 请求章节内容2.0
 */
export function getChapterCon(url: string) {
    return axios.get(url, {
        headers: {'content-type': 'text/plain; charset=UTF-8;'}
    });
}

/**
 * 查询任务进度
 */
export function getTaskSchedule(id: number) {
    return postJson('/api/starsrecord/getProgress', id);
}

/**
 * 获取章节数据汇总 2.9.0
 */
export function getChapterNumByDraftState(id: number | string) {
    return postJson('/api/chapter/getChapterNumByDraftState', id);
}

/**
 * 请求小说付费
 * @novel novel
 */
export function modifyPaymentStatus(id: any) {
    return postJson('/api/novel/modifyPaymentStatus', id);
}

export function uploadImgToServer(file: File, type: string) {
    const formData = new FormData();
    formData.append('filename', JSON.stringify([file.name]));
    formData.append('type', type);
    formData.append('file#0', file);
    return postForm('/api/upload/pictures', formData);
}

export function uploadChapterToServer(file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', file.name);
    return postForm('/api/upload/chapter', formData);
}

/**
 * 申请签约
 * @param novelId 
 */
export function applySign(novelId) {
    return postJson('/api/novel/applySign', novelId);
}

/**
 * 申请签约
 * @param novelId 
 */
export function applyPay(novelId) {
    return postJson('/api/novel/issatisfyapplycharge', novelId);
}

/**
 * 申请付费
 * @param param 
 */
export function applyCharge(param) {
    return postJson('/api/novel/applyCharge', param);
}

export function getArticleInfoModifyStatus(id) {
    return postJson('/api/novel/getArticleInfoModifyStatus', id);
}

export function joinNtwCompetition(param) {
    return postJson('/api/novel/joinNtwCompetition', param);
}
