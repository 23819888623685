enum IncomeKeys {
    INIT_PAGE = 'INCOME_PAGE_INIT_PAGE',
    GET_INCOME_INFO = 'INCOME_PAGE_GET_INCOME_INFO',
    GET_INCOME_RECORD_LIST = 'INCOME_PAGE_GET_INCOME_RECORD_LIST',
    INCOME_RECORD_LIST_SEARCH = 'INCOME_PAGE_INCOME_RECORD_LIST_SEARCH',
    UPDATE_INCOME_RECORD_LIST_PAGE_SIZE = 'INCOME_PAGE_UPDATE_INCOME_RECORD_LIST_PAGE_SIZE',
    GET_TYPE_LIST = 'INCOME_PAGE_GET_TYPE_LIST',
    GET_SECTION_LIST = 'INCOME_PAGE_GET_SECTION_LIST',
    RULE_MODAL_VISIBLE = 'INCOME_PAGE_RULE_MODAL_VISIBLE',
    CHANGE_CHAPTER = 'INCOME_PAGE_CHANGE_CHAPTER',
}

export default IncomeKeys;
