import WithdrawKeys from './keys/withdrawKeys';

export function initPage() {
    return { type: WithdrawKeys.INIT_PAGE };
}

export function getWithdrawInfo(dataList: any) {
    return { type: WithdrawKeys.GET_WITHDRAW_INFO, payload: dataList };
}

export function getBankCardInfo(dataList: any) {
    return { type: WithdrawKeys.GET_BACK_CARD_INFO, payload: dataList };
}

export function getServiceCharges(dataList: any) {
    return { type: WithdrawKeys.GET_SERVICE_CHARGES, payload: dataList };
}

export function modifyBankCardInfo(param: any) {
    return { type: WithdrawKeys.MODIFY_BACK_CARD_INFO, payload: param };
}

export function getBackList(dataList: any) {
    return { type: WithdrawKeys.GET_BANK_LIST, payload: dataList };
}

export function ruleModalVisible(bol: boolean) {
    return { type: WithdrawKeys.RULE_MODAL_VISIBLE, payload: bol };
}

export function withdrawModalVisible(bol: boolean) {
    return { type: WithdrawKeys.WITHDRAW_MODAL_VISIBLE, payload: bol };
}

export function verifying(bol: boolean) {
    return { type: WithdrawKeys.VERIFYING_PAGE, payload: bol };
}

export function toVerify(bol: boolean) {
    return { type: WithdrawKeys.TO_VERIFY_PAGE, payload: bol };
}

export function toInput(bol: boolean) {
    return { type: WithdrawKeys.TO_INPUT_PAGE, payload: bol };
}

export function applySuccessModal(bol: boolean) {
    return { type: WithdrawKeys.APPLY_SUCCESS_MODAL, payload: bol };
}

export function dataLoading(bol: boolean) {
    return { type: WithdrawKeys.DATA_LOADING, payload: bol };
}

export function getPrice(value: any) {
    return { type: WithdrawKeys.GET_WITHDRAW_PRICE, payload: value };
}

export function authentication(value: any) {
    return { type: WithdrawKeys.AUTHENTICATION, payload: value };
}

export function serverInputError(bol: boolean, value?: string) {
    return { type: WithdrawKeys.SERVER_INPUT_ERROR, payload: {bol, value} };
}

export function withdrawError(bol: boolean, value?: string) {
    return { type: WithdrawKeys.WITHDRAW_ERROR, payload: {bol, value} };
}

export function saveButton(bol: boolean) {
    return { type: WithdrawKeys.SAVE_BUTTON, payload: bol };
}

export function prompt(param: {bol: boolean, value?: string}) {
    return { type: WithdrawKeys.PROMPT, payload: param };
}

export function getTaxParam() {
    return { type: WithdrawKeys.GET_TAX_PARAM};
}

export function getTaxList(list: any) {
    return { type: WithdrawKeys.GET_TAX_LIST, payload: list};
}

export function extractModalLoading(bol: boolean) {
    return { type: WithdrawKeys.EXTRACT_MODAL_LOADING, payload: bol };
}