import * as React from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Modal } from 'antd';
import emitter from '../config/events';
import { loginOut } from '../server/loginServer';
import IndexPageModal from '../component/index/view/IndexPageModal';

interface HeaderState {
    logOutShow: boolean;
    menuShow: boolean;
    secondMenuShow: boolean;
    toLogin: boolean;
    promptModal: boolean;
    name: string;
    penName: string;
    authenticationStatus: string;
    avatar: string;

}

interface Props {
    history: any;
    initIndexPage: () => void;
    changeShowPrompt: (bol: boolean) => void;
    openAuthorRegisterModal: (bol: boolean) => void;
    i18n: any;
    t: any;
    authorRegisterModalVisible: boolean;
}

class Header extends React.Component<Props, HeaderState> {

    state: HeaderState = {
        logOutShow: false,
        menuShow: false,
        secondMenuShow: false,
        toLogin: false,
        promptModal: false,
        name: '',
        penName: '',
        authenticationStatus: '',
        avatar: ''
    };

    eventEmitter: any;

    componentDidMount () {
        const {initIndexPage} = this.props;
        initIndexPage();
        this.eventEmitter = emitter.addListener('changeMessage', (message) => {
            this.setState({
                name: message.name,
                authenticationStatus : message.authenticationStatus,
                avatar: message.portraitUrl,
                penName: message.penName,

            });
        });
        let avatar = window.localStorage.getItem('avatar');
        let name = window.localStorage.getItem('name');
        let penName = window.localStorage.getItem('penName');
        let authenticationStatus = window.localStorage.getItem('authenticationStatus');
        let token = window.localStorage.getItem('TOKEN');
        if (token) {
            if (avatar) {
                this.setState({avatar: avatar});
            }
            if (name) {
                this.setState({name: name});
            }
            if (penName) {
                this.setState({penName: penName});
            }
            if(authenticationStatus){
                this.setState({authenticationStatus: authenticationStatus});
            }
        }
    }

    mouseEnter = () => {
        this.setState({logOutShow: true});
    }

    mouseLeave = () => {
        this.setState({logOutShow: false});
    }

    menuMouseEnter = () => {
        this.setState({menuShow: true});
    }

    menuMouseLeave = () => {
        this.setState({menuShow: false});
    }

    secondMenuMouseEnter = () => {
        this.setState({secondMenuShow: true});
    }

    secondMenuMouseLeave = () => {
        this.setState({secondMenuShow: false});
    }

    outLogin = () => {
        this.props.changeShowPrompt(false);
        loginOut().then(res => {
            this.setState({promptModal: true});
            window.localStorage.removeItem('TOKEN');
            window.localStorage.removeItem('avatar');
            window.localStorage.removeItem('name');
            window.localStorage.removeItem('penName');
            window.localStorage.removeItem('authenticationStatus');
            setTimeout(() => { this.setState({promptModal: false, toLogin: true });  }, 2000);
        });
    }

    promptModalCancel = () => {
        this.setState({promptModal: false});
    }

    goAuthorRegister = () => {
        this.props.openAuthorRegisterModal(false);
        this.props.history.push('/user/register');
    }

    render() {
        const { t, authorRegisterModalVisible } = this.props;
        // const { t, i18n } = this.props;
        // const changeLanguage = (lng: any) => {
        //     i18n.changeLanguage(lng);
        // };

        const { logOutShow, toLogin, promptModal, avatar, name,authenticationStatus, penName, menuShow, secondMenuShow } = this.state;
        let router: string = window.location.hash.split('#')[1];
        let key: string = router.split('/')[1];
        let index: boolean = false, editor = false, settlement = false, help = false, user = false;
        if (key === 'novel') {
            index = true;
        } else if (key === 'edit') {
            editor = true;
        } else if (key === 'settlement') {
            settlement = true;
        } else if (key === 'help') {
            help = true;
        } else if (key === 'user') {
            user = true;
        } else {
            console.log('1');
        }

        if (toLogin) {
            return <Redirect to="/login" />;
        }

        // @ts-ignore
        return (
           <header className="header">

               <div className="header_main">
                   <Link to="/novel/index">
                       <img className="header_main_logo" src={`https://jkt-web-cdn.novelme.id/novelme/author/pic/newLogo.svg`} />
                   </Link>
                   <ul>
                       <li className={index ? 'on' : ''} onMouseOver={this.menuMouseEnter} onMouseLeave={this.menuMouseLeave}>
                           <a onClick={e => e.preventDefault()}>{t('WORK_MANAGER')}</a>
                           <div className="caret" />
                           <div className={menuShow ? 'workMenu workMenuEnter' : 'workMenu workMenuLeave'}>
                               <Link to="/novel/index">{t('WORK_MANAGER')}</Link>
                               {/*<Link to="/novel/workList">{t('CORPUS_MANAGER')}</Link>*/}
                               <Link to="/novel/corpusUpload">{t('UPLOAD_SECTION')}</Link>
                           </div>
                       </li>
                       <li  className={editor ? 'on' : ''}>
                           <Link to="/edit/editor">{t('CREATION')}</Link>
                       </li>
                       <li className={settlement ? 'on' : ''} onMouseOver={this.secondMenuMouseEnter} onMouseLeave={this.secondMenuMouseLeave}>
                           <a onClick={e => e.preventDefault()}>{t('WRITER_SETTLEMENT')}</a>
                           <div className="caret" />
                           <div className={secondMenuShow ? 'workMenu workMenuEnter' : 'workMenu workMenuLeave'}>
                               <Link to="/settlement/income">{t('WRITER_SETTLEMENT')}</Link>
                               <Link to="/settlement/withdraw">{t('WITHDRAW')}</Link>
                           </div>
                       </li>
                       <li className={help ? 'on' : ''}>
                           <Link to="/help/supportCenter">{t('HELP')}</Link>
                       </li>
                   </ul>
                   <div className={user ? 'header_main_avatar on' : 'header_main_avatar'} onMouseOver={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                       <img  src={avatar || `${t('ossUrl')}default_avatar.svg`}/>
                       <span className="name">{penName || name}</span>
                       <div className="caret" />
                       <div className={logOutShow ? 'logOut logOutEnter' : 'logOut logOutLeaver'}>
                           <div>
                               <Link to="/user/info">
                                   {t('PERSONAL_DATA')}</Link>
                           </div>
                           {
                               ((penName != "" && penName != null) && (authenticationStatus != "2"))?
                               <div>
                               <Link to="/user/certification">
                               {t('CERTIFICATION')}</Link>
                               </div> : <div></div>
                           }
                           <div onClick={this.outLogin}>Log Out</div>
                           {/*<div onClick={() => changeLanguage('id')}>id</div>*/}
                           {/*<div onClick={() => changeLanguage('cn')}>cn</div>*/}
                       </div>
                   </div>
               </div>
               <Modal
                   visible={promptModal}
                   centered={true}
                   footer={null}
                   closable={false}
                   maskClosable={true}
                   onCancel={this.promptModalCancel}
                   className="confirm prompt"
               >
                   <em>{t('EXIT_SUCCESS')}</em>
               </Modal>
               {/* 作者注册弹框 */}
               <IndexPageModal
                        t={t}
                        visible={authorRegisterModalVisible}
                        closable={false}
                        onOk={this.goAuthorRegister}
                        content={t('REGISTER_AUTHOR_FIRST')}
                    />
           </header>
        );
    }
}

export default withRouter(Header);
