enum WithdrawKeys {
    INIT_PAGE = 'WITHDRAW_PAGE_INIT_PAGE',
    GET_WITHDRAW_INFO = 'WITHDRAW_PAGE_GET_WITHDRAW_INFO',
    GET_BACK_CARD_INFO = 'WITHDRAW_PAGE_GET_BACK_CARD_INFO',
    GET_SERVICE_CHARGES = 'WITHDRAW_PAGE_GET_SERVICE_CHARGES',
    MODIFY_BACK_CARD_INFO = 'WITHDRAW_PAGE_MODIFY_BACK_CARD_INFO',
    GET_BANK_LIST= 'WITHDRAW_PAGE_GET_BANK_LIST',
    RULE_MODAL_VISIBLE = 'WITHDRAW_PAGE_RULE_MODAL_VISIBLE',
    WITHDRAW_MODAL_VISIBLE = 'WITHDRAW_PAGE_WITHDRAW_MODAL_VISIBLE',
    TO_VERIFY_PAGE = 'WITHDRAW_PAGE_TO_VERIFY_PAGE',
    VERIFYING_PAGE = 'WITHDRAW_PAGE_VERIFYING_PAGE',
    TO_INPUT_PAGE = 'WITHDRAW_PAGE_TO_INPUT_PAGE',
    GET_WITHDRAW_PRICE = 'WITHDRAW_PAGE_GET_WITHDRAW_PRICE',
    AUTHENTICATION = 'WITHDRAW_PAGE_AUTHENTICATION',
    SERVER_INPUT_ERROR = 'WITHDRAW_PAGE_SERVER_INPUT_ERROR',
    WITHDRAW_ERROR = 'WITHDRAW_PAGE_WITHDRAW_ERROR',
    APPLY_SUCCESS_MODAL = 'WITHDRAW_PAGE_APPLY_SUCCESS_MODAL',
    DATA_LOADING = 'WITHDRAW_PAGE_DATA_LOADING',
    SAVE_BUTTON = 'WITHDRAW_PAGE_SAVE_BUTTON',
    PROMPT = 'WITHDRAW_PAGE_PROMPT',
    GET_TAX_PARAM = 'WITHDRAW_PAGE_GET_TAX_PARAM',
    GET_TAX_LIST = 'WITHDRAW_PAGE_GET_TAX_LIST',
    EXTRACT_MODAL_LOADING = 'WITHDRAW_PAGE_EXTRACT_MODAL_LOADING'
}

export default WithdrawKeys;
