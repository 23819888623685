import { postJson } from '../utils/xhr';

/**
 * 请求个人数据
 */
export function userCheck() {
    return postJson('/api/user/check');
}

/**
 * 请求个人数据
 */
export function getPersonalData() {
    return postJson('/api/personal/getPersonalData');
}

/**
 * 公告数据
 */
export function getNoticeList(param: any) {
    return postJson('/api/bulletin/getBulletinList', param);
}

/**
 * 获取置顶公告
 */
export function getTopBulletin() {
    return postJson('/api/bulletin/getTopBulletin');
}

/**
 * 公告详情
 */
export function getNoticeDetail(id: string) {
    return postJson('/api/bulletin/getBulletinById', id);
}

/**
 * 获取banner列表
 */
export function getBannerList() {
    return postJson('/api/banner/getBannerList');
}

/**
 * 获取用户通知
 */
export function getUserNotification() {
    return postJson('/api/notification/getUserNotification');
}

/**
 * 标记通知为已读
 */
export function markAsRead(id: number|string) {
    return postJson('/api/notification/markAsRead', id);
}

/**
 * 轮播点击统计
 */
export function userClickBanner(id: number|string) {
    return postJson('/api/banner/userClickBanner', id);
}

/**
 * 查询星票明细
 */
export function getStarDetailMsg(param: any) {
    return postJson('/api/starsrecord/getStarInfo', param);
}

/**
 * 查询打赏明细
 */
export function getRewardList(param: any) {
    return postJson('/api/starsrecord/getRewardDetailList', param);
}
/**
 * 查询销售明细
 */
export function getSaleList(param: any) {
    return postJson('/api/starsrecord/getSalesDetailList', param);
}