import * as React from 'react';

export default class PrivacyPolicyPage extends React.Component<any, any> {

    render() {

        return (
            <section className="index_notice notice_list">
                <div className="notice_detail">
                    <h1>Kebijakan Privasi</h1>
                    <p>NovelMe (“we”) are committed to protecting and respecting your privacy. While using our products (including services), we may collect and use your relevant information in order to improve the quality of our services; we will do everything in our power to protect your privacy, as long as it is within a reasonable business scope. We recommend that you read the "Privacy Policy" in detail to get an understanding of what type of information we collect, how we collect this information and how we use it. By using our products or services, you agree to the collection, storage and use of your information as long as it follows the context dictated here and the adherence of local laws and regulations.</p>
                    <p>This "Privacy Policy" utilizes certain professional terms; we will try to explain things as concise as possible, so that you may understand completely.</p>
                    <p>Our Privacy Policy explains how we and some of the companies we work with collect, use, share and protect information in relation to our mobile services, web site, and any software provided on or in connection with our services (collectively, the "Service"), and your choices about the collection and use of your information.</p>
                    <h5>1. INFORMATION WE COLLECT</h5>
                    <p>We collect the following types of information:</p>
                    <p>Information you provide to us or upload, which includes information you supply when you register an account with us and save items to your profile.</p>
                    <p>Device or SIM-related information: information related to your device. For example, IMEI number, Android version, device manufacturer details and model name, network operator.</p>
                    <p>Location information (only for specific services/functionalities): various types of information on your location. For example, country code, city code, mobile network code, cell identity, longitude and latitude information, language settings.</p>
                    <p>Log information: information related to your use of certain functions, apps and websites. For example, cookies and other anonymous identifier technologies, IP addresses, temporary message history, standard system logs.</p>
                    <p>Payment information: information related to your payment of certain products or services. For example, billing accounts, the mode and types of payment, purchase orders, payment account, credit cards, online banking, etc.</p>
                    <h5>2. HOW WE USE YOUR INFORMATION</h5>
                    <p>Provide, improve, test, and monitor the effectiveness of our Service.</p>
                    <p>Develop and test new products and features.</p>
                    <p>Monitor metrics such as total number of visitors, traffic, and demographic patterns.</p>
                    <p>Diagnose or fix technology problems.</p>
                    <h5>3. SHARING OF YOUR INFORMATION</h5>
                    <p>We will not rent or sell your information to third parties outside NovelMe (or the group of companies of which NovelMe is a part) without your consent, except as noted in this Policy.</p>
                    <p>Parties with whom we may share your information:</p>
                    <p>We may share your information as well as information from tools like cookies, log files, and device identifiers and location data, with third-party organizations that help us measure traffic and usage trends for the service. Our Service Providers will be given access to your information as is reasonably necessary to provide the Service under reasonable confidentiality terms.</p>
                    <p>What happens in the event of a change of control:</p>
                    <p>If we sell or otherwise transfer part or the whole of NovelMe or our assets to another organization (e.g., in the course of a transaction like a merger, acquisition, bankruptcy, dissolution, liquidation), your information such as name and email address, User Content and any other information collected through the Service may be among the items sold or transferred. You will continue to own your User Content. The buyer or transferee will have to honor the commitments we have made in this Privacy Policy.</p>
                    <h5>4. HOW TO CONTACT US</h5>
                    <p>If you have any questions about this Privacy Policy or the Service, please contact us at nexttopwriter@novelmeid.com.</p>
                    <h5>5. CHANGES TO OUR PRIVACY POLICY</h5>
                    <p>NovelMe may, in its sole discretion, modify or update this Privacy Policy from time to time, and so you should review this page periodically. When we change the policy in a material manner, we will update the ‘last modified’ date at the bottom of this page.</p>
                    <p>This Privacy Policy was last modified on Sep 20, 2018</p>
                </div>
            </section>
        );
    }
}
