import { all, take, fork, call, put, select, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import articleKeys from '../../component/index/action/keys/articleKeys';
import * as workServer from '../../server/workServer';
import { editToReleaseChapter, revisionChapter, toEditChapter } from '../../server/editServer';
import * as articleAction from '../../component/index/action/articleAction';
import * as editServer from '../../server/editServer';
import * as Mean from '../../i18n';

function* initPage() {
    yield getSensitiveWordList();
}

function* getSensitiveWordList() {
    try {
        const result = yield call(editServer.getSensitiveWordList);
        yield put(articleAction.getSensitiveWord(result));
    } catch (err) {
        yield errorTip(Mean.NO_NETWORK);
    }
}

function* getChapterMsg() {
    while (true) {
        const { payload } = yield take(articleKeys.GET_CHAPTER_ID);
        try {
            const result = yield call(workServer.getChapterDetailById, payload.id);
            if (payload.isRelease === '1') {
                const { data } = yield call(workServer.getChapterCon, result.contentUrl);
                result.content = data;
            } else {
                const { data } = yield call(workServer.getChapterCon, result.draftContentUrl);
                result.content = data;
                result.name = result.draftName === '' ? result.name : result.draftName;
                // console.log(result.draftName, result );
                result.draftContent = data;
            }

            yield put(articleAction.getChapterMsg(result));
        } catch (err) {
            yield errorTip(Mean.NO_NETWORK);
        }
    }
}

function* saveChapterMsg() {
    while (true) {
        const { payload } = yield take(articleKeys.SAVE_CHAPTER);
        const data = yield select();
        const reducer = data.articleReducer;
        if (reducer.chapterName === '' || reducer.chapterCon.trim() === '') {
            if (payload === 'click') {
                yield errorTip('Silakan isi nama bab / isi bab');
            }
        } else {
            let param = {
                content: reducer.chapterDraftCon,
                name:  reducer.chapterDraftName,
                chapterId: reducer.chapterMsg.id,
            };

            try {
                if (param.name.length > 60) {
                    yield errorTip('Nama Bab maks. 60 huruf');
                } else {
                    if (payload === 'click') {
                        yield errorTip('Bab sedang disimpan, silakan tunggu');
                    } else {
                        yield put(articleAction.autoSaveTextShow(true));
                    }

                    yield toEditChapter(param);

                    if (payload === 'click') {
                        yield errorTip('Bab sudah disimpan');
                    } else {
                        yield put(articleAction.autoSaveTextShow(false));
                    }
                }

            } catch (err) {
                if (err.code === 327) {
                    yield put(articleAction.autoSaveTextShow(false));
                    let interval: any = window.sessionStorage.getItem('interval');
                    clearInterval(interval);
                } else {
                    yield errorTip('Gagal terhubung ke server, silakan coba lagi');
                }
            }
        }

    }
}

function* releaseChapter() {
    while (true) {
        const { payload } = yield take(articleKeys.RELEASE_CHAPTER);
        const data = yield select();
        const reducer = data.articleReducer;

        if (reducer.chapterDraftName === '' || reducer.chapterDraftCon.trim() === '') {
            yield errorTip('Silakan isi nama bab / isi bab');
        } else {
            try {
                let param = {
                    content: reducer.chapterDraftCon, // restatus === 1 || status === 1 ? reducer.chapterName :
                    name: reducer.chapterDraftName, // restatus === 1 || status === 1 ? reducer.chapterCon :
                    chapterId: reducer.chapterMsg.id,
                };

                if (param.name.length > 60) {
                    yield errorTip('Nama Bab maks. 60 huruf');
                } else {
                    // 弹出框提示 - 正在发布
                    yield errorTip('Bab sedang dikirim, silakan tunggu');
                    if (payload === 'modify') {
                        yield revisionChapter(param);
                    } else {
                        yield toEditChapter(param);
                        // // 请求接口
                        yield editToReleaseChapter(reducer.chapterMsg.id);
                    }
                    // 弹出提示 - 发布成功
                    yield errorTip('Sukses mengirim');
                    yield put(articleAction.toList(true));
                    yield delay(500);
                    yield put(articleAction.toList(false));
                }

            } catch (err) {
                // 弹出提示 - 发布失败
                yield errorTip('Gagal mengirim');
                yield put(articleAction.toList(false));
            }
        }

    }
}

// 获取旧内容
function* getDraftContent() {
    while (true) {
       const { payload } = yield take(articleKeys.CHANGE_CONTENT);
       const list = yield select();
       const reducer = list.articleReducer;
       if (payload) {
           const {data} = yield call(workServer.getChapterCon, reducer.chapterMsg.draftContentUrl);
           yield put(articleAction.getDraftContent(data));
       }
    }
}

// 点击修订按钮后
function* isDraftBtn() {
    while (true) {
        yield take(articleKeys.IS_DRAFT_BTN);
        const list = yield select();
        const reducer = list.articleReducer;
        const {data} = yield call(workServer.getChapterCon, reducer.chapterMsg.draftContentUrl);
        yield put(articleAction.getDraftContent(data));
        const { chapterDraftName, chapterName } = reducer;
        if (chapterDraftName === '') {
            yield put(articleAction.getDraftName(chapterName));
        }
    }
}

function* errorTip(text: string) {
    yield put(articleAction.getPromptText(text));
    yield put(articleAction.openPrompt(true));
    yield delay(2000);
    yield put(articleAction.openPrompt(false));
}

function* watchInitPage() {
    yield takeEvery(articleKeys.INIT_PAGE, initPage);
}

export function* watchArticleSaga() {
    yield all([
        fork(watchInitPage),
        fork(getChapterMsg),
        fork(saveChapterMsg),
        fork(releaseChapter),
        fork(getDraftContent),
        fork(isDraftBtn)
    ]);
}
