import NovelDetailKeys from './keys/novelDetailKeys';

export function initPage() {
    return { type: NovelDetailKeys.INIT_PAGE };
}

export function getCorpusId(param: any) {
    return { type: NovelDetailKeys.GET_CORPUS_ID, payload: param };
}

export function getChapterList(list: any) {
    return { type: NovelDetailKeys.GET_CHAPTER_LIST, payload: list };
}

export function personalData(list: any) {
    return { type: NovelDetailKeys.GET_PERSONAL_DATA, payload: list };
}

export function novelDetailInfo(msg: any) {
    return { type: NovelDetailKeys.NOVEL_DETAIL_INFO, payload: msg};
}

export function getUpdatePage(page: number, pageSize: number) {
    return { type: NovelDetailKeys.UPDATE_PAGE, payload: { page, pageSize} };
}

export function recycleUpdatePage(page: number, pageSize: number) {
    return { type: NovelDetailKeys.RECYCLE_LIST_CHANGE_DATE, payload: { page, pageSize} };
}

export function initUpdatePage(page: number, pageSize: number) {
    return { type: NovelDetailKeys.INIT_UPDATE_PAGE, payload: { page, pageSize} };
}

export function delChapter(id: number) {
    return { type: NovelDetailKeys.DEL_CHAPTER, payload: id };
}

export function listLoading(value: boolean) {
    return { type: NovelDetailKeys.LOADING, payload: value };
}

export function changeChapter(value: any) {
    return { type: NovelDetailKeys.CHANGE_CHAPTER, payload: value };
}

export function releaseChapter(item: any) {
    return { type: NovelDetailKeys.RELEASE_CHAPTER, payload: item };
}

export function showChapter(id: number | string) {
    return { type: NovelDetailKeys.SHOW_CHAPTER, payload: id };
}

export function prompt(param: {bol: boolean, value?: string }) {
    return { type: NovelDetailKeys.PROMPT, payload: param };
}

export function task (bol: boolean) {
    return { type: NovelDetailKeys.TASK, payload: bol };
}

export function getTaskStar(param: number) {
    return { type: NovelDetailKeys.TASKSTAR, payload: param };
}

export function restoreChapter(id: number) {
    return { type: NovelDetailKeys.RESTORE_CHAPTER, payload: id };
}

export function getRecycleId(param: any) {
    return { type: NovelDetailKeys.GET_RECYCLE_ID, payload: param };
}

export function getRecycleList(list: any[]) {
    return { type: NovelDetailKeys.GET_RECYCLE_LIST, payload: list };
}

export function getSearchStartNum(num: string) {
    return { type: NovelDetailKeys.GET_SEARCH_START_NUM, payload: num };
}

export function getSearchEndNum(num: string) {
    return { type: NovelDetailKeys.GET_SEARCH_END_NUM, payload: num };
}

export function getNovelDetailById(id: string) {
    return { type: NovelDetailKeys.GET_NOVEL_DETAIL_BY_ID, payload: id };
}

export function releaseModal(bol: boolean) {
    return { type: NovelDetailKeys.RELEASE_MODAL, payload: bol };
}

export function certificationModal(bol: boolean) {
    return { type: NovelDetailKeys.CERTIFICATION_MODAL, payload: bol };
}

export function competitionModal(bol: boolean) {
    return { type: NovelDetailKeys.COMPETITION_MODAL, payload: bol };
}

export function unverifiedModal(bol: boolean) {
    return { type: NovelDetailKeys.UNVERIFIED_MODAL, payload: bol };
}

export function isCompetition(param?: any) {
    return { type: NovelDetailKeys.COMPETITION_OK, payload: param };
}

export function getNovelId(id: number | string) {
    return { type: NovelDetailKeys.GET_NOVEL_ID, payload: id };
}

export function getChapterNumByDiffStatus(param: any) {
    return { type: NovelDetailKeys.GET_DIFF_STATUS_CHAPTER_NUM, payload: param };
}

export function gerChangeChapterNum(num: number | string) {
    return { type: NovelDetailKeys.GET_CHANGE_CHAPTER_NUM, payload: num };
}