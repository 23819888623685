import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as Loadable from 'react-loadable';
import Main from '../../layout/PageLayout';
// import IncomePage from './view/setting/incomeSetting';
// import WithdrawPage from './view/setting/withdrawSetting';
// import WithdrawDetail from './view/setting/withdrawDetailSetting';
import SecondDirectory404Page from '../../layout/secondDirectory404Page';

const IncomeComponent = Loadable({
    loader: () => import('./view/setting/incomeSetting'),
    loading: () => <div />,
});

const WithdrawComponent = Loadable({
    loader: () => import('./view/setting/withdrawSetting'),
    loading: () => <div />,
});

const WithdrawDetailComponent = Loadable({
    loader: () => import('./view/setting/withdrawDetailSetting'),
    loading: () => <div />,
});

export const settlementRouter = (
    <Main>
        <Switch>
            <Route path="/settlement/income" component={IncomeComponent} />
            <Route path="/settlement/withdraw" component={WithdrawComponent} />
            <Route path="/settlement/withdrawDetail" component={WithdrawDetailComponent} />
            <Route component={SecondDirectory404Page} />
        </Switch>
    </Main>
);
