import CertificationKeys from './keys/certificationKeys';

export function initPage() {
    return { type: CertificationKeys.INIT_PAGE };
}

export function getCertificationInfo(param: any) {
    return { type: CertificationKeys.GET_CERTIFICATION_INFO, payload: param };
}

export function modifyCertificationInfo(param: any) {
    return { type: CertificationKeys.MODIFY_CERTIFICATION_INFO, payload: param };
}

export function notifyModalVisible(bol: boolean) {
    return { type: CertificationKeys.NOTIFY_MODAL_VISIBLE, payload: bol };
}

export function modifyCertificationError(bol: boolean, value?: string) {
    return { type: CertificationKeys.MODIFY_CERTIFICATION_INFO_ERROR, payload: {bol, value} };
}

export function phoneError(bol: boolean, value?: string) {
    return { type: CertificationKeys.PHONE_ERROR, payload: {bol, value} };
}

export function verificationCodeError(bol: boolean, value?: string) {
    return { type: CertificationKeys.VERIFICATION_CODE_ERROR, payload: {bol, value} };
}

export function emailError(bol: boolean, value?: string) {
    return { type: CertificationKeys.EMAIL_ERROR, payload: {bol, value} };
}

export function prompt(param: {bol: boolean, value?: string}) {
    return { type: CertificationKeys.PROMPT, payload: param };
}

export function saveButton(param: boolean) {
    return { type: CertificationKeys.SAVE_BUTTON, payload: param };
}

export function sendPhoneNumber(param: string) {
    return { type: CertificationKeys.SEND_PHONE_NUMBER, payload: param };
}

export function checkPhone(param: string) {
    return { type: CertificationKeys.CHECK_PHONE, payload: param };
}
