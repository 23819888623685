import * as React from 'react';
import { Link } from 'react-router-dom';
import '../less/less/noNetwork.less';
import { ossUrl } from '../i18n';

export default class ErrorPage extends React.Component<any, any> {
    render() {
        return (
           <div className="noNetwork">
                <div className="center">
                    <img src={`${ossUrl}404.png`} alt=""/>
                    <p>Mohon Maaf, Halaman tidak ditemukan</p>
                    <Link to="/novel/index" className="toIndex">Kembali</Link>
                </div>
           </div>
        );
    }
}
