import { fork, all, takeEvery, put, take, call } from 'redux-saga/effects';
import userInfoKeys from '../../component/user/action/keys/userInfoKeys';
import * as userInfoAction from '../../component/user/action/userInfoAction';
import * as userInfoServer from '../../server/userInfoServer';
import * as indexServer from '../../server/indexServer';
import { errorTip } from './common';
import * as Mean from '../../i18n';

function* initPage() {
    yield getPersonalData();
    yield getContactInfo();
}

function* getPersonalData() {
    try {
        const result = yield call(indexServer.getPersonalData);
        yield put(userInfoAction.getPersonData(result));
    } catch (err) {
        yield errorTip(Mean.NO_NETWORK, userInfoAction.prompt);
    }
}

function* getContactInfo() {
    try {
        const result = yield call(userInfoServer.getContactInfo);
        yield put(userInfoAction.getContactInfo(result));
    } catch (err) {
        console.log(err);
    }
}

function* watchCheckPhone() {
    while (true) {
        try {
            const { payload } = yield take(userInfoKeys.CHECK_PHONE);
            yield call(userInfoServer.checkPhoneAvailable, payload);
        } catch (err) {
            if (err.code === 304) {
                yield put(userInfoAction.verificationCodeError(true, 'Format no hp salah'));
            } else if (err.code === 309) {
                yield put(userInfoAction.phoneError(true, 'No. HP sudah pernah dipakai'));
            } else {
                yield errorTip(Mean.NO_NETWORK, userInfoAction.prompt);
            }
        }
    }
}

function* watchModifyContactInfo() {
    while (true) {
        try {
            const { payload } = yield take(userInfoKeys.SAVE_USER_INFO);
            yield put(userInfoAction.userInfoButton(true));
            yield call(userInfoServer.modifyContactInfo, payload);
            yield errorTip('Berhasil menyimpan', userInfoAction.prompt);
            yield getContactInfo();
            yield put(userInfoAction.userInfoButton(false));
        } catch (err) {
            yield put(userInfoAction.userInfoButton(false));
            if (err.code === 304) {
                yield put(userInfoAction.modifyContactInfoError(true, 'Format no hp salah'));
            } else if (err.code === 308) {
                yield put(userInfoAction.modifyContactInfoError(true, 'Kode OTP tidak cocok'));
            } else if (err.code === 309) {
                yield put(userInfoAction.modifyContactInfoError(true, 'Nomer ponsel sudah digunakan'));
            } else if (err.code === 310) {
                yield put(userInfoAction.emailError(true, 'Format email salah'));
            } else {
                yield errorTip(Mean.NO_NETWORK, userInfoAction.prompt);
            }
        }
    }
}

function* watchRequestVerificationCode() {
    while (true) {
        try {
            const { payload } = yield take(userInfoKeys.SEND_PHONE_NUMBER);
            yield call(userInfoServer.requestVerificationCode, payload);
        } catch (err) {
            if (err.code === 304) {
                yield put(userInfoAction.verificationCodeError(true, 'Format no hp salah'));
            } else if (err.code === 305) {
                yield put(userInfoAction.verificationCodeError(true, 'Terlalu banyak permintaan OTP'));
            } else if (err.code === 306) {
                yield put(userInfoAction.verificationCodeError(true, 'Limit kode hari ini, coba lagi besok'));
            } else if (err.code === 307) {
                yield put(userInfoAction.verificationCodeError(true, 'Kode OTP gagal dikirim'));
            } else {
                yield errorTip(Mean.NO_NETWORK, userInfoAction.prompt);
            }
        }
    }
}

function *watchBecomeAuthor() {
    while (true) {
        try {
            const { payload } = yield take(userInfoKeys.BECOME_AUHTOR);
            yield put(userInfoAction.becomeAuthorButton(true));
            yield call(userInfoServer.becomeAuthor, payload);
            yield put(userInfoAction.becomeAuthorButton(false));
            yield put(userInfoAction.prompt({bol: true, value: 'Selamat atas aplikasi Anda yang skukses.'}));
        } catch (err) {
            yield put(userInfoAction.becomeAuthorButton(false));
            if (err.code === 301) {
                yield put(userInfoAction.becomeAuthorError(true, 'Nama pena pernah ganti, tidak bisa ganti lagi'));
            } else if (err.code === 302) {
                yield put(userInfoAction.becomeAuthorError(true, 'Nama pena sudah dipakai'));
            } else if (err.code === 303) {
                yield put(userInfoAction.becomeAuthorError(true, 'Format nama pena salah'));
            } else if (err.code === 304) {
                yield put(userInfoAction.phoneError(true, 'Format no hp salah'));
            } else {
                yield errorTip(Mean.NO_NETWORK, userInfoAction.prompt);
            }
        }
    }
}

function* watchModifyPenName() {
    while (true) {
        try {
            const { payload } = yield take(userInfoKeys.MODIFY_PEN_NAME);
            yield put(userInfoAction.penNameButton(true));
            yield call(userInfoServer.modifyPenName, payload);
            yield getPersonalData();
            yield put(userInfoAction.penNameButton(false));
        } catch (err) {
            yield put(userInfoAction.penNameButton(false));
            if (err.code === 301) {
                yield put(userInfoAction.modifyPenNameError(true, 'Nama pena pernah ganti, tidak bisa ganti lagi'));
            } else if (err.code === 302) {
                yield put(userInfoAction.modifyPenNameError(true, 'Nama pena sudah dipakai'));
            } else if (err.code === 303) {
                yield put(userInfoAction.modifyPenNameError(true, 'Format nama pena salah'));
            } else {
                yield errorTip(Mean.NO_NETWORK, userInfoAction.prompt);
            }
        }
    }
}

function* watchInitPage() {
    yield takeEvery(userInfoKeys.INIT_PAGE, initPage);
}

export function* watchUserInfoSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchBecomeAuthor),
        fork(watchModifyPenName),
        fork(watchRequestVerificationCode),
        fork(watchModifyContactInfo),
        fork(watchCheckPhone),
    ]);
}
