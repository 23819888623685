import { combineReducers } from 'redux';

import { loginReducer } from '../layout/reducer/loginReducer';

import { indexReducer } from '../component/index/reducer/indexReducer';

import { workListReducer } from '../component/index/reducer/workListReducer';
import { createCorpusReducer } from '../component/index/reducer/createCorpusReducer';
import { chapterSortReducer } from '../component/index/reducer/chapterSortReducer';
import { editReducer } from '../component/edit/reducer/editReducer';
import { corpusDetailReducer } from '../component/index/reducer/novelDetailReducer';
import { articleReducer } from '../component/index/reducer/articleReducer';
import { uploadChapterReducer } from '../component/index/reducer/uploadChapterReducer';
import { starDetailReducer } from '../component/index/reducer/starDetailReducer';
import { rewardDetailReducer } from '../component/index/reducer/rewardDetailReducer';

import { userInfoReducer } from '../component/user/reducer/userInfoReducer';
import { certificationReducer } from '../component/user/reducer/certificationReducer';

import { incomeReducer } from '../component/settlement/reducer/incomeReducer';
import { withdrawReducer } from '../component/settlement/reducer/withdrawReducer';
import { withdrawDetailReducer } from '../component/settlement/reducer/withdrawDetailReducer';

export default combineReducers({
    loginReducer,
    indexReducer,
    workListReducer,
    createCorpusReducer,
    editReducer,
    corpusDetailReducer,
    articleReducer,
    uploadChapterReducer,
    starDetailReducer,
    chapterSortReducer,
    userInfoReducer,
    incomeReducer,
    withdrawReducer,
    withdrawDetailReducer,
    certificationReducer,
    rewardDetailReducer
});
