enum editKeys {
    INIT_PAGE = 'EDIT_PAGE_INIT_PAGE',
    GET_ALL_NOVEL_INFO_LIST = 'EDIT_PAGE_GET_ALL_NOVEL_INFO_LIST',
    GET_SENSITIVE_WORD_LIST = 'EDIT_PAGE_GET_SENSITIVE_WORD_LIST',
    VISIBLE_PROMPT = 'EDIT_PAGE_VISIBLE_PROMPT',
    SAVE_CHAPTER = 'EDIT_PAGE_SAVE_CHAPTER',
    AUTO_SAVE_CHAPTER_TOOLTIP_TEXT = 'EDIT_PAGE_AUTO_SAVE_CHAPTER_TOOLTIP_TEXT',
    GET_HAS_RELEASE_NOVEL_ID = 'EDIT_PAGE_GET_HAS_RELEASE_NOVEL_ID',
    GET_NOVEL_ID_FOR_URL = 'EDIT_PAGE_GET_NOVEL_ID_FOR_URL',
    GET_SAVE_CHAPTER_ID = 'EDIT_PAGE_GET_SAVE_CHAPTER_ID',
    EDIT_TO_LIST = 'EDIT_PAGE_EDIT_TO_LIST',
}

export default editKeys;
