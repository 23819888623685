import UploadChapterKeys from '../action/keys/uploadChapterKeys';
import { ActionTypes } from '../action/type/uploadChapterType';

interface State {
    novelList: any[];
}

const State: State = {
    novelList: []
};

export const uploadChapterReducer = (state = State, action: ActionTypes) => {
    switch (action.type) {
        case UploadChapterKeys.INIT_PAGE:
            return State;

        case UploadChapterKeys.GET_NOVEL_LIST:
            return Object.assign({}, state, {
                novelList: action.payload
            });
        default:
            return state;
    }
};