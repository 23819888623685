import { postJson } from '../utils/xhr';

/**
 * 获取奖金收入概况
 */
export function getIncomeInfo() {
    return postJson('/api/income/getIncomeInfo');
}

/**
 * 获取收入明细
 */
export function getIncomeRecordList(param: any) {
    return postJson('/api/income/getIncomeRecordList', param);
}

/**
 * 获取文集类型列表
 */
export function getNovelTypeList() {
    return postJson('/api/novel/getNovelTypeList');
}

/**
 * 获取我的所有文集信息
 */
export function getMyAllNovelInfo() {
    return postJson('/api/novel/getMyAllNovelInfoList');
}

// 提现界面

/**
 * 获取提现信息
 */
export function getWithdrawInfo() {
    return postJson('/api/withdraw/getWithdrawInfo');
}

/**
 * 获取提现手续费
 */
export function getServiceCharges() {
    return postJson('/api/withdraw/getServiceCharges');
}

/**
 * 获取提现信息
 */
export function getBankCardInfo() {
    return postJson('/api/withdraw/getBankCardInfo');
}

/**
 * 获取提现信息
 */
export function getBankList() {
    return postJson('/api/withdraw/getBankList');
}

/**
 * 获取提现信息
 */
export function getWithdrawList(param: any) {
    return postJson('/api/withdraw/getWithdrawList', param);
}

/**
 * 提现身份验证
 */
export function authentication(param: any) {
    return postJson('/api/withdraw/authentication', param);
}

/**
 * 修改银行卡信息
 */
export function modifyBankCard(param: any) {
    return postJson('/api/withdraw/modifyBankCard', param);
}

/**
 * 申请提现
 */
export function withdrawApply(param: any) {
    return postJson('/api/withdraw/withdrawApply', param);
}

/**
 * 获取税费列表
 */
export function withdrawDetailForApply() {
    return postJson('/api/withdraw/withdrawDetailForApply');
}

/**
 * 获取提现订单详情
 */
export function getOrderMsg(id: number) {
    return postJson('/api/withdraw/withdrawRecordById', id);
}