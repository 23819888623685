import { fork, all, takeEvery, put, select, call } from 'redux-saga/effects';
import IncomeKeys from '../../component/settlement/action/keys/incomeKeys';
import * as incomeAction from '../../component/settlement/action/incomeAction';
import * as settlementServer from '../../server/settlementServer';

function* initPage() {
    yield getIncomeInfo();
}

function* getIncomeInfo() {
    try {
        const result = yield call(settlementServer.getIncomeInfo);
        yield put(incomeAction.getIncomeInfo(result));
        yield getIncomeRecordList();
        yield getMyAllNovelInfo();
    } catch (err) {
        console.log(err);
    }
}

function* getIncomeRecordList() {
    try {
        const state = yield select();
        const data = state.incomeReducer;
        const param = {
            ...data.page,
            data: data.searchData.startMonth
        };
        const result = yield call(settlementServer.getIncomeRecordList, param);
        yield put(incomeAction.getIncomeRecordList({dataList: result.data, total: result.total}));
    } catch (err) {
        console.log(err);
    }
}

function* getMyAllNovelInfo() {
    try {
        const result = yield call(settlementServer.getMyAllNovelInfo);
        yield put(incomeAction.getSectionList(result));
    } catch (err) {
        console.log(err);
    }
}

function* watchInitPage() {
    yield takeEvery(IncomeKeys.INIT_PAGE, initPage);
}

function* watchSearchData() {
    yield takeEvery(IncomeKeys.INCOME_RECORD_LIST_SEARCH, getIncomeRecordList);
}

function* watchChangePageSize() {
    yield takeEvery(IncomeKeys.CHANGE_CHAPTER, getIncomeRecordList);
}

export function* watchIncomeInfoSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchSearchData),
        fork(watchChangePageSize)
    ]);
}
