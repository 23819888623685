enum articleKeys  {
    INIT_PAGE = 'ARTICLE_INIT_PAGE',
    GET_CHAPTER_ID = 'ARTICLE_CHAPTER_ID',
    CHAPTER_NAME = 'ARTICLE_CHAPTER_NAME',
    CHAPTER_CON = 'ARTICLE_CHAPTER_CON',
    GET_CHAPTER_MSG = 'ARTICLE_CHAPTER_MSG',
    SAVE_CHAPTER= 'ARTICLE_SAVE_CHAPTER',
    RELEASE_CHAPTER = 'ARTICLE_RELEASE_CHAPTER',
    TO_LISt = 'ARTICLE_TO_LIST',
    OPEN_PROMPT_MODAL = 'ARTICLE_OPEN_PROMPT_MODAL',
    GET_PROMPT_TEXT = 'ARTICLE_GET_PROMPT_TEXT',
    AUTO_SAVE_TEXT_SHOW = 'AUTO_SAVE_TEXT_SHOW',
    GET_SENSITIVEWORD_LIST = 'ARTICLE_GET_SENSITIVEWORD_LIST',
    CHANGE_CONTENT = 'ARTICLE_CHANGE_CONTENT',
    GET_DRAFT_CONTENT = 'ARTICLE_GET_DRAFT_CONTENT',
    IS_DRAFT_BTN = 'ARTICLE_IS_DRAFT_BTN',
    GET_DRAFT_NAME = 'ARTICLE_GET_DRAFT_NAME',
}

export default articleKeys;
