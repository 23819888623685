import workListKeys from './keys/workListKeys';

export function initPage() {
    return { type: workListKeys.INIT_PAGE };
}

export function getWorkList(list: any[]) {
    return { type: workListKeys.GET_CORPUS_LIST, payload: list };
}

export function getCorpusStatus(status: any) {
    return { type: workListKeys.GET_CORPUS_STATUS, payload: status };
}

export function updatePageSize(page: any, pageSize: any) {
    return { type: workListKeys.CHANGE_PAGE_SIZE, payload: { page, pageSize} };
}

export function changePageSizeAndStatus(page: any, pageSize: any) {
    return { type: workListKeys.CHANGE_PAGE_SIZE_AND_STATUS, payload: { page, pageSize} };
}

export function deleteNovelDataList(id: string) {
    return { type: workListKeys.DEL_CORPUS, payload: id };
}

export function visiblePrompt(param: {bol: boolean, value?: string }) {
    return { type: workListKeys.VISIBLE_PROMPT, payload: param };
}

export function loading(value: boolean) {
    return { type: workListKeys.LOADING, payload: value };
}

export function workListNoData(value: boolean) {
    return { type: workListKeys.NO_DATA, payload: value };
}

export function inCompetition(value: any) {
    return { type: workListKeys.IN_COMPETITION, payload: value };
}

export function getCorpusDetail(value: any) {
    return { type: workListKeys.GET_CORPUS_DETAIL, payload: value };
}

export function getCorpusDetailById(id: number) {
    return { type: workListKeys.GET_CORPUS_DETAIL_BY_ID, payload: id };
}

export function getPaidNovel(novel: any) {
    return { type: workListKeys.GET_PAID_NOVEL, payload: novel };
}

export function paidNovelModal(param: any) {
    return { type: workListKeys.PAID_NOVEL_MODAL, payload: param };
}
