import articleKeys from './keys/articleKeys';

export function initPage() {
   return { type: articleKeys.INIT_PAGE };
}

export function getChapterId(param: any) {
    return { type: articleKeys.GET_CHAPTER_ID, payload: param };
}

export function getChapterName(title: string) {
    return { type: articleKeys.CHAPTER_NAME, payload: title };
}

export function getChapterCon(con: string) {
    return { type: articleKeys.CHAPTER_CON, payload: con };
}

export function getChapterMsg(msg: any) {
    return { type: articleKeys.GET_CHAPTER_MSG, payload: msg };
}

export function saveChapterMsg(type: string) {
    return { type: articleKeys.SAVE_CHAPTER, payload: type};
}

export function releaseChapter(isModify?: string) {
    return { type: articleKeys.RELEASE_CHAPTER, payload: isModify };
}

export function toList(value: boolean) {
    return { type: articleKeys.TO_LISt, payload: value };
}

export function openPrompt(value: boolean) {
    return { type: articleKeys.OPEN_PROMPT_MODAL, payload: value };
}

export function getPromptText(value: string) {
    return { type: articleKeys.GET_PROMPT_TEXT, payload: value };
}

export function autoSaveTextShow(value: boolean) {
    return { type: articleKeys.AUTO_SAVE_TEXT_SHOW, payload: value };
}

export function getSensitiveWord(value: boolean) {
    return { type: articleKeys.GET_SENSITIVEWORD_LIST, payload: value };
}

export function getChangeContentUrl(value: boolean) {
    return { type: articleKeys.CHANGE_CONTENT, payload: value};
}

export function getDraftContent(value: string) {
    return { type: articleKeys.GET_DRAFT_CONTENT, payload: value };
}

export function isDraftBtn() {
    return { type: articleKeys.IS_DRAFT_BTN };
}

export function getDraftName(value: string) {
    return { type: articleKeys.GET_DRAFT_NAME, payload: value };
}