enum NovelDetailKeys {
    GET_NOVEL_DETAIL_BY_ID = 'NOVEL_DETAIL_GET_NOVEL_DETAIL_BY_ID',
    NOVEL_DETAIL_INFO = 'NOVEL_DETAIL_NOVEL_DETAIL_INFO',
    PROMPT = 'NOVEL_DETAIL_PROMPT',
    TASK = 'NOVEL_DETAIL_TASK',
    TASKSTAR = 'NOVEL_DETAIL_TASKSATAR',
    RESTORE_CHAPTER = 'NOVEL_DETAIL_RESTORE_CHAPTER',
    SHOW_CHAPTER = 'NOVEL_DETAIL_SHOW_CHAPTER',
    RELEASE_CHAPTER = 'NOVEL_DETAIL_RELEASE_CHAPTER',
    GET_PERSONAL_DATA = 'NOVEL_DETAIL_GET_PERSONAL_DATA',
    RELEASE_MODAL = 'NOVEL_DETAIL_RELEASE_MODAL',
    UNVERIFIED_MODAL = 'NOVEL_DETAIL_UNVERIFIED_MODAL',
    CERTIFICATION_MODAL = 'NOVEL_DETAIL_CERTIFICATION_MODAL',
    COMPETITION_MODAL = 'NOVEL_DETAIL_COMPETITION_MODAL',
    COMPETITION_OK = 'NOVEL_DETAIL_COMPETITION_OK',
    INIT_UPDATE_PAGE = 'NOVEL_DETAIL_INIT_UPDATE_PAGE',
    GET_NOVEL_ID = 'NOVEL_DETAIL_NOVEL_ID',
    GET_DIFF_STATUS_CHAPTER_NUM = 'NOVEL_DETAILGET_DIFF_STATUS_CHAPTER_NUM',
    GET_CHANGE_CHAPTER_NUM = 'NOVEL_DETAIL_GET_CHANGE_CHAPTER_NUM',
    RECYCLE_LIST_CHANGE_DATE = 'NOVEL_DETAIL_RECYCLE_LIST_CHANGE_DATE',

    INIT_PAGE = 'CHAPTER_LIST_INIT_PAGE',
    GET_CORPUS_ID = 'GET_CORPUS_ID',
    GET_RECYCLE_ID = 'GET_RECYCLE_ID',
    GET_RECYCLE_LIST = 'GET_RECYCLE_LIST',
    GET_CHAPTER_LIST = 'GET_CHAPTER_LIST',
    UPDATE_PAGE = 'CHAPTER_UPDATE_PAGE',
    DEL_CHAPTER = 'DEL_CHAPTER',
    LOADING = 'CHAPTER_LIST_LOADING',
    CHANGE_CHAPTER = 'CHANGE_CHAPTER',
    GET_SEARCH_START_NUM = 'GET_SEARCH_START_NUM',
    GET_SEARCH_END_NUM = 'GET_SEARCH_END_NUM'
}

export default NovelDetailKeys;
