import loginKeys from './keys/loginKeys';

export function getFaceBookLogin(param: any) {
    return { type: loginKeys.LOGIN_FACEBOOK, payload: param };
}

export function getGoogleLogin(param: any) {
    return { type: loginKeys.LOGIN_GOOGLE, payload: param };
}

export function isToIndex(value: boolean) {
    return { type: loginKeys.LOGIN_TO_INDEX, payload: value };
}