import * as React from 'react';
import '../less/less/noNetwork.less';
import baseImg from '../less/images/noNetwork.png';

export default class OfflinePage extends React.Component<any, any> {

    errorImg = (e: any) => {
      e.currentTarget.src = baseImg;
      e.onerror = null;
    }

    render() {
        return (
           <div className="noNetwork">
                <div className="center">
                    <img src={baseImg} onError={(e: any) => {this.errorImg(e); }} alt=""/>
                    <p>Sambungan gagal, silakan coba lagi</p>
                </div>
           </div>
        );
    }
}
