import NovelDetailKeys from '../action/keys/novelDetailKeys';
import { ActionType } from '../action/type/novelDetailType';

interface Page {
    current: number;
    pageSize: number;
}

interface State {
    novelDetailInfo: any;
    personalData: any;
    chapterList: any[];
    total: number;
    page: Page;
    chapterReqMsg: any;
    loading: boolean;
    promptModal: boolean;
    promptText: string;
    taskModal: boolean;
    taskStar: number;
    recycleList: any[];
    getRecycleMsg: any;
    searchStartNum: string;
    searchEndNum: string;
    releaseModal: boolean;
    certificationModal: boolean;
    competitionModal: boolean;
    unverifiedModal: boolean;
    chapterNumByDiffStatus: any[];
    changeChapterNum: number | string;
}

const State: State = {
    novelDetailInfo: {},
    personalData: {},
    chapterList: [],
    chapterReqMsg: {},
    total: 0,
    page: {
        current: 1,
        pageSize: 20
    },
    loading: true,
    promptModal: false,
    promptText: '',
    taskModal: false,
    taskStar: 0,
    recycleList: [],
    getRecycleMsg: {},
    searchStartNum: '',
    searchEndNum: '',
    releaseModal: false,
    certificationModal: false,
    competitionModal: false,
    unverifiedModal: false,
    chapterNumByDiffStatus: [],
    changeChapterNum: 0
};

export const corpusDetailReducer = (state = State, action: ActionType) => {
    switch (action.type) {

        case NovelDetailKeys.INIT_PAGE:
            return State;

        case NovelDetailKeys.NOVEL_DETAIL_INFO:
            return Object.assign({}, state, {
                novelDetailInfo: action.payload
            });

        case NovelDetailKeys.GET_PERSONAL_DATA:
            return Object.assign({}, state, {
                personalData: action.payload
            });

        case NovelDetailKeys.RELEASE_MODAL:
            return Object.assign({}, state, {
                releaseModal: action.payload
            });

        case NovelDetailKeys.CERTIFICATION_MODAL:
            return Object.assign({}, state, {
                certificationModal: action.payload
            });

        case NovelDetailKeys.COMPETITION_MODAL:
            return Object.assign({}, state, {
                competitionModal: action.payload
            });

        case NovelDetailKeys.UNVERIFIED_MODAL:
            return Object.assign({}, state, {
                unverifiedModal: action.payload
            });

        case NovelDetailKeys.PROMPT:
            return Object.assign({}, state, {
                promptModal: action.payload.bol,
                promptText: action.payload.value
            });

        case NovelDetailKeys.TASK:
            return Object.assign({}, state, {
                taskModal: action.payload
            });

        case NovelDetailKeys.TASKSTAR:
            return Object.assign({}, state, {
               taskStar: action.payload
            });

        case NovelDetailKeys.GET_CHAPTER_LIST:
            return Object.assign({}, state, {
                chapterList: action.payload.data,
                total: action.payload.total
            });

        case NovelDetailKeys.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.page,
                    pageSize: action.payload.pageSize
                }
            });

        case NovelDetailKeys.INIT_UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.page,
                    pageSize: action.payload.pageSize
                }
            });

        case NovelDetailKeys.RECYCLE_LIST_CHANGE_DATE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.page,
                    pageSize: action.payload.pageSize
                }
            });

        case NovelDetailKeys.GET_CORPUS_ID:
            return Object.assign({}, state, {
               chapterReqMsg: action.payload
            });

        case NovelDetailKeys.LOADING:
            return Object.assign({}, state, {
                loading: action.payload
            });

        case NovelDetailKeys.GET_RECYCLE_LIST:
            return Object.assign({}, state, {
                recycleList: action.payload.data,
                total: action.payload.total
            });

        case NovelDetailKeys.GET_RECYCLE_ID:
            return Object.assign({}, state, {
                getRecycleMsg: action.payload
            });

        case NovelDetailKeys.GET_SEARCH_START_NUM:
            return Object.assign({}, state, {
                searchStartNum: action.payload
            });

        case NovelDetailKeys.GET_SEARCH_END_NUM:
            return Object.assign({}, state, {
                searchEndNum: action.payload
            });

        case NovelDetailKeys.GET_DIFF_STATUS_CHAPTER_NUM:
            return Object.assign({}, state, {
                chapterNumByDiffStatus: action.payload
            });

        case NovelDetailKeys.GET_CHANGE_CHAPTER_NUM:
            return Object.assign({}, state, {
                changeChapterNum: action.payload
            });

        default:
            return state;
    }
};
