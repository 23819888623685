import * as React from 'react';

export default class AuthorPactPage extends React.Component<any, any> {

    render() {

        return (
            <section className="index_notice notice_list">
                <div className="notice_detail">
                    <h1>Perjanjian Layanan</h1>
                    <p>The website located at tulis.novelme.id (the “Site”) is a copyrighted work belonging to PT. Funsoft Mobindo Indonesia. (“Novel Platform”, “us”, “our”, and “we”). Novel Platform provides a social networking site that allows registered users to create profiles, store content, and connect with other users (collectively, with all other services provided through the Site, the “Services”). Certain features of the Services or Site may be subject to additional guidelines, terms, or rules, which will be posted on the Service or Site in connection with such features. All such additional terms, guidelines, and rules are incorporated by reference into this Agreement.</p>
                    <p>THESE TERMS OF USE (“AGREEMENT”) SETS FORTH THE LEGALLY BINDING TERMS FOR YOUR USE OF THE SITE AND SERVICES. BY ACCESSING OR USING THE SITE OR SERVICES, YOU ARE ACCEPTING THIS AGREEMENT AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THIS AGREEMENT. IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THIS AGREEMENT, DO NOT ACCESS AND/OR USE THE SITE OR SERVICES. IF YOU ARE USING THE SITE OR SERVICES ON BEHALF OF A COMPANY, ENTITY, OR ORGANIZATION, YOU REPRESENT AND WARRANT THAT YOU ARE AN AUTHORIZED REPRESENTATIVE OF SUCH COMPANY, ENTITY, OR ORGANIZATION WITH THE AUTHORITY TO BIND IT TO THIS AGREEMENT.</p>
                    <h5>1. Accounts</h5>
                    <p>1.1. Accounts Creation. In order to use certain features of the Site (e.g., to use the Services), you must register for an account with Novel Platform (“Novel Platform Account”) and provide certain information about yourself as prompted by the registration form. You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information. You may delete your Novel Platform Account at any time, for any reason. Novel Platform may suspend or terminate your Novel Platform Account in accordance with Section 10.</p>
                    <p>1.2. Accounts Responsibilities. You are responsible for maintaining the confidentiality of your Novel Platform Account login information and are fully responsible for all activities that occur under your Novel Platform Account. You agree to immediately notify Novel Platform of any unauthorized use, or suspected unauthorized use of your Novel Platform Account or any other breach of security. Novel Platform cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.</p>
                    <p>1.3. Social Networking Services. We may permit you to associate your Novel Platform Account with your login credentials from certain social networking sites (e.g., Facebook) (“SNS”). If you associate your Novel Platform Account with your login credentials from a SNS, we may receive information about you from such SNS, in accordance with the terms and conditions (e.g., terms of use and privacy policy) of the SNS (“SNS Terms”). If you elect to share your information with these SNS, we will share information with them in accordance with your election. The SNS Terms of these SNS will apply to the information we disclose to them.</p>
                    <h5>2. Payment Terms</h5>
                    <p>2.1. Paid Services. Certain of our Services may be subject to payments now and in the future (the “Paid Services”). Please see our relevant page on our Site for a description of the current Paid Services. Please note that any payment terms presented to you in the process of using or signing up for a Paid Service are deemed part of this Agreement.</p>
                    <p>2.2. Billing. We use a third-party payment processor (the “Payment Processor”) to bill you through a payment account linked to your Account on the Services (your “Billing Account”) for use of the Paid Services. The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in addition to this Agreement. We are not responsible for errors by the Payment Processor. By choosing to use Paid Services, you agree to pay us, through the Payment Processor, all charges at the prices then in effect for any use of such Paid Services in accordance with the applicable payment terms and you authorize us, through the Payment Processor, to charge your chosen payment provider (your “Payment Method”). You agree to make payment using that selected Payment Method. We reserve the right to correct any errors or mistakes that it makes even if it has already requested or received payment.</p>
                    <p>2.3. Payment Method. The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer or other provider of your chosen Payment Method. If we, through the Payment Processor, do not receive payment from you, you agree to pay all amounts due on your Billing Account upon demand.</p>
                    <p>2.4. Current Information Required. YOU MUST PROVIDE CURRENT, COMPLETE AND ACCURATE INFORMATION FOR YOUR BILLING ACCOUNT. YOU MUST PROMPTLY UPDATE ALL INFORMATION TO KEEP YOUR BILLING ACCOUNT CURRENT, COMPLETE AND ACCURATE (SUCH AS A CHANGE IN BILLING ADDRESS, CREDIT CARD NUMBER, OR CREDIT CARD EXPIRATION DATE).</p>
                    <p>2.5. Termination. We may terminate your access to all or any part of the Services at any time, with or without cause, with or without notice, effective immediately, which may result in the forfeiture and destruction of all information associated with your membership. If you wish to terminate your Account, you may do so by following the instructions on the Site or through the Services. Any fees paid hereunder are non-refundable. All provisions of these Terms of Service which by their nature should survive termination shall survive termination, including, without limitation, licenses of User Content, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
                    <h5>3. Site and Mobile App</h5>
                    <p>3.1. License. Subject to the terms of this Agreement, Novel Platform grants you a non-transferable, non-exclusive, license to use the Site and Services for your personal, noncommercial use. Subject to the terms of this Agreement, Novel Platform grants you a non-transferable, non-exclusive, license to install and use the software Novel Platform makes available for mobile devices (“Mobile App”), in executable object code format only, solely on your own handheld mobile device and for your personal, noncommercial use. As used in this Agreement, the term “Services” includes the Mobile App.</p>
                    <p>3.2. Certain Restrictions. The rights granted to you in this Agreement are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site or Services; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site or Services; (c) you shall not access the Site or Services in order to build a similar or competitive service; and (d) except as expressly stated herein, no part of the Site or Services may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. Any future release, update, or other addition to functionality of the Site or Services shall be subject to the terms of this Agreement. All copyright and other proprietary notices on any Site or Services content must be retained on all copies thereof.</p>
                    <p>3.3. Modification. Novel Platform reserves the right, at any time, to modify, suspend, or discontinue the Site or Services or any part thereof with or without notice. You agree that Novel Platform will not be liable to you or to any third party for any modification, suspension, or discontinuance of the Site or Services or any part thereof.</p>
                    <p>3.4. Ownership. Excluding your User Content (defined below), you acknowledge that all the intellectual property rights, including copyrights, patents, trade marks, and trade secrets, in the Site and Services are owned by Novel Platform or Novel Platform’s licensors. The provision of the Site and Services does not transfer to you or any third party any rights, title or interest in or to such intellectual property rights. Novel Platform and its suppliers reserve all rights not granted in this Agreement.</p>
                    <p>3.5. App Platforms. You acknowledge and agree that the availability of the App is dependent on the third party app platform from which you received the Mobile App, e.g., the Apple iPhone store (“App Platform”). You acknowledge that this Agreement is between you and Novel Platform and not with the App Platform. Novel Platform, not the App Platform, is solely responsible for the Mobile App, the content thereof, maintenance, support services, and warranty therefor, and addressing any claims relating thereto (e.g., product liability, legal compliance, or intellectual property infringement). You agree to pay all fees charged by the App Platform in connection with the Mobile App. Each App Platform may have its own terms and conditions to which you must agree before downloading the Mobile App from it. You agree to comply with, and your license to use the Mobile App is conditioned upon your compliance with, all applicable agreements, terms and conditions of use/service, and other policies of the applicable App Platform. You acknowledge that the App Platform (and its subsidiaries) is a third party beneficiary of this Agreement and will have the right to enforce this Agreement.</p>
                    <h5>4. User Content</h5>
                    <p>4.1. User Content. “User Content” means any and all information and content that a user submits to, or uses with, the Site or Services (e.g., content in the user’s profile or postings or content stored in your Stash). You are solely responsible for your User Content. You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that makes you or any third party personally identifiable. You hereby represent and warrant that your User Content does not violate the Acceptable Use Policy (defined below). You may not state or imply that your User Content is in any way provided, sponsored or endorsed by Novel Platform. Because you alone are responsible for your User Content (and not Novel Platform), you may expose yourself to liability if, for example, your User Content violates the Acceptable Use Policy. Novel Platform is not obligated to backup any User Content and User Content may be deleted at anytime. You are solely responsible for creating backup copies of your User Content if you desire.</p>
                    <p>4.2. License. You hereby grant, and you represent and warrant that you have the right to grant, to Novel Platform an irrevocable, nonexclusive, royalty-free and fully paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use your User Content, and to grant sublicenses of the foregoing, solely for the purposes of including your User Content in the Site and Services. You agree to irrevocably waive (and cause to be waived) any claims and assertions of moral rights or attribution with respect to your User Content.</p>
                    <p>4.3. Feedback. If you provide Novel Platform any feedback or suggestions regarding the Site or Services (“Feedback”), you hereby assign to Novel Platform all rights in the Feedback and agree that Novel Platform shall have the right to use such Feedback and related information in any manner it deems appropriate. Novel Platform will treat any Feedback you provide to Novel Platform as non-confidential and non-proprietary. You agree that you will not submit to Novel Platform any information or ideas that you consider to be confidential or proprietary.</p>
                    <h5>5. Acceptable Use Policy</h5>
                    <p>The following sets forth Novel Platform’s “Acceptable Use Policy”:</p>
                    <p>5.1. You agree not to use the Site or Services to collect, upload, transmit, display, or distribute any User Content (a) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (b) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive (e.g., material that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual) or otherwise objectionable material of any kind or nature or which is harmful to minors in any way; or (c) in violation of any law, regulation, or obligations or restrictions imposed by any third party.</p>
                    <p>5.2. In addition, you agree not to use the Site or Services to: (a) upload, transmit, or distribute any computer viruses, worms, or any software intended to damage or alter a computer system or data; (b) send unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (c) harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent; (d) interfere with, disrupt, or create an undue burden on servers or networks connected to the Site or Services or violate the regulations, policies or procedures of such networks; (e) attempt to gain unauthorized access to the Site or Services, other computer systems or networks connected to or used together with the Site or Services, through password mining or other means; (f) harass or interfere with another user’s use and enjoyment of the Site or Services; or (g) introduce software or automated agents or scripts to the Site or Services so as to produce multiple accounts, generate automated searches, requests and queries, or to strip, scrape, or mine data from the Site or Services.</p>
                    <p>5.3. We reserve the right (but have no obligation) to review any User Content, investigate, and/or take appropriate action against you in our sole discretion (including removing or modifying your User Content, terminating your Novel Platform Account in accordance with Section 10, and/or reporting you to law enforcement authorities) if you violate the Acceptable Use Policy or any other provision of this Agreement or otherwise create liability for us or any other person.</p>
                    <h5>6.Indemnity</h5>
                    <p>You agree to indemnify and hold Novel Platform (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (i) your use of the Site or Services, (ii) your User Content, (iii) your violation of this Agreement; or (iv) your violation of applicable laws or regulations. Novel Platform reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Novel Platform. Novel Platform will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>
                    <h5>7. Third Party Sites; Other Users</h5>
                    <p>7.1. Third Party Sites. The Site might contain links to third party websites, services, and advertisements for third parties (collectively, “Third Party Sites”). Such Third Party Sites are not under the control of Novel Platform and Novel Platform is not responsible for any Third Party Sites. Novel Platform provides these Third Party Sites only as a convenience and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third Party Sites. You use all Third Party Sites at your own risk. When you link to a Third Party Site, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices. You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third Party Sites.</p>
                    <p>7.2. Other Users. Each Site user is solely responsible for any and all of its User Content. Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content and we make no guarantees regarding the accuracy, currency, suitability, or quality of any User Content, and we assume no responsibility for any User Content. Your interactions with other Site or Service users are solely between you and such user. You agree that Novel Platform will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any Site or Service user, we are under no obligation to become involved.</p>
                    <p>7.3. Release. You hereby release and forever discharge us (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or relates directly or indirectly to, any interactions with, or act or omission of, other Site or Service users or Third Party Sites.</p>
                    <h5>8. Disclaimers</h5>
                    <p>8.1. THE SITE AND SERVICES ARE PROVIDED “AS-IS” AND “AS AVAILABLE” AND WE (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING THE WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SITE OR SERVICES: (A) WILL MEET YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (C) WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE.</p>
                    <h5>9. Limitation on Liability</h5>
                    <p>9.1. IN NO EVENT SHALL WE (AND OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THIS AGREEMENT OR YOUR USE OF, OR INABILITY TO USE, THE SITE OR SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITE AND SERVICES ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR (AND OUR SUPPLIERS’) LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO THE AMOUNTS YOU’VE PAID NOVEL PLATFORM IN THE PRIOR 12 MONTHS (IF ANY). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.</p>
                    <h5>10. Term and Termination</h5>
                    <p>10.1. Subject to this Section, this Agreement will remain in full force and effect while you use the Site or Services. We may (a) suspend your rights to use the Site and/or Services (including your Novel Platform Account) or (b) terminate this Agreement, at any time for any reason at our sole discretion, including for any use of the Site or Services in violation of this Agreement. Upon termination of this Agreement, your Novel Platform Account and right to access and use the Site and Services will terminate immediately. You understand that any termination of your Novel Platform Account may involve deletion of your User Content associated therewith from our live databases. Novel Platform will not have any liability whatsoever to you for any termination of this Agreement, including for termination of your Novel Platform Account or deletion of your User Content. Even after this Agreement is terminated, the following provisions of this Agreement will remain in effect: Sections 3.2-3.5, 4–13.</p>
                    <h5>11. Copyright Policy</h5>
                    <p>11.1. Novel Platform respects the intellectual property of others and asks that users of our Site and Services do the same. In connection with our Site and Services, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination, in appropriate circumstances, of users of our online Site and Services who are repeat infringers of intellectual property rights, including copyrights. If you believe that one of our users is, through the use of our Site and Services, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification must be provided to our designated Copyright Agent:</p>
                    <p>1. your physical or electronic signature; 2.identification of the copyrighted work(s) that you claim to have been infringed; 3. identification of the material on our services that you claim is infringing and that you request us to remove; 4. sufficient information to permit us to locate such material; 5. your address, telephone number, and e-mail address; 6. a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and 7. a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</p>
                    <p>Please note that, any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement. The designated Copyright Agent for Novel Platform is:</p>
                    <p>Email:nexttopwriter@novelmeid.com</p>
                    <h5>12. General</h5>
                    <p>12.1. No Support or Maintenance. You acknowledge and agree that Novel Platform will have no obligation to provide you with any support or maintenance in connection with the Site or Services.</p>
                    <p>12.2. Changes to Terms of Use. This Agreement is subject to occasional revision, and if we make any substantial changes, we may notify you by prominently posting notice of the changes on our Site. Any changes to this agreement will be effective immediately for any users of our Site or Services. Continued use of our Site or Services following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</p>
                    <p>12.3. ARBITRATION CLAUSE & CLASS ACTION WAIVER – IMPORTANT – PLEASE REVIEW AS THIS AFFECTS YOUR LEGAL RIGHTS. All disputes between you and Novel Platform arising out of, relating to or in connection with the Site or Service or this Agreement shall be settled in an amiable way firstly. Should the parties reach no agreement, then the case shall be exclusively settled through binding arbitration pursuant to the then-current rules of the Singapore International Arbitration Centre (SIAC), for commercial arbitration. There is no judge or jury in arbitration. Arbitration procedures are simpler and more limited than rules applicable in court and review by a court is limited. YOU AND NOVEL PLATFORM AGREE THAT ANY SUCH ARBITRATION SHALL BE CONDUCTED ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION. Novel Platform.</p>
                    <p>12.4. Entire Agreement. This Agreement constitutes the entire agreement between you and us regarding the use of the Site and Services. Our failure to exercise or enforce any right or provision of this Agreement shall not operate as a waiver of such right or provision. The section titles in this Agreement are for convenience only and have no legal or contractual effect. The word including means including without limitation. If any provision of this Agreement is, for any reason, held to be invalid or unenforceable, the other provisions of this Agreement will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. Your relationship to Novel Platform is that of an independent contractor, and neither party is an agent or partner of the other. This Agreement, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Novel Platform’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. The terms of this Agreement shall be binding upon assignees.</p>
                    <p>12.5. Copyright/Trademark Information. tulis.novelme.id and included content (and any derivative works or enhancements of the same) including, but not limited to, all text, illustrations, files, images, software, scripts, graphics, photos, sounds, music, videos, information, content, materials, products, services, URLs, technology, documentation, and interactive features (collectively, the “Website Content”) and all intellectual property rights to the same are owned by us, our licensors, or both. Additionally, all trademarks, service marks, trade names and trade dress that may appear on our Site are owned by us, our licensors, or both. You shall not acquire any right, title or interest in our Site or any Website Content. Any rights not expressly granted in these Terms of Use are expressly reserved. You are not permitted to use the Site and Website Content without our prior written consent or the consent of such third party which may own the Site and Website Content.</p>
                    <p>12.6. Contact Information. You may contact us with any feedback, questions, comments, or complaints at:</p>
                    <p>PT. Funsoft Mobindo Indonesia</p>
                    <p>Email: nexttopwriter@novelmeid.com</p>
                </div>
            </section>
        );
    }
}
