import IndexKeys from '../action/keys/indexKeys';
import { ActionType } from '../action/type/indexType';

interface Page {
    current: number;
    pageSize: number;
}

export interface StoreState {
    personalData: any;
    noticeList: any[];
    noticePageList: any;
    bannerList: any[];
    noticeDetail: any;
    userNotificationList: any;
    page: Page;
    total: number;
    totalPage: number;
    openPromptModal: boolean;
    penNameModalVisible: boolean;
    failAuthModalVisible: boolean;
    aggrementModalVisible: boolean;
    authorRegisterModalVisible: boolean;
}

const initialState: StoreState = {
    personalData: {
        guideComplete: true
    },
    noticeList: [],
    noticePageList: {},
    bannerList: [],
    noticeDetail: {},
    userNotificationList: {},
    page: {
        current: 1,
        pageSize: 20
    },
    total: 0,
    totalPage: 0,
    openPromptModal: false,
    penNameModalVisible: false,
    failAuthModalVisible: false,
    aggrementModalVisible: false,
    authorRegisterModalVisible: false,
};

export const indexReducer = (state = initialState, action: ActionType ) =>  {
    switch (action.type) {

        case IndexKeys.GET_PERSONAL_DATE:
            return Object.assign({}, state, {
                personalData: action.payload
            });

        case IndexKeys.GET_NOTICE_LIST:
            return Object.assign({}, state, {
                noticeList: action.payload.data,
                total: action.payload.total
            });

        case IndexKeys.GET_NOTICE_PAGE_LIST:
            return Object.assign({}, state, {
                noticePageList: action.payload,
            });

        case IndexKeys.GET_USER_NOTIFICATION_LIST:
            return Object.assign({}, state, {
                userNotificationList: action.payload,
            });

        case IndexKeys.GET_NOTICE_DETAIL:
            return Object.assign({}, state, {
               noticeDetail: action.payload
            });

        case IndexKeys.UPDATE_PAGE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.page,
                    pageSize: action.payload.pageSize
                }
            });

        case IndexKeys.OPEN_PROMPT_MODAL:
            return Object.assign({}, state, {
                openPromptModal: action.payload
            });

        case IndexKeys.GET_BANNER_DATA_LIST:
            return Object.assign({}, state, {
                bannerList: action.payload
            });

        case IndexKeys.OPEN_PEN_NAME_MODAL:
            return Object.assign({}, state, {
                penNameModalVisible: action.payload
            });

        case IndexKeys.OPEN_FAIL_AUTH_MODAL:
            return Object.assign({}, state, {
                failAuthModalVisible: action.payload
            });

        case IndexKeys.OPEN_AGGREMENT_MODAL:
            return Object.assign({}, state, {
                aggrementModalVisible: action.payload
            });
        
        case IndexKeys.OPEN_AUTHOR_REGISTER_MODAL:
            return Object.assign({}, state, {
                authorRegisterModalVisible: action.payload
            })

        default:
            return state;
    }
};
