import { fork, all, call, takeEvery, put, take, select } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import editKeys from '../../component/edit/action/keys/editKeys';
import * as editAction from '../../component/edit/action/editAction';
import * as editServer from '../../server/editServer';
// import { uploadFile } from '../../server/ossServer';
import { errorTip } from './common';
import * as Mean from '../../i18n/index';

function* initPage() {
    yield getMyAllNovelInfoList();
}

function* getSensitiveWordList() {
    try {
       const result = yield call(editServer.getSensitiveWordList);
       yield put(editAction.getSensitiveWordList(result));
    } catch (err) {
        yield errorTip(Mean.NO_NETWORK, editAction.visiblePrompt);
    }
}

function* getMyAllNovelInfoList() {
    try {
        let param = {
            completedStatus: 0
        };
        const result = yield call(editServer.getMyAllNovelInfoList, param);
        yield put(editAction.getAllNovelList(result));
        yield getSensitiveWordList();
    } catch (err) {
        if (err = 'Account locked') {
            console.log(err);
        } else {
            yield errorTip(Mean.NO_NETWORK, editAction.visiblePrompt);
        }
    }
}

function* watchEditSaveChapter() {
    while (true) {
        const { payload } = yield take(editKeys.SAVE_CHAPTER);

        const data = yield select();
        const editReducer = data.editReducer;
        if (payload.novelId) {
            if (payload.name) {
                // if (payload.chapterCon) {
                //     let blob = new Blob([payload.chapterCon], {type: 'text/plain'});
                //     let file = new File([blob], 'chapter.txt', {type: 'text/plain'});
                //     payload.ossKey = yield uploadFile({type: 3, suffixes: ['txt']}, file );
                // }
                if (payload.chapterCon) {
                    payload.id =  editReducer.saveChapterId;
                    if (payload.id === 0) {
                        yield addChapter(payload);
                    } else {
                        yield modifyChapter(payload);
                    }
                    if (payload.isRelease) {
                        yield releaseChapter(payload.id);
                    }
                } else {
                    if (payload.isRelease || payload.type === 'click') {
                        yield errorTip('Silakan isi isi bab', editAction.visiblePrompt);
                    }
                }
            } else {
                yield errorTip('Silakan isi nama bab', editAction.visiblePrompt);
            }
        } else {
            if (payload.type === 'click') {
                yield errorTip('Silakan pilih buku', editAction.visiblePrompt);
            }

        }
    }
}

function* addChapter (param: any) {
    try {
        if (param.type === 'click' && !param.isRelease) {
            yield errorTip('Bab sedang disimpan, silakan tunggu', editAction.visiblePrompt);
        }
        let value = {
            novelId: param.novelId,
            name: param.name,
            content: param.chapterCon,
        };

        const result = yield call(editServer.addChapter, value);
        yield put(editAction.getSaveChapterId(result));

        if (param.type === 'click' && !param.isRelease) {
            yield errorTip('Bab sudah disimpan', editAction.visiblePrompt);
        }

        if (param.isRelease) {
            yield releaseChapter(result);
        }
    } catch (err) {
        yield errorTip('Gagal terhubung ke server, silakan coba lagi', editAction.visiblePrompt);
    }
}

function* modifyChapter(param: any) {
    try {
        if (param.type === 'click' && !param.isRelease) {
            yield errorTip('Bab sedang disimpan, silakan tunggu', editAction.visiblePrompt);
        }
        let value = {
            chapterId: param.id,
            name: param.name,
            content: param.chapterCon,
        };
        yield call(editServer.modifyChapter, value);
        if (param.type === 'click' && !param.isRelease) {
            yield errorTip('Bab sudah disimpan', editAction.visiblePrompt);
        }
        if (param.type === 'auto') {
            yield put(editAction.autoSaveChapterTooltipTextVisible(true));
            yield delay(2000);
            yield put(editAction.autoSaveChapterTooltipTextVisible(false));
        }
    } catch (err) {
        yield errorTip(Mean.NO_NETWORK, editAction.visiblePrompt);
    }
}

function* releaseChapter(id: number) {
    if (id === 0) {
        // 弹出框提示 - 发布失败
        console.log('发布失败 id', id);
        return;
    } else {
        try {
            // 弹出框提示 - 正在发布
            yield errorTip('Bab sedang dikirim, silakan tunggu', editAction.visiblePrompt);

            // 数据请求
            yield call(editServer.editToReleaseChapter, id);

            // 弹出提示框 - 发布成功
            yield errorTip('Sukses mengirim', editAction.visiblePrompt);

            // 去章节列表
            yield put(editAction.editToChapterList(true));
            yield delay(2000);
            yield put(editAction.editToChapterList(false));
        } catch (err) {
            // 弹出提示框 - 发布失败
            yield errorTip('Gagal mengirim', editAction.visiblePrompt);
            yield put(editAction.editToChapterList(false));
            console.log(err);
        }

    }
}

function* watchInitPage() {
    yield takeEvery(editKeys.INIT_PAGE, initPage);
}

export function* watchEditSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchEditSaveChapter),
    ]);
}
