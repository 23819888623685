import { postJson, postLoginJson } from '../utils/xhr';

/**
 * facebook 第三方登录
 */
export function loginServerForFacebook (param: any) {
    return postLoginJson('/api/user/login', param, 'facebook');
}

/**
 * google 第三方登录
 */
export function loginServerForGoogle (param: any) {
    return postLoginJson('/api/user/login', param, 'google');
}

/**
 * 退出登录
 */
export function loginOut() {
    return postJson('/api/user/logout');
}
