import * as React from 'react';
import '../less/less/noNetwork.less';
import { ossUrl } from '../i18n';

export default class ConstructionPage extends React.Component<any, any> {

    render() {
        return (
            <div className="noNetwork">
                <div className="center">
                    <img style={{width: '559px', height: '334px', margin: '0 0 30px 40px'}} src={`${ossUrl}noList.png`} alt=""/>
                    <p>Website dalam pembuatan, silakan kembali lagi di lain waktu</p>
                </div>
            </div>
        );
    }
}
