import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as Loadable from 'react-loadable';
import Main from '../../layout/PageLayout';
// import UserInfoPage from './view/setting/userInfoSetting';
// import CertificationPage from './view/setting/certificationSetting';
import SecondDirectory404Page from '../../layout/secondDirectory404Page';

const UserInfoComponent = Loadable({
    loader: () => import('./view/setting/userInfoSetting'),
    loading: () => <div />,
});

const CertificationComponent = Loadable({
    loader: () => import('./view/setting/certificationSetting'),
    loading: () => <div />,
});

const AuthorRegisternComponent = Loadable({
    loader: () => import('./view/setting/authorRegisterSetting'),
    loading: () => <div />,
});

export const userInfoRouter = (
    <Main>
        <Switch>
            <Route path="/user/info" component={UserInfoComponent} />
            <Route path="/user/certification" component={CertificationComponent} />
            <Route path="/user/register" component={AuthorRegisternComponent} />
            <Route component={SecondDirectory404Page} />
        </Switch>
    </Main>
);
