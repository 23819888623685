import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import Main from '../../layout/PageLayout';
import * as Loadable from 'react-loadable';
// import HelpPage from './view/Help';
import SecondDirectory404Page from '../../layout/secondDirectory404Page';

const HelpComponent = Loadable({
    loader: () => import('./view/Help'),
    loading: () => <div />,
});

export const helpRouter = (
    <Main>
        <Switch>
            <Route path="/help/supportCenter" component={HelpComponent} />
            <Route component={SecondDirectory404Page} />
        </Switch>
    </Main>
);
