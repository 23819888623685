import StarDetailKeys from '../action/keys/starDetailKeys';
import { ActionType } from '../action/type/starDetailType';

interface State {
    list: any;
}

const State: State = {
    list: {}
};

export const starDetailReducer = (state = State, action: ActionType) => {
    switch (action.type) {

        case StarDetailKeys.GET_StAR_LIST:
            return Object.assign({}, state, {
                list: action.payload
            });

        default:
            return state;
    }
};
