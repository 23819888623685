import WithdrawDetailKeys from '../action/keys/withdrawDetailKeys';
import { ActionType } from '../action/type/withdrawDetailType';

interface PageData {
    current: number;
    pageSize: number;
}

export interface StoreState {
    dataList: any[];
    total: number;
    searchData: any;
    page: PageData;
    orderMsg: any;
    backCardInfo: any;
    loading: boolean;
    promptModal: boolean;
    promptText: string;
    toWithdraw: boolean;
}

const initialState: StoreState = {
    dataList: [],
    total: 0,
    searchData: {},
    page: {
        current: 1,
        pageSize: 1000,
    },
    orderMsg: {},
    backCardInfo: {},
    loading: false,
    promptModal: false,
    promptText: '',
    toWithdraw: false

};

export const withdrawDetailReducer = (state = initialState, action: ActionType ) =>  {
    switch (action.type) {

        case WithdrawDetailKeys.INIT_PAGE:
            return initialState;

        case WithdrawDetailKeys.GET_WITHDRAW_DATA_LIST:
            return Object.assign({}, state, {
                dataList: action.payload.dataList,
                total: action.payload.total,
            });

        case WithdrawDetailKeys.DATA_LIST_SEARCH:
            return Object.assign({}, state, {
                searchData: action.payload
            });

        case WithdrawDetailKeys.UPDATE_PAGE_SIZE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.pagination.current,
                    pageSize: action.payload.pagination.pageSize,
                }
            });
        case WithdrawDetailKeys.GET_WITHDRAW_ORDER_MSG:
            return Object.assign({}, state, {
               orderMsg: action.payload
            });

        case WithdrawDetailKeys.GET_WIthDRAW_ORDER_BANK_CARD:
            return Object.assign({}, state, {
                backCardInfo: action.payload
            });

        case WithdrawDetailKeys.LOADING:
            return Object.assign({}, state, {
               loading: action.payload
            });

        case WithdrawDetailKeys.PROMPT_MODAL:
            return Object.assign({}, state, {
                promptModal: action.payload.bol,
                promptText: action.payload.value
            });

        case WithdrawDetailKeys.TO_WITHDRAW:
            return Object.assign({}, state, {
                toWithdraw: action.payload
            });

        default:
            return state;
    }
};
