import { postJson } from '../utils/xhr';

/**
 * 获取文集列表2.0.0
 */
export function getMyAllNovelInfoList(value?: any) {
    return postJson('/api/novel/getMyAllNovelInfoList', value);
}

/**
 * 获取敏感词列表2.0.0
 */
export function getSensitiveWordList() {
    return postJson('/api/sensitiveWord/getSensitiveWordList');
}

/**
 * 新建章节2.0.0
 */
export function addChapter(param: any) {
    return postJson('/api/chapter/addChapter', param);
}

/**
 * 编辑文章2.0.0
 */
export function modifyChapter(param: any) {
    return postJson('/api/chapter/modifyChapter', param);
}

/**
 * 编辑文章
 */
export function toEditChapter(param: any) {
    return postJson('/api/chapter/modifyChapter', param);
}

/**
 * 修订章节
 */
export function revisionChapter(param: any) {
    return postJson('/api/chapter/revisionChapter', param);
}

/**
 * 发布文章
 */
export function editToReleaseChapter(id: number) {
    return postJson('/api/chapter/releaseChapter', id);
}
