enum userInfoKeys {
    INIT_PAGE = 'USER_INFO_INIT_PAGE',
    GET_CONTACT_INFO = 'USER_INFO_GET_CONTACT_INFO',
    BECOME_AUHTOR = 'USER_INFO_BECOME_AUHTOR',
    MODIFY_PEN_NAME = 'USER_INFO_MODIFY_PEN_NAME',
    GET_PERSON_DATA = 'USER_INFO_GET_PERSON_DATA',
    SEND_PHONE_NUMBER = 'USER_INFO_SEND_PHONE_NUMBER',
    SAVE_USER_INFO = 'USER_INFO_SAVE_USER_INFO',
    BECOME_AUTHOR_ERROR = 'USER_INFO_BECOME_AUTHOR_ERROR',
    MODIFY_PEN_NAME_ERROR = 'USER_INFO_MODIFY_PEN_NAME_ERROR',
    MODIFY_CONTACT_INFO_ERROR = 'USER_INFO_MODIFY_CONTACT_INFO_ERROR',
    VERIFICATION_CODE_ERROR = 'USER_INFO_VERIFICATION_CODE_ERROR',
    EMAIL_ERROR = 'USER_INFO_EMAIL_ERROR',
    PROMPT = 'USER_INFO_PROMPT',
    BECOME_AUTHOR_BUTTON = 'USER_INFO_BECOME_AUTHOR_BUTTON',
    SAVE_PEN_NAME_BUTTON = 'USER_INFO_SAVE_PEN_NAME_BUTTON',
    USER_INFO_BUTTON = 'USER_INFO_BUTTON',
    CHECK_PHONE = 'USER_INFO_CHECK_PHONE',
    PHONE_ERROR = 'USER_INFO_PHONE_ERROR',
}

export default userInfoKeys;
