import { all, call, fork, takeEvery, put, take, select } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import IndexKeys from '../../component/index/action/keys/indexKeys';
import * as indexAction from '../../component/index/action/indexAction';
import * as indexServer from '../../server/indexServer';

function* initPage () {
    yield getPersonalData();
    yield getBannerList();
    yield getUserNotificationList();
    yield getNoticePageList();
}

function* getPersonalData() {
    try {
        const result = yield call(indexServer.getPersonalData);
        window.localStorage.setItem('authorId', result.authorId);
        yield put(indexAction.getPersonalMsg(result));
    } catch (err) {
        console.log(err);
    }
}

function* getNoticeList() {
    const data = yield select();
    const reducer = data.indexReducer;
    let param = {
        ...reducer.page
    };
    try {
        const result = yield call(indexServer.getNoticeList, param);
        yield put(indexAction.getNoticeData(result));
    } catch (err) {
        yield put(indexAction.openPromptModal(true));
        yield delay(1000);
        yield put(indexAction.openPromptModal(false));
    }
}

function* getNoticePageList() {
    try {
        const result = yield call(indexServer.getTopBulletin);
        yield put(indexAction.getNoticePageData(result));
    } catch (err) {
        yield put(indexAction.openPromptModal(true));
        yield delay(1000);
        yield put(indexAction.openPromptModal(false));
    }
}

function* getBannerList() {
    try {
        const result = yield call(indexServer.getBannerList);
        yield put(indexAction.getBannerDataList(result));
    } catch (err) {
        yield put(indexAction.openPromptModal(true));
        yield delay(1000);
        yield put(indexAction.openPromptModal(false));
    }
}

function* getUserNotificationList() {
    try {
        const result = yield call(indexServer.getUserNotification);
        yield put(indexAction.getUserNotificationList(result));
        if (result.penNameNotify !== null) {
            yield put(indexAction.openPenNameModal(true));
            yield markAsRead(result.penNameNotify);
        }
        if (result.penNameNotify === null && result.authenticationFailNotify !== null) {
            yield put(indexAction.openFailAuthModal(true));
            yield markAsRead(result.authenticationFailNotify);
        }
    } catch (err) {
        yield put(indexAction.openPromptModal(true));
        yield delay(1000);
        yield put(indexAction.openPromptModal(false));
    }
}

function* getNoticeDetail() {
    while (true) {
        const { payload } = yield take(IndexKeys.GET_NOTICE_ID);
        try {
            const result = yield call(indexServer.getNoticeDetail, payload);
            yield put(indexAction.getNoticeDetail(result));
        } catch (err) {
            yield put(indexAction.openPromptModal(true));
            yield delay(1000);
            yield put(indexAction.openPromptModal(false));
        }
    }
}

function* watchMarkAsRead() {
    while (true) {
        const { payload } = yield take(IndexKeys.MARK_AS_READ);
        try {
            yield call(indexServer.markAsRead, payload);
        } catch (err) {
            yield put(indexAction.openPromptModal(true));
            yield delay(1000);
            yield put(indexAction.openPromptModal(false));
        }
    }
}

function* markAsRead(value: number|null) {
    try {
        yield call(indexServer.markAsRead, value);
    } catch (err) {
        yield put(indexAction.openPromptModal(true));
        yield delay(1000);
        yield put(indexAction.openPromptModal(false));
    }
}

function* watchUserClickBanner() {
    while (true) {
        const { payload } = yield take(IndexKeys.USER_CLICK_BANNER);
        try {
            yield call(indexServer.userClickBanner, payload);
        } catch (err) {
            yield put(indexAction.openPromptModal(true));
            yield delay(1000);
            yield put(indexAction.openPromptModal(false));
        }
    }
}

function* watchIndexInitPage() {
    yield takeEvery(IndexKeys.INIT_PAGE, initPage);
}

function* watchNoticePage() {
    yield takeEvery(IndexKeys.NOTICE_LIST_PAGE, getNoticeList);
}

function* watchUpdatePage() {
    yield takeEvery(IndexKeys.UPDATE_PAGE, getNoticeList);
}

export function* watchIndexSaga() {
    yield all([
        fork(watchIndexInitPage),
        fork(watchNoticePage),
        fork(getNoticeDetail),
        fork(watchUpdatePage),
        fork(watchMarkAsRead),
        fork(watchUserClickBanner)
    ]);
}
