import createCorpusKeys from '../action/keys/createCorpusKeys';

export function initPage() {
    return { type: createCorpusKeys.INIT_PAGE };
}

export function getTypeList(list: any[]) {
    return { type: createCorpusKeys.GET_NOVEL_TYPE_LIST, payload: list };
}

export function openSureModal(value: boolean) {
    return { type: createCorpusKeys.OPEN_CREATE_SURE_MODAL, payload: value };
}

export function imgError(visible: boolean, text?: string) {
    return { type: createCorpusKeys.IMG_ERROR, payload: {visible, text} };
}

export function selectError(visible: boolean, text?: string) {
    return { type: createCorpusKeys.SELECT_ERROR, payload: {visible, text} };
}

export function introError(visible: boolean, text?: string) {
    return { type: createCorpusKeys.INTRO_ERROR, payload: {visible, text} };
}

export function nameError(visible: boolean, text?: string) {
    return { type: createCorpusKeys.NAME_ERROR, payload: {visible, text} };
}

export function addNovel(param: any) {
    return { type: createCorpusKeys.ADD_NOVEL, payload: param};
}

export function editNovel(param: any) {
    return { type: createCorpusKeys.EDIT_NOVEL, payload: param };
}

export function toCorpusList(value: boolean) {
    return { type: createCorpusKeys.TO_CORPUS_LIST, payload: value };
}

export function visiblePrompt(param: {bol: boolean, value?: string }) {
    return { type: createCorpusKeys.VISIBLE_PROMPT, payload: param };
}

export function novelDetailList(param: any) {
    return { type: createCorpusKeys.NOVEL_DETAIL_LIST, payload: param };
}

export function getNovelDetailById(id: string|number) {
    return { type: createCorpusKeys.GET_NOVEL_DETAIL_BY_ID, payload: id };
}

export function getNovelCover(img: any) {
    return { type: createCorpusKeys.GET_NOVEL_COVER, payload: img };
}

export function getNovelBrief(img: any) {
    return { type: createCorpusKeys.GET_NOVEL_BRIEF, payload: img };
}

// 判断弹出框提示内容
export function openCreateChapterModal(value: boolean) {
    return { type: createCorpusKeys.OPEN_CREATE_CHAPTER_MODAL, payload: value };
}

export function getNovelId(value: number) {
    return { type: createCorpusKeys.GET_NOVEL_ID, payload: value };
}

export function cancelButton(value: boolean) {
    return { type: createCorpusKeys.CANCEL_BUTTON, payload: value };
}
