import { all, take, fork, call, put, select, takeEvery } from 'redux-saga/effects';
import ChapterDetailKeys from '../../component/index/action/keys/novelDetailKeys';
import * as workServer from '../../server/workServer';
import * as indexServer from '../../server/indexServer';
import * as corpusDetailAction from '../../component/index/action/novelDetailAction';
import { editToReleaseChapter } from '../../server/editServer';
import * as Mean from '../../i18n/index';
import { errorTip } from './common';

function* watchGetChapterLIst() {
    while (true) {
       yield take(ChapterDetailKeys.GET_CORPUS_ID);
       try {
           yield chapterList();
           yield getPersonalData();
        } catch (err) {
           yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
        }
    }
}

function* getPersonalData() {
    try {
        const result = yield call(indexServer.getPersonalData);
        yield put(corpusDetailAction.personalData(result));
    } catch (err) {
        yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
    }
}

// 获取novelDetail显示数量
function* getChanterNumByState() {
    while (true) {
        const {payload} = yield take(ChapterDetailKeys.GET_NOVEL_ID);
        yield getChapterNumByStateFn(payload);
    }
}

// 获取小说各列表章节数量
function* getChapterNumByStateFn(id: number | string) {
    try {
        const result = yield call(workServer.getChapterNumByDraftState, id);
        yield put(corpusDetailAction.getChapterNumByDiffStatus(result));
    } catch (err) {
        yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
    }
}

// 已整理
function* watchGetNovelDetailById() {
    while (true) {
        const { payload } = yield take(ChapterDetailKeys.GET_NOVEL_DETAIL_BY_ID);
        try {
            const result = yield call(workServer.getNovelDetailById, payload);
            result.brief = result.brief.replace(/<br\s*\/?\s*>/ig, '\n');
            yield put(corpusDetailAction.novelDetailInfo(result));
        } catch (err) {
            yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
        }
    }
}

// 已整理 上架章节
function* watchShowChapter() {
    while (true) {
        const { payload } = yield take(ChapterDetailKeys.SHOW_CHAPTER);
        try {
            const data = yield select();
            const reducer = data.corpusDetailReducer;
            const result = yield call(workServer.showChapter, payload);

            // 如果不是第一页，而且仅剩一条数据时，页码减1
            if (reducer.chapterList.length === 1 && reducer.page.current !== 1) {
                yield put(corpusDetailAction.initUpdatePage(reducer.page.current - 1, 20));
            }

            yield getChapterNumByStateFn(reducer.novelDetailInfo.id);
            yield chapterList();

            if (result.dailyTask === 1 && result.weekTask === 1 && result.totalCountLevel === 0 ) {
                yield errorTip('Sukses publikasi', corpusDetailAction.prompt);
            } else {
                yield put(corpusDetailAction.task(true));
                let num = 0;
                result.dailyTask !== 1 ? num += 100 : num = 0;
                result.weekTask !== 1 ? num += 200 : num += 0;
                let monthMun = yield monthStar(result.totalCountLevel);
                num = num + monthMun;
                yield put(corpusDetailAction.getTaskStar(num));
            }

        } catch (err) {
            if (err.code === 325) {
                yield errorTip('Buku sudah tamat, tidak dapat menambahkan bab',  corpusDetailAction.prompt);
            }  else if (err.code === 336) {
                yield errorTip('Silakan unggah berdasar no. urut!',  corpusDetailAction.prompt);
            } else if (err.code === 330) {
                yield put(corpusDetailAction.unverifiedModal(true));
                } else {
                yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
            }
        }
    }
}

// 章节月更新获取星票数
function monthStar(num: any) {
    switch (num) {
        case 1:
            return 400;
        case 2:
            return 1400;
        case 3:
            return 1000;
        case 4:
            return 3000;
        case 5:
            return 2000;
        default:
            return 0;
    }
}

// 已整理 删除章节
function* watchDeleteChapter() {
    while (true) {
        const { payload } = yield take(ChapterDetailKeys.DEL_CHAPTER);
        const data = yield select();
        const reducer = data.corpusDetailReducer;
        try {
            if (payload.isRecycle) {
                yield call(workServer.deleteChapterFromTrash, payload.id);
                yield errorTip(Mean.DELETE_SUCCESS, corpusDetailAction.prompt);
                // 如果不是第一页，而且仅剩一条数据时，页码减1
                if (reducer.recycleList.length === 1 && reducer.page.current !== 1) {
                    yield put(corpusDetailAction.initUpdatePage(reducer.page.current - 1, 20));
                }
                yield getRecycleList();
            } else {

                yield call(workServer.deleteChapter, payload.id);
                yield errorTip(Mean.DELEDE_SUCCESS, corpusDetailAction.prompt);
                // 如果不是第一页，而且仅剩一条数据时，页码减1
                if (reducer.chapterList.length === 1 && reducer.page.current !== 1) {
                    yield put(corpusDetailAction.initUpdatePage(reducer.page.current - 1, 20));
                }
                yield chapterList();
                yield getChapterNumByStateFn(reducer.novelDetailInfo.id);
            }
        } catch (err) {
            yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
        }
    }
}

// 已整理 还原章节
function* watchRestoreChapter() {
    while (true) {
        const { payload } = yield take(ChapterDetailKeys.RESTORE_CHAPTER);
        try {
            const data = yield select();
            const reducer = data.corpusDetailReducer;
            yield call(workServer.restoreChapterFromTrash, payload);
            yield errorTip(Mean.RESTORE_CHAPTER, corpusDetailAction.prompt);
            // 如果不是第一页，而且仅剩一条数据时，页码减1
            if (reducer.recycleList.length === 1 && reducer.page.current !== 1) {
                yield put(corpusDetailAction.initUpdatePage(reducer.page.current - 1, 20));
            }
            yield getRecycleList();
            yield getChapterNumByStateFn(reducer.novelDetailInfo.id);
        } catch (err) {
            yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
        }
    }
}

// 已整理 获取回收站章节列表
function* getRecycleList() {
    const data = yield select();
    const reducer = data.corpusDetailReducer;
    let param = {
        data: reducer.getRecycleMsg.novelId,
        ...reducer.page
    };
    try {
        const result = yield call(workServer.getChapterListFomTrash, param);
        yield put(corpusDetailAction.getRecycleList(result));
        yield put(corpusDetailAction.listLoading(false));
    } catch (err) {
        yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
    }
}

// 已整理 获取章节列表
function* getChapterList(param: any) {
    try {
        let result;
        yield put(corpusDetailAction.listLoading(true));
        if (param.data.type === 'release') {
            result = yield call(workServer.getShelfChapterList, param);
        } else if (param.data.type === 'draft') {
            result = yield call(workServer.getDraftChapterList, param);
        }

        yield put(corpusDetailAction.listLoading(false));

        return result;
    } catch (err) {
        yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
    }

}

// 已整理 提请参赛
// function* watchSubmitToCompetition() {
//     while (true) {
//         yield take(ChapterDetailKeys.COMPETITION_OK);
//         yield put(corpusDetailAction.competitionModal(false));
//         const data = yield select();
//         const reducer = data.corpusDetailReducer;
//         try {
//             let { novelDetailInfo } = reducer;
//             let id = novelDetailInfo.id;
//             yield call(workServer.submitToCompetition, id);
//             yield errorTip('Sukses ikut serta', corpusDetailAction.prompt);
//         } catch (err) {
//             if (err.code === 322) {
//                 yield errorTip('Karya ini tidak bisa ikut lomba', corpusDetailAction.prompt);
//             } else {
//                 yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
//             }
//         }
//     }
// }

// 已整理 发布章节
function* watchReleaseChapter() {
    while (true) {
        const { payload } = yield take(ChapterDetailKeys.RELEASE_CHAPTER);
        if ((payload.wordCount || payload.realWordCount) > 4000) {
            yield errorTip('Panjang tiap bab maks. 4000 kata',  corpusDetailAction.prompt);
        } else if ((payload.wordCount || payload.realWordCount) < 1000) {
            yield errorTip('Panjang tiap bab min. 1000 kata',  corpusDetailAction.prompt);
        } else {
            const data = yield select();
            const reducer = data.corpusDetailReducer;
            let param = {
                data: reducer.chapterReqMsg,
                ...reducer.page
            };
            try {
                if ((payload.status === 3 || payload.status === 4) && !payload.locked) {
                    yield workServer.showChapter(payload.id);
                } else {
                    yield editToReleaseChapter(payload.id);
                }
                const result = yield getChapterList(param);
                yield put(corpusDetailAction.getChapterList(result));
                let { personalData, novelDetailInfo} = reducer;
                if (personalData && personalData.authenticationStatus === 0) {
                    yield put(corpusDetailAction.certificationModal(true));
                } else if (personalData.authenticationStatus === 2 && novelDetailInfo.status === 1 && novelDetailInfo.sourceType === 1 && !novelDetailInfo.inCompetition) {
                    yield put(corpusDetailAction.competitionModal(true));
                } else {
                    yield put(corpusDetailAction.releaseModal(true));
                }
            } catch (err) {
                if (err.code === 324) {
                    yield errorTip('Mengandung kata terlarang, tidak bisa mengirim',  corpusDetailAction.prompt);
                } else if (err.code === 328) {
                    yield errorTip('Isi bab kosong, silakan perbaiki dan kirim ulang',  corpusDetailAction.prompt);
                } else if (err.code === 336) {
                    yield errorTip('Silakan unggah berdasar no. urut!',  corpusDetailAction.prompt);
                } else if (err.code === 313) {
                    yield errorTip('313',  corpusDetailAction.prompt);
                } else {
                    yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
                }
            }
        }
    }
}

function* chapterList() {
    try {
        const data = yield select();
        const reducer = data.corpusDetailReducer;

        let param = {
            data: reducer.chapterReqMsg,
            ...reducer.page
        };

        for (let key in param.data) {
            if (param.data[key] === '') {
                delete param.data[key];
            }
        }

        // 重置默认值，防止菜单切换太快导致数据显示位置错误
        let initialParam = {
            total: 0,
            data: []
        };
        yield put(corpusDetailAction.getChapterList(initialParam));

        const result = yield getChapterList(param);
        yield put(corpusDetailAction.getChapterList(result));
    } catch (err) {
        yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
    }
}

function* watchChangeChapterStatus() {
    while (true) {
        const {payload} = yield take(ChapterDetailKeys.CHANGE_CHAPTER);
        const data = yield select();
        const reducer = data.corpusDetailReducer;
        let keyArr = payload.chapterKey.split('-');
        if (payload.type === 'release') {
            payload.status === 0 ? reducer.chapterReqMsg.status = null : reducer.chapterReqMsg.status = payload.status;
        } else {
            payload.status === 0 ? reducer.chapterReqMsg.status = null : reducer.chapterReqMsg.status = payload.status - 1;
        }

        let fir = Number(keyArr[0]), sec = Number(keyArr[1]);
        reducer.chapterReqMsg.startChapter = fir === 0 ? null : fir;
        reducer.chapterReqMsg.endChapter = sec === 0 ? null : sec;
        let param = {
            data: reducer.chapterReqMsg,
            ...reducer.page
        };

        for (let key in param.data) {
            if (param.data[key] === '') {
                delete param.data[key];
            }
        }
        if (sec > 0) {
            if (sec < fir) {
                yield errorTip('Format salah', corpusDetailAction.prompt);
            } else {
                try {
                    param.data.order = 'ASC';
                    const result = yield getChapterList(param);
                    yield put(corpusDetailAction.getChapterList(result));
                } catch (err) {
                    yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
                }
            }
        } else {
            if (fir === 0 && sec === 0) {
                yield errorTip('Masukkan bab', corpusDetailAction.prompt);
            } else {
                try {
                    param.data.order = 'DESC';
                    const result = yield getChapterList(param);
                    yield put(corpusDetailAction.getChapterList(result));
                } catch (err) {
                    yield errorTip(Mean.NO_NETWORK, corpusDetailAction.prompt);
                }
            }
        }
    }
}

function* watchUpdatePage() {
    yield takeEvery(ChapterDetailKeys.UPDATE_PAGE, chapterList);
}

function* watchRecycleUpdatePage() {
    yield takeEvery(ChapterDetailKeys.RECYCLE_LIST_CHANGE_DATE, getRecycleList);
}

function* watchGetChapterListFomTrash() {
    yield takeEvery(ChapterDetailKeys.GET_RECYCLE_ID, getRecycleList);
}

export function* watchCorpusDetailSaga() {
    yield all([
        fork(watchGetNovelDetailById), // 获取小说细节信息
        fork(getChanterNumByState), // 获取各状态章节数量
        fork(watchChangeChapterStatus), // 改变小说章节列表状态
        fork(watchShowChapter), // 上架章节
        fork(watchDeleteChapter), // 删除章节
        fork(watchRestoreChapter), // 从回收站还原章节
        fork(watchGetChapterListFomTrash), // 获取章节列表从回收站
        fork(watchReleaseChapter), // 发布章节
        fork(watchGetChapterLIst), // 获取章节列表
        fork(watchUpdatePage), // 监测是否修改page
        fork(watchRecycleUpdatePage), // 检测回收站翻页
        // fork(watchSubmitToCompetition), // 提请参赛
    ]);
}
