import NovelDetailKeys from './keys/rewardDetailKeys';

export function getRewardList(param: any) {
    return { type: NovelDetailKeys.GET_RWARD_LIST, payload: param };
}
export function getRewardPayload(param: any) {
    return { type: NovelDetailKeys.RWARD_LIST_PAYLOAD, payload: param };
}
export function getSaleList(param: any) {
    return { type: NovelDetailKeys.GET_SALE_LIST, payload: param };
}
export function getSalePayload(param: any) {
    return { type: NovelDetailKeys.SALE_LIST_PAYLOAD, payload: param };
}