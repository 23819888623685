import CertificationKeys from '../action/keys/certificationKeys';
import { ActionType } from '../action/type/certificationType';

export interface StoreState {
    certificationInfo: any;
    notifyModalVisible: boolean;
    promptModalVisible: boolean;
    modifyCertificationError: boolean;
    buttonStatus: boolean;
    serverPhoneError: boolean;
    verificationCodeError: boolean;
    emailError: boolean;
    promptText: string;
    modifyCertificationText: string;
    serverPhoneErrorText: string;
    verificationCodeErrorText: string;
    emailErrorText: string;
}

const initialState: StoreState = {
    certificationInfo: {},
    notifyModalVisible: false,
    promptModalVisible: false,
    modifyCertificationError: false,
    buttonStatus: false,
    serverPhoneError: false,
    verificationCodeError: false,
    emailError: false,
    promptText: '',
    modifyCertificationText: '',
    serverPhoneErrorText: '',
    verificationCodeErrorText: '',
    emailErrorText: '',
};

export const certificationReducer = (state = initialState, action: ActionType ) =>  {
    switch (action.type) {

        case CertificationKeys.INIT_PAGE:
            return initialState;

        case CertificationKeys.GET_CERTIFICATION_INFO:
            return Object.assign({}, state, {
                certificationInfo: action.payload,
            });

        case CertificationKeys.SAVE_BUTTON:
            return Object.assign({}, state, {
                buttonStatus: action.payload,
            });

        case CertificationKeys.PROMPT:
            return Object.assign({}, state, {
                promptModalVisible: action.payload.bol,
                promptText: action.payload.value,
            });

        case CertificationKeys.MODIFY_CERTIFICATION_INFO_ERROR:
            return Object.assign({}, state, {
                modifyCertificationError: action.payload,
                modifyCertificationText: action.payload,
            });

        case CertificationKeys.NOTIFY_MODAL_VISIBLE:
            return Object.assign({}, state, {
                notifyModalVisible: action.payload,
            });

        case CertificationKeys.PHONE_ERROR:
            return Object.assign({}, state, {
                serverPhoneError: action.payload.bol,
                serverPhoneErrorText: action.payload.value,
            });

        case CertificationKeys.VERIFICATION_CODE_ERROR:
            return Object.assign({}, state, {
                verificationCodeError: action.payload.bol,
                verificationCodeErrorText: action.payload.value,
            });

        case CertificationKeys.EMAIL_ERROR:
            return Object.assign({}, state, {
                emailError: action.payload.bol,
                emailErrorText: action.payload.value,
            });

        default:
            return state;
    }
};
