import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as Loadable from 'react-loadable';
import Main from '../../layout/PageLayout';
// import UserInfoPage from './view/setting/userInfoSetting';
// import CertificationPage from './view/setting/certificationSetting';
import SecondDirectory404Page from '../../layout/secondDirectory404Page';

const WelfareComponent = Loadable({
    loader: () => import('./view/Welfare'),
    loading: () => <div />,
});

export const welfareRouter = (
    <Main>
        <Switch>
            <Route path="/welfare/page" component={WelfareComponent} />
            <Route component={SecondDirectory404Page} />
        </Switch>
    </Main>
);
