import * as React from 'react';
import Header from './setting/headerSetting';
import Footer from './Footer';
import { Skeleton  } from 'antd';
// import { userCheck } from '../server/indexServer';
import { withNamespaces } from 'react-i18next';

interface State {
    showPrompt: boolean;
    isLoading: boolean;
}

class PageLayout extends React.Component<any, State> {

    state: State = {
        showPrompt: true,
        isLoading: false
    };

    componentWillMount() {
        // userCheck().then((res: any) => {
        //     this.setState({isLoading: false});
        // }).catch((err) => {
        //     this.setState({isLoading: true});
        // });
        window.addEventListener('offline', this.toNoNetWorkPage);
        window.addEventListener('online', this.refreshPage);
    }

    toNoNetWorkPage = () => {
        window.open('/#/noNetwork');
    }

    refreshPage = () => {
        window.location.reload();
    }

    componentWillUnmount() {
        window.removeEventListener('offline', this.toNoNetWorkPage);
        window.addEventListener('onLine', this.refreshPage);
    }

    changeShowPrompt = (type: boolean) => {
        this.setState({showPrompt: type});
    }

    componentWillUpdate(nextProps: any, nextState: any) {
        window.scrollTo(0, 0);
    }

    render() {
        const { i18n, t } = this.props;
        let bodyHeight = document.body.clientHeight;
        let minHeight = bodyHeight - 150;
        const { showPrompt, isLoading } = this.state;

        return (
                <div style={{position: 'relative'}}>
                    <Skeleton loading={isLoading}>
                         <Header changeShowPrompt={this.changeShowPrompt} i18n={i18n} t={t}/>
                    <div id="root1" />
                    <div data-state={showPrompt} style={{paddingTop: '50px', marginBottom: '20px',  minHeight: `${minHeight}px`}} id="father">
                        {this.props.children}
                    </div>
                    <Footer t={t} />
                    </Skeleton >
                </div>
        );
    }
}

export default  withNamespaces('translation')(PageLayout);
