import indexKeys from '../action/keys/loginKeys';
import { ActionType } from '../action/type/loginType';

interface StoreState {
    toIndex: boolean;
}

const initialState: StoreState = {
    toIndex: false
};

export const loginReducer = (state = initialState, action: ActionType ) =>  {
    switch (action.type) {

        case indexKeys.LOGIN_TO_INDEX:
            return Object.assign({}, state, {
               toIndex: action.payload
            });

        default:
            return state;
    }
};