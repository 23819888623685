import * as React from 'react';
import { Modal } from 'antd';

interface IndexProps {
    t: any;
    visible: boolean;
    closable?: boolean;
    onOk: () => void;
    onCancel?: (value: boolean) => void;
    content: string;
}

class IndexPageModal extends React.Component<IndexProps, {}> {

    onCancel = () => {
        if (this.props.onCancel != null) {
            this.props.onCancel(false);
        }
    }

    render() {
        const { visible, content, onOk, t, closable } = this.props;

        return (
            <Modal
                visible={visible}
                closable={closable == null ? true : closable}
                centered={true}
                width={400}
                maskClosable={false}
                onCancel={this.onCancel}
                footer={null}
                className="confirm"
            >
                <em>{content}</em>
                <div className="submitButton" onClick={onOk}>{t('SURE')}</div>
            </Modal>
        );
    }
}

export default IndexPageModal;
