enum chapterSortKeys {
    INIT_PAGE = 'SORT_INIT_PAGE',
    GET_SORT_CORPUS_ID = 'GET_SORT_CORPUS_ID',
    GET_SORT_LIST = 'GET_SORT_LIST',
    GET_NEW_SORT_LIST = 'GET_NEW_SORT_LIST',
    GET_PROMPT_TEXT = 'SORT_GET_PROMPT_TEXT',
    OPEN_PROMPT_MODAL = 'SORT_OPEN_PROMPT_MODAL',
    SORT_TO_LIST = 'SORT_TO_LIST',
}

export default chapterSortKeys;