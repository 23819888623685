import React from 'react';
import { Form, Input, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { getParameter } from '../../../utils/getURL';
import { applyCharge } from '../../../server/workServer';

const { TextArea } = Input;

class PayApply extends React.Component<any, any> {

    state = {
        articleId: null,
        outline: null,
        planWordCount: null,
        contact: null,
        submitLoading: false
    }

    componentDidMount() {
        const id = getParameter('id');
        this.setState({
            articleId: id
        });
    }

    handleOutlineChange = (e) => {
        this.setState({
            outline: e.target.value
        });
    }

    handlePlanWordCountChange = (e) => {
        let wordCount;
        try {
            wordCount = parseInt(e.target.value);
        } catch (err) {
        }
        if (isNaN(wordCount)) {
            wordCount = '';
        }
        this.setState({
            planWordCount: wordCount
        });
    }

    handleContactChange = (e) => {
        this.setState({
            contact: e.target.value
        });
    }

    handleSubmit = () => {
        const {articleId, outline, planWordCount, contact, submitLoading} = this.state;
        if (submitLoading) return;
        const param = {
            articleId,
            outline,
            planWordCount,
            contact
        };

        this.setState({submitLoading: true});
        applyCharge(param).then(() => {
            this.props.history.push('/novel/index');
        }).catch(err => {
            console.log(err);
        }).finally(() => {
            this.setState({submitLoading: false});
        });
    }

    render() {
        const {articleId, outline, planWordCount, contact, submitLoading} = this.state;
        const canSubmit = articleId && outline && planWordCount && contact;

        return (
            <div className="w1200 pay-apply-page" style={{marginTop: '20px'}}>
                <Form>
                    <Form.Item label="Penjelasan singkat" required>
                        <TextArea rows={6} onChange={this.handleOutlineChange} value={outline} />
                    </Form.Item>
                    <Form.Item label="Rencana jumlah kata" required>
                        <Input onChange={this.handlePlanWordCountChange} value={planWordCount} />
                    </Form.Item>
                    <Form.Item label="Cara menghubungi" required>
                        <Input onChange={this.handleContactChange} value={contact} />
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={!canSubmit} loading={submitLoading} onClick={this.handleSubmit}>Kirim permohonan</Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}

export default withRouter(PayApply);