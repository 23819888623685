import workListKey from '../action/keys/workListKeys';
import { ActionType } from '../action/type/workListType';

interface PageData {
    current: number;
    pageSize: number;
}

interface State {
    workList: any[];
    page: PageData;
    total: any;
    corpusDetail: any;
    filterStatus: any;
    promptText: string;
    promptModal: boolean;
    loading: boolean;
    noDataShow: boolean;
    paidModal: boolean;
}

const State: State = {
    workList: [],
    filterStatus: null,
    corpusDetail: {},
    page: {
        current: 1,
        pageSize: 5
    },
    total: 0,
    promptText: '',
    promptModal: false,
    loading: false,
    noDataShow: false,
    paidModal: false
};

export const workListReducer = (state = State, action: ActionType) => {
    switch (action.type) {

        case workListKey.INIT_PAGE:
            return State;

        case workListKey.GET_CORPUS_LIST:
            return Object.assign({}, state, {
                workList: action.payload.data,
                total: action.payload.total,
            });

        case workListKey.GET_CORPUS_STATUS:
            return Object.assign({}, state, {
                filterStatus: action.payload
            });

        case workListKey.VISIBLE_PROMPT:
            return Object.assign({}, state, {
                promptModal: action.payload.bol,
                promptText: action.payload.value,
            });

        case workListKey.CHANGE_PAGE_SIZE:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.page,
                    pageSize: action.payload.pageSize
                }
            });

        case workListKey.CHANGE_PAGE_SIZE_AND_STATUS:
            return Object.assign({}, state, {
                page: {
                    current: action.payload.page,
                    pageSize: action.payload.pageSize
                }
            });

        case workListKey.LOADING:
            return Object.assign({}, state, {
               loading: action.payload
            });

        case workListKey.NO_DATA:
            return Object.assign({}, state, {
                noDataShow: action.payload
            });

        case workListKey.GET_CORPUS_DETAIL:
            return Object.assign({}, state, {
                corpusDetail: action.payload
            });

        case workListKey.PAID_NOVEL_MODAL:
            return Object.assign({}, state, {
                paidModal: action.payload.bol,
                promptText: action.payload.value,
            });

        default:
            return state;
    }
};
