import WithdrawDetailKeys from './keys/withdrawDetailKeys';

export function initPage() {
    return { type: WithdrawDetailKeys.INIT_PAGE };
}

export function getDataList(dataList: any) {
    return { type: WithdrawDetailKeys.GET_WITHDRAW_DATA_LIST, payload: dataList };
}

export function dataListSearch(dataList: any) {
    return { type: WithdrawDetailKeys.DATA_LIST_SEARCH, payload: dataList };
}

export function updatePageSize(dataList: any) {
    return { type: WithdrawDetailKeys.UPDATE_PAGE_SIZE, payload: dataList };
}

export function getOrderId(id: number) {
    return { type: WithdrawDetailKeys.GET_WITHDRAW_ORDER_ID, payload: id };
}

export function getOrderMsg(param: any) {
    return { type: WithdrawDetailKeys.GET_WITHDRAW_ORDER_MSG, payload: param };
}

export function getOrderBankCardInfo(param: any) {
    return { type: WithdrawDetailKeys.GET_WIthDRAW_ORDER_BANK_CARD, payload: param };
}

export function loading(bol: boolean) {
    return { type: WithdrawDetailKeys.LOADING, payload: bol };
}

export function promptModal(param: { bol: boolean, value: string }) {
    return { type: WithdrawDetailKeys.PROMPT_MODAL, payload: param };
}

export function toCertification(val: boolean) {
    return { type: WithdrawDetailKeys.TO_WITHDRAW, payload: val };
}