import { all, fork } from 'redux-saga/effects';
import { watchLoginSaga } from './saga/loginSaga';
import { watchIndexSaga } from './saga/indexSaga';
import { watchWorkListSaga } from './saga/workListSaga';
import { watchCreateCorpusSaga } from './saga/createCorpusSaga';
import { watchEditSaga } from './saga/editSaga';
import { watchChapterSortSaga } from './saga/chapterSortSaga';
import { watchCorpusDetailSaga } from './saga/corpusDetailSaga';
import { watchArticleSaga } from './saga/articleSaga';
import { watchUserInfoSaga } from './saga/userInfoSaga';
import { watchUploadChapterSaga } from './saga/createUploadSaga';
import { watchIncomeInfoSaga } from './saga/incomeSaga';
import { watchWithdrawInfoSaga } from './saga/withdrawSaga';
import { watchWithdrawDetailSaga } from './saga/withdrawDetailSaga';
import { watchCertificationSaga } from './saga/certificationSaga';
import { watchStarDetailSaga } from './saga/starDetailSaga';
import { watchRewardDetailSaga } from './saga/rewardDetailSaga';

export default function* rootSaga() {
    yield all([
        fork(watchLoginSaga),
        fork(watchIndexSaga),
        fork(watchWorkListSaga),
        fork(watchCreateCorpusSaga),
        fork(watchEditSaga),
        fork(watchChapterSortSaga),
        fork(watchCorpusDetailSaga),
        fork(watchArticleSaga),
        fork(watchUserInfoSaga),
        fork(watchUploadChapterSaga),
        fork(watchWithdrawInfoSaga),
        fork(watchWithdrawDetailSaga),
        fork(watchCertificationSaga),
        fork(watchIncomeInfoSaga),
        fork(watchStarDetailSaga),
        fork(watchRewardDetailSaga)
    ]);
}
