import { all, take, select, fork, put, call, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import createCorpusKeys from '../../component/index/action/keys/createCorpusKeys';
import * as createAction from '../../component/index/action/createCorpusAction';
import * as workServer from '../../server/workServer';
import * as Mean from '../../i18n/index';

import { errorTip } from './common';

function* watchAddNovel() {
    while (true) {
        const { payload } = yield take(createCorpusKeys.ADD_NOVEL);
        const data = yield select();
        const reduce = data.createCorpusReducer;
        try {
            if (!reduce.nameError && !reduce.imgError && !reduce.introError && !reduce.selectError) {
                yield put(createAction.openSureModal(false));
                yield put(createAction.cancelButton(false));
                let result = yield call(workServer.uploadImgToServer, payload.coverUrl, '2');
                payload.coverUrl = result[0];
                // 正在创建，请稍等
                // yield errorTip(Mean.PLEASE_WAIT, createAction.visiblePrompt);
                // // 接口请求
                let novelId = yield call(workServer.createNewCropus, payload);
                yield put(createAction.getNovelId(novelId));
                yield put(createAction.cancelButton(true));
                // // 文集创建成功
                // yield errorTip(Mean.CREATE_SUCCESS, createAction.visiblePrompt);
                yield put(createAction.openCreateChapterModal(true));
                // yield delay(1000);
                // yield put(createAction.toCorpusList(false));
            } else {
                yield put(createAction.openSureModal(false));
            }
        } catch (err) {
            if (err.code === 316) {
                yield errorTip('Genre tulisan tidak ada', createAction.visiblePrompt);
            } else if (err.code === 329) {
                yield errorTip('Sebelum publikasi novel pertama, belum bisa buat novel lagi', createAction.visiblePrompt);
            } else if(err.code === 346){
                yield errorTip('Genre yang kamu pilih tidak sesuai, silakan perbarui aplikasi dan coba lagi', createAction.visiblePrompt);
            } else{
                yield errorTip(Mean.CREATE_FAILED, createAction.visiblePrompt);
            }
            yield put(createAction.openSureModal(false));
            yield put(createAction.toCorpusList(false));
        }
    }
}

function* getNovelTypeList() {
    try {
        const result = yield call(workServer.getNovelTypeList);
        yield put(createAction.getTypeList(result));
    } catch (err) {
        yield errorTip(Mean.NO_NETWORK, createAction.visiblePrompt);
    }
}

function* watchGetNovelDetailById() {
    while (true) {
        const { payload } = yield take(createCorpusKeys.GET_NOVEL_DETAIL_BY_ID);
        try {
            const result = yield call(workServer.getNovelDetailById, payload);
            if (result.infoAuditStatus === 1 || result.infoAuditStatus === 3) {
                const modifyResult = yield call(workServer.getArticleInfoModifyStatus, payload);
                result.modifyResult = modifyResult;
            } else {
                result.modifyResult = {};
            }
            yield put(createAction.novelDetailList(result));
        } catch (err) {
            yield errorTip(Mean.NO_NETWORK, createAction.visiblePrompt);
        }
    }
}

function* editCorpusSure() {
    while (true) {
        const {payload} = yield take(createCorpusKeys.EDIT_NOVEL);
        yield put(createAction.openSureModal(false));
        const data = yield select();
        const reduce = data.createCorpusReducer;
        try {
            if (payload.coverUrl) {
                let result = yield call(workServer.uploadImgToServer, payload.coverUrl, '2');
                payload.coverUrl = result[0];
            } else {
                delete payload.coverUrl;
            }
            // 正在修改文集，请稍等
            // yield errorTip(Mean.PLEASE_WAIT_MODIFY, createAction.visiblePrompt);
            // 接口请求
            // payload.brief = payload.brief.replace(/\n/g, '<br />');
            payload.id = reduce.novelDetailList.id;
            yield call(workServer.editCorpus, payload);
            // 修改成功
            yield errorTip(Mean.PLEASE_WAIT_MODIFY_SUCCESS, createAction.visiblePrompt);
            yield put(createAction.toCorpusList(true));
            yield delay(1000);
            yield put(createAction.toCorpusList(false));
        } catch (err) {
            yield errorTip(Mean.NO_NETWORK, createAction.visiblePrompt);
        }

    }
}

function* watchInitPage() {
    yield takeEvery(createCorpusKeys.INIT_PAGE, getNovelTypeList);
}

export function* watchCreateCorpusSaga() {
    yield all([
        fork(watchInitPage),
        fork(watchAddNovel),
        fork(watchGetNovelDetailById),
        fork(editCorpusSure),
    ]);
}
