import { fork, all, take } from 'redux-saga/effects';
import loginKeys from '../../layout/action/keys/loginKeys';
// import * as loginServer from '../../server/loginServer';
// import * as loginAction from '../../layout/action/loginAction';

function* facebookLogin() {
    while (true) {
        let { payload } = yield take(loginKeys.LOGIN_FACEBOOK);
        console.log(payload);
    }

}

export function* watchLoginSaga() {
    yield all([
        fork(facebookLogin)
    ]);
}