import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as Loadable from 'react-loadable';
import Main from '../../layout/PageLayout';
import PayApply from './view/PayApply';
import SecondDirectory404Page from '../../layout/secondDirectory404Page';

const IndexComponent = Loadable({
    loader: () => import('./view/setting/indexSetting'),
    loading: () => <div />,
});

const NoticeListComponent = Loadable({
    loader: () => import('./view/setting/noticeListSetting'),
    loading: () => <div />,
});

const NoticeDetailComponent = Loadable({
    loader: () => import('./view/setting/noticeDetailSetting'),
    loading: () => <div />,
});

const WorkListComponent = Loadable({
    loader: () => import('./view/setting/workListSetting'),
    loading: () => <div />,
});

const CreateCorpusComponent = Loadable({
    loader: () => import('./view/setting/createCorpusSetting'),
    loading: () => <div />,
});

const CorpusDetailComponent = Loadable({
    loader: () => import('./view/setting/novelDetailSetting'),
    loading: () => <div />,
});

const ArticleComponent = Loadable({
    loader: () => import('./view/setting/articleSetting'),
    loading: () => <div />,
});

const ChapterSortComponent = Loadable({
    loader: () => import('./view/setting/chapterSortSetting'),
    loading: () => <div />,
});

const UploadChapterComponent = Loadable({
    loader: () => import('./view/setting/uploadChapterSetting'),
    loading: () => <div />,
});

const StarDetailComponent = Loadable({
    loader: () => import('./view/setting/starDetailSetting'),
    loading: () => <div />,
});

const RewardDetailComponent = Loadable({
    loader: () => import('./view/setting/rewardDetailSetting'),
    loading: () => <div />,
});

export const indexRouter = (
    <Main>
        <Switch>
            <Route path="/novel/index" component={IndexComponent} />
            <Route path="/novel/noticeList" component={NoticeListComponent} />
            <Route path="/novel/noticeDetail" component={NoticeDetailComponent} />
            <Route path="/novel/workList" component={WorkListComponent} />
            <Route path="/novel/createCorpus" component={CreateCorpusComponent} />
            <Route path="/novel/corpusDetail/:id" component={CorpusDetailComponent} />
            <Route path="/novel/article" component={ArticleComponent} />
            <Route path="/novel/chapterSort" component={ChapterSortComponent} />
            <Route path="/novel/corpusUpload" component={UploadChapterComponent} />
            <Route path="/novel/starDetail" component={StarDetailComponent} />
            <Route path="/novel/rewardDetail" component={RewardDetailComponent} /> 
            <Route path="/novel/payApply" component={PayApply} />
            <Route component={SecondDirectory404Page} />
        </Switch>
    </Main>
);
